
<div class="text-right d-flex align-items-center justify-content-end m-3">
    <mat-icon class="close-mat" (click)="closeMatDrawer()"> close </mat-icon>
</div>

<div class="mob-dropdown-content">
    <h4 class="w-100 pl-4 mb-4 heading-two">We are currently operating in the following countries</h4>
    <div class="country-tags">
      <div class="countries-list-inner">
        <a class="cursor-pointer" (click)="selectCountry(world)"
        >
        <div class="img-div">
          <img loading="lazy" [src]="world.imgUrl" alt="">
        </div>
        <div class="country-content">
          {{world?.name}}
        </div>
        </a>
      <a class="cursor-pointer" (click)="selectCountry(country)" *ngFor="let country of Countries"
        >
        <div class="img-div">
          <img loading="lazy" [src]="country.imgUrl" alt="">
        </div>
        <div class="country-content" [ngClass]="{'grayText':!country.startedOparations}">
          <p class="primary-blue" *ngIf="!country?.active">Coming Soon</p>
          <p class="primary-red" *ngIf="!country?.startedOparations">Not yet available</p>{{country?.name}}</div>
        </a>
      </div>
    </div>
  </div>