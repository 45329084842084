export class ServerStorage implements Storage {
    private data: { [key: string]: string } = {};
  
    get length(): number {
      return Object.keys(this.data).length;
    }
  
    clear(): void {
      this.data = {};
    }
  
    getItem(key: string): string | null {
        let value = this.data.hasOwnProperty(key) ? this.data[key] : null;

        if(key=='blogsaccessToken'){
            console.log("While Getting: "+ key+"->"+ value);
        }
        return value;
    }
  
    key(index: number): string | null {
      const keys = Object.keys(this.data);
      return index >= 0 && index < keys.length ? keys[index] : null;
    }
  
    removeItem(key: string): void {
      delete this.data[key];
    }
  
    setItem(key: string, value: string): void {
      this.data[key] = value;
    }

    decryption(id: number): any {
        throw new Error('Method not implemented.');
      }
  }