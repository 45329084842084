<div class="subscribe-modal">
              
    <span><mat-icon (click)="onNoClick()"> close </mat-icon></span>
                                
  <div class="subscribe-top">
    <figure>
      <img loading="lazy" src="../../assets/images/home/success-img.svg" alt="img">
      <!-- <img loading="lazy" [src]="subscribeImgPath +'/home/aumnics-subscribe-now.svg'" alt="img"> -->
    </figure>            
  </div>

  <div class="subscribe-cont">
    <h2 class="heading-one">{{"subscribenow.Thankyou" | translate}}</h2>
    <p class="paragraph-global-subtest">{{"subscribenow.ThankyouParagraph" | translate}}</p>

        <button (click)="onNoClick()">{{"buttons.okay" | translate}}</button>
  </div>
          
</div>


