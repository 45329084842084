import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({providedIn: 'root'})
export class SharedService {

  public developerPitch: any; // to set developr  pitch in the prifle view

  //social media links for amy
    public instagram = 'https://www.instagram.com/amy_pro_official/';
    public youtube = 'https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ';
    public twitter = 'https://twitter.com/amy_profficials';
    public linkedin = 'https://www.linkedin.com/company/amy-connect/';
    public facebook = 'https://www.facebook.com/Amyproofficial/';
    public medium = 'https://medium.com/@aumnicsai';

    private booleanValue = new BehaviorSubject(false);
    castValue = this.booleanValue.asObservable();

    public developerWorkId = new BehaviorSubject('');
    selectedDeveloperWorkId = this.developerWorkId.asObservable();

    public showSearchModal = new BehaviorSubject(false);
    showSearch = this.showSearchModal.asObservable();

    public countriesBoolean = new BehaviorSubject(false);
    showCountries = this.countriesBoolean.asObservable()

    public serviceProviderPreferences = new BehaviorSubject('');
    serviceProviderOptions = this.serviceProviderPreferences.asObservable()

    private scrollPositionSubject = new BehaviorSubject<number>(0); // Tracks scroll position
    public scrollPosition$ = this.scrollPositionSubject.asObservable();

    constructor(private router : Router) {
     }

    sendValue(newValue){
        this.booleanValue.next(newValue); 
    }

    setDeveloperWorkId(developerWorkId){
      this.developerWorkId.next(developerWorkId);
    }

    setSearchValue(value){
      this.showSearchModal.next(value);
    }

    setShowCountries(value){
      this.countriesBoolean.next(value);
    }

    sendSpPreference(value){
      this.serviceProviderPreferences.next(value); 
  }

   sendScrollEvt(scrollTop){
    this.scrollPositionSubject.next(scrollTop)
   }
    
    private methodCallSource = new Subject<number>();

    methodCalled$ = this.methodCallSource.asObservable();
  
    callMethod(id:number): void {
      this.methodCallSource.next(id);
  }

  //to set social media links by app name
  setSocialLinks(appId){
    if(appId == 'amy-connect' || appId == 'amyConnect'){
      this.instagram = 'https://www.instagram.com/amy_connect/';
      this.youtube = 'https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ';
      this.twitter = 'https://twitter.com/amy_connect1';
      this.linkedin = 'https://www.linkedin.com/company/amy-connect/';
      this.facebook = 'https://www.facebook.com/Amyconnectofficial/';
      this.medium = 'https://medium.com/@aumnicsai';
    } else if(appId == 'amy-cms' || appId == 'cms') {
      this.instagram = 'https://www.instagram.com/amy_cms/';
      this.youtube = 'https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ';
      this.twitter = 'https://twitter.com/AmyCms20082';
      this.linkedin = 'https://www.linkedin.com/company/amy-cms/';
      this.facebook = 'https://www.facebook.com/Amycmsofficial/';
      this.medium = 'https://medium.com/@aumnicsai';
    } else if(appId == 'amy-clockit' || appId == 'clockit'){
      this.instagram = 'https://www.instagram.com/amy_cms/';
      this.youtube = 'https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ';
      this.twitter = 'https://twitter.com/AmyClockit';
      this.linkedin = 'https://www.linkedin.com/company/amy-clockit/';
      this.facebook = 'https://www.facebook.com/AmyClockit/';
      this.medium = 'https://medium.com/@aumnicsai';
    }else {
      this.instagram = 'https://www.instagram.com/amy_pro_official/';
      this.youtube = 'https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ';
      this.twitter = 'https://twitter.com/amy_profficials';
      this.linkedin = 'https://www.linkedin.com/company/amy-connect/';
      this.facebook = 'https://www.facebook.com/Amyproofficial/';
      this.medium = 'https://medium.com/@aumnicsai';
    }
  }

  public getRandomColor(): any {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    const colors = ['#FDEB71', '#ABDCFF', '#FEB692', '#CE9FFC',
      '#32CCBC', '#F6416C', '#81FBB8', '#FCCF31',
      '#F4397F', '#F9D156'];
    return colors[(Math.random() * 10 + '').split('.')[0]];
    // return color;
  }

  public getConstantRandomColors(i): any {
    const colors = ['#FDEB71', '#ABDCFF', '#FEB692', '#CE9FFC',
      '#32CCBC', '#F6416C', '#81FBB8', '#FCCF31', '#F4397F', '#F9D156'];
    if (i > colors.length - 1) {
      i = Number(((i - colors.length) / colors.length).toFixed(1).split('.')[1]);
    }
    return colors[i];
  }

  public getShortName(name) {
    if (name != null && name.trim().length>0) {
      let shortName = name.match(/\b(\w)/g).join('');
      shortName = shortName.substring(0, 2).toUpperCase();
      if (shortName.length < 2) {
        shortName = name.substring(0, 2).toUpperCase();
      }
      return shortName;
    } else {
      return 'au';
    }
  }

  public getShortNameWithColor(name) {
    if (name != null && name.trim().length>0) {
      let shortName = name.match(/\b(\w)/g).join('');
      shortName = shortName.substring(0, 2).toUpperCase();
      if (shortName.length < 2) {
        shortName = name.substring(0, 2).toUpperCase();
      }
      return shortName + this.getRandomColor();  
    }
    return name;
  }
    
}