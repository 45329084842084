import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor() { }

  @Input() message: string = 'No data found';
  @Input() description: string;
  ngOnInit(): void {
  }

}
