import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  websiteUrl = environment.WEBSITE_URL;

  constructor(private title: Title,private metaService: Meta,@Inject(DOCUMENT) private document: Document,
  private platformLocation: PlatformLocation) {  
    if(environment?.showGoogleTagManager){
      this.loadTagManager();
    }    
    if(environment.name !== 'prod'){

    //creates robots meta tag for non prod environment
    const metaElement = this.document.createElement('meta');
    metaElement.setAttribute('name', 'robots');
    metaElement.setAttribute('content', 'noindex, nofollow');
    this.document.head.appendChild(metaElement);
    }
   }

  public setMetaData(data?){

    const description = 'Hire from Outsourcing Marketplace: Amy Connect is a global marketplace for software services agencies. Find Software development services from expert agencies globally. Businesses and organizations can hire talent from top agencies in a growing network of top talent from around the world.';
    const keywords = 'Amy';
    const tags = [
      { name: 'title', content:'Amy Connect: Online Global Marketplace For Software Services Agencies'},
      { name: 'description', content:description},
      { name: 'keywords', content: keywords},

      { name: 'twitter:card', content:'summary'},
      { name: 'twitter:image', content: 'https://aumnicswebsitedatastore.s3.ap-south-1.amazonaws.com/webimages/amy-connect-logo.svg'},
      { name: 'twitter:title', content:'Amy Connect: Online Global Marketplace For Software Services Agencies'},
      { name: 'twitter:description', content: 'Hire from Outsourcing Marketplace: Amy Connect is a global marketplace for software services agencies. Find Software development services from expert agencies globally. Businesses and organizations can hire talent from top agencies in a growing network of top talent from around the world.'},

      { property: 'og:image', content: 'https://aumnicswebsitedatastore.s3.ap-south-1.amazonaws.com/webimages/amy-connect-logo.svg'},
      { property: 'og:title', content:'Amy Connect: Online Global Marketplace For Software Services Agencies'},
      { property: 'og:description', content: 'Hire from Outsourcing Marketplace: Amy Connect is a global marketplace for software services agencies. Find Software development services from expert agencies globally. Businesses and organizations can hire talent from top agencies in a growing network of top talent from around the world.'},
      { property: 'og:type', content: 'website'},
      { property: 'og:url', content: this.extractOrigin + '/en/connect'}
    ]
    tags.forEach(keyword => this.metaService.updateTag(keyword));
  }


  public updateMetaTagsInformation( title, description , keywords? , image?, url? , type?): void  {
    //console.log("METATAGS : " +title, description, keywords,image);
    if(title){
    this.metaService.updateTag({ name : 'title', content : title })
    this.metaService.updateTag({ name : 'twitter:title', content : title })
    this.metaService.updateTag({ property : 'og:title', content : title })
    }

    if(description){
    this.metaService.updateTag( { name : 'description', content : this.limitCharacters(description ,320) })
    this.metaService.updateTag( { name : 'twitter:description', content : this.limitCharacters(description ,320) })
    this.metaService.updateTag( { property : 'og:description', content : this.limitCharacters(description ,320)})
    }

    if(keywords){
    this.metaService.updateTag({ name : 'keywords', content : keywords })
    }

    if(image){
    this.metaService.updateTag({ name : 'twitter:image', content : image })
    this.metaService.updateTag({ property : 'og:image', content : image })
    }

    if( url) {
      this.metaService.updateTag({ property : 'og:url', content : this.extractOrigin(null) +'/'+ url })
      this.setCanonicalURL(this.extractOrigin(null) +'/'+ url);
    }

    if(type){
      this.metaService.updateTag({ property : 'og:type', content : type })
    }

   
  }


   // Append the script to the head tag
   loadTagManager(noScript?: string): void {
    const script = environment.name === 'prod'
      ? `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-7KT4T6SKQN');
      `
      : '';
    const temp = this.document.createElement('script');
    const comment = this.document.createComment('Google Tag Manager');
    this.document.head.appendChild(comment);
    temp.innerHTML = script;
    this.document.head.appendChild(temp);

    if (environment.name == 'prod') {
      this.googleTagManagerNoscript(noScript);
    }
  }

  private googleTagManagerNoscript(noScript: string): void {
     // Create a script element
    const scriptElement = this.document.createElement('script');
    // Set the async attribute
    scriptElement.async = true;
    // Set the source attribute with your Google Tag Manager ID
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=G-7KT4T6SKQN';
    // Append the script element to the head of the document
    this.document.head.appendChild(scriptElement);
  }

  // Append the noscript element to the body tag
  googleTagMangerNoscript(noScript) {
    const noscriptElement = this.document.createElement('noscript');
    const iframeElement = this.document.createElement('iframe');
    iframeElement.src = noScript;
    iframeElement.height = '0';
    iframeElement.width = '0';
    iframeElement.style.display = 'none';
    iframeElement.style.visibility = 'hidden';

    noscriptElement.appendChild(iframeElement);
    const comment = this.document.createComment('Google Tag Manager (noscript)');
    this.document.body.appendChild(comment);
    this.document.body.appendChild(noscriptElement);
  } 

  setCanonicalURL(url: string): void {
    const head = document.getElementsByTagName('head')[0];
    const existingCanonical: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
    
    if (existingCanonical) {
      existingCanonical.href = url;
    } else {
      const element: HTMLLinkElement = document.createElement('link');
      element.rel = 'canonical';
      element.href = url;
      head.appendChild(element);
    }
  }
  
  //returns browser url on ssr
  getCurrentUrl(): string {
    return (this.platformLocation as any).location.href;
  }

  private extractOrigin(url: string): string {
    if(!url || url == null){
      url = this.getCurrentUrl()
    }
    const urlObject = new URL(url);
    return `${urlObject.protocol}//${urlObject.host}`;
  }


  //returns spliced string by given length
  limitCharacters(value: string, maxLength: number = 320): string {
    if (value === null || value === undefined) {
      return ''; // or handle it in another way based on your requirements
    }

    if (value.length > maxLength) {
      return value.substring(0, maxLength);
    } else {
      return value;
    }
  }
}
