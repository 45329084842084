import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})

export class CookieService  {
  cookieValue = 'test user';


  constructor(private cookieService: SsrCookieService){
  }
  ngOnInit(): void {

  }

   //Set cookies
  public setCookie(name: string, value: string){
    this.cookieService.set(name, value );
  }

   //Get Cookies
  public getCookie(name: string): string {
    return this.cookieService.get(name);
  }

   //delete cookie
   public deleteCookie(name: string): void {
    this.cookieService.delete(name);
   }

   //delete all cookies
   public deleteAllCookie(): void {
    this.cookieService.deleteAll();
   }
}
