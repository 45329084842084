import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
declare var $: any

@Component({
  selector: 'app-verticles',
  templateUrl: './verticles.component.html',
  styleUrls: ['../../skill-groups/skill-groups.component.scss', './verticles.component.scss']
})
export class VerticlesComponent implements OnInit {
  verticleTypeName: any;
  isVideoGuide: any;
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.getVerticles();
  }

  @Input() projcetModules = [];
  @Input() headerText: any;
  @Input() selectedVerticle;
  @Input() showTeam = false;
  @Input() isCompanyProfile = true;
  @Output() varticlesEmit: EventEmitter<any> = new EventEmitter();
  @Output() CloseEmit: EventEmitter<any> = new EventEmitter();
  skillLoader = false;
  searchText
  @Input() buttonName = 'Cancel';
  @Input() AddStyleClass = false;
  @Input() isHireTalent =false;
  selectedVerticals = [];
  loader = false;
  @Input() isClient = false;
  @Input() ProjectVerticle = false;
  @Input() projectPosting = false;
  @Input() demandLeadEdit = false;
  @Input() packageCategory = false;
  @Input() hideCss = false;

  public onVerticlesProceed(){
    if(!this.selectedVerticle) {
      return;
    }
    this.varticlesEmit.emit({verticleId: this.selectedVerticle, verticleTypeName: this.verticleTypeName});
  }
  public onCloseModel() {
    this.CloseEmit.emit({type:'back'});
  }
  
  public search() {

  }

  public getVerticles() {
    this.skillLoader = false;
    this.projcetModules = [];
    this.commonService.getAllIndustryTypes().subscribe((res: any)=> {
      if(this.selectedVerticle) {
        res.message.forEach(element => {
          if(element.id == this.selectedVerticle) {
            this.verticleTypeName = element.name;
          }
        });
      }
      this.projcetModules = res.message;
      this.skillLoader = true;
    }, err => {
      this.skillLoader = true;
    })
  }

  public onSelectProjcet(item) {
    this.selectedVerticle = item.id;
    this.verticleTypeName = item.name;
  }

  onSelectCategory(item: any) {
    if (this.selectedVerticals?.length > 0) {
      const index = this.selectedVerticals.findIndex((selectedItem) => selectedItem.id === item.id);
      if (index > -1) {
        this.selectedVerticals.splice(index, 1);
      } else {
        this.selectedVerticals.push(item);
      }
    } else {
      this.selectedVerticals.push(item);
    }
  }
}
