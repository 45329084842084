<mat-dialog-content>
<div class="subscribe-modal">
  <span><mat-icon (click)="onNoClick()"> close </mat-icon></span>

  <div class="subscribe-top">
    <figure>
      <!-- <img loading="lazy" src="../../assets/images/home/subscribe-now.svg" alt="img"> -->
      <img
        [src]="subscribeImgPath + '/home/aumnics-subscribe-now.svg'"
        alt="img"
      />
    </figure>
  </div>

  <div class="subscribe-cont">
    <h2 class="heading-one">{{ "subscribenow.subscribetitle" | translate }}</h2>
    <p class="paragraph-global-subtest">
      {{ "subscribenow.subscribeparagraph" | translate }}
    </p>
    <label>{{ "subscribenow.subscribesubtitle" | translate }}</label>
    <form [formGroup]="subscribeForm" (ngSubmit)="subscribeNow()">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'placeHolders.enterName' | translate }}"
        formControlName="userName"
      />
      <div
        *ngIf="f.userName.touched && f.userName.errors?.required"
        class="errors"
      >
        {{ "placeHolders.enterName" | translate }}
      </div>

      <!-- <p-captcha id="recaptcha" [siteKey]="env.CAPTCHA_SITE_KEY" class="captch-block">
                </p-captcha> -->

      <div class="captch-block">
        <p-captcha
          id="recaptcha"
          [siteKey]="env.CAPTCHA_SITE_KEY"
          (onResponse)="validateCaptcha()"
          (onExpire)="validateCaptcha()"
        >
        </p-captcha>
        <p id="recaptchaError" class="error d-none">
          {{ "placeHolders.required" | translate }}
        </p>
      </div>

      <button [disabled]="subscribeForm.invalid">
        {{ "subscribenow.subscribeButton" | translate }}
      </button>
      
    </form>
  </div>
</div>

</mat-dialog-content>