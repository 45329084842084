<div class="tabs-container d-flex w100">
    <div class="col-3 pt-3">
        <div *ngFor="let item of steps" class="w-100 steps-list d-flex" [ngClass]="{'selected': selectedStep == item.id, 'green-bg':item.status == 1, 'sub-heading-color': selectedStep < item.id}">
            <img *ngIf="item.status == 0 && selectedStep == item.id" loading="lazy" src="../../assets/icons/black-dot.svg" class="menu-icon-img mr-1" width="16px">
            <img *ngIf="item.status == 1" loading="lazy" src="../../assets/icons/blue-dot.svg" class="menu-icon-img mr-1" width="16px">
            <img *ngIf="item.status == 0 && selectedStep < item.id" loading="lazy" src="../../assets/icons/grey-dot.svg" class="menu-icon-img mr-1" width="16px">
            <span>{{item.name}}</span>
        </div>
    </div>
    <div class="col-9 p-0 border-left">
        <div class="d-none" [ngClass]="{'d-block p-0':projectTypeSelection && selectedStep == 1}">
            <div class="contactor-form pb-0">
                <form [formGroup]="projectTypeForm" (ngSubmit)="onSubmitProjectType()" class="project_type body-content pb-0 mt-3 px-4 pt-3">
                    <div class="formgrid d-flex mb-2">
                        <div class="col-12 px-0">
                            <label for="firstname1">
                                <span class="mr-2 fw-500">Project Title</span>
                                <span (click)="showInputExample()" pTooltip="Some title examples" tooltipPosition="bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 23"><defs><style>.a{fill:#96a1ba;stroke:rgba(0,0,0,0);}</style></defs><g transform="translate(0.5 0.5)"><path class="a" d="M11.188-10.312a11,11,0,1,0,11,11A11,11,0,0,0,11.188-10.312Zm0,19.871A8.866,8.866,0,0,1,2.317.688a8.871,8.871,0,1,1,17.742,0A8.866,8.866,0,0,1,11.188,9.558Zm0-14.992A1.863,1.863,0,0,0,9.325-3.571a1.863,1.863,0,0,0,1.863,1.863A1.863,1.863,0,0,0,13.05-3.571,1.863,1.863,0,0,0,11.188-5.433ZM13.671,5.833V4.768a.532.532,0,0,0-.532-.532h-.532V-.2a.532.532,0,0,0-.532-.532H9.236A.532.532,0,0,0,8.7-.2V.865a.532.532,0,0,0,.532.532h.532V4.236H9.236a.532.532,0,0,0-.532.532V5.833a.532.532,0,0,0,.532.532h3.9A.532.532,0,0,0,13.671,5.833Z" transform="translate(-0.188 10.312)"/></g></svg>
                                </span>
                            </label>
                            <input id="firstname1" type="text" pInputText placeholder="Project Title" formControlName="jobTitle" (focus)="onFocus(1)" (blur)="onBlur(1)"
                                class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                    </div>
                    <div class="formgrid d-flex">
                        <div class="col-12 px-0">
                            <label for="firstname1">
                                <span class="mr-2 fw-500">Description</span>
                                <span (click)="showTextareaExample()" pTooltip="Some title examples" tooltipPosition="bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 23"><defs><style>.a{fill:#96a1ba;stroke:rgba(0,0,0,0);}</style></defs><g transform="translate(0.5 0.5)"><path class="a" d="M11.188-10.312a11,11,0,1,0,11,11A11,11,0,0,0,11.188-10.312Zm0,19.871A8.866,8.866,0,0,1,2.317.688a8.871,8.871,0,1,1,17.742,0A8.866,8.866,0,0,1,11.188,9.558Zm0-14.992A1.863,1.863,0,0,0,9.325-3.571a1.863,1.863,0,0,0,1.863,1.863A1.863,1.863,0,0,0,13.05-3.571,1.863,1.863,0,0,0,11.188-5.433ZM13.671,5.833V4.768a.532.532,0,0,0-.532-.532h-.532V-.2a.532.532,0,0,0-.532-.532H9.236A.532.532,0,0,0,8.7-.2V.865a.532.532,0,0,0,.532.532h.532V4.236H9.236a.532.532,0,0,0-.532.532V5.833a.532.532,0,0,0,.532.532h3.9A.532.532,0,0,0,13.671,5.833Z" transform="translate(-0.188 10.312)"/></g></svg>
                                </span>
                            </label>
                            <textarea pInputTextarea rows="5" id="lastname1" placeholder="Description" formControlName="jobDescription" (focus)="onFocus(2)" (blur)="onBlur(2)"
                                class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
                        </div>
                        
                    </div>
                    <p class="project-type label mb-0 fw-500 mt-2">Choose the project type that suits your current requirement below
                    <p>
                    <div class="formgrid row pt-0 pl-2 d-flex">
                        <div class="col-4" *ngFor="let projectType of projectTypes;let i =index">
                            <div class="box p-4" (click)="onSelectProjectType(i+1)"
                                [ngClass]="{'selectedProject': activedProject-1 == i}">
                                <img loading="lazy" class="project-img" src={{projectType.projectIcon}}>
                                <h6 class="title sub-heading-color mt-1 mb-0">{{projectType.projectTitle}}</h6>
                                <!-- <p class="sub-heading-color">{{projectType.description1}}</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="formgrid hire-bottom-space d-none mt-3" [ngClass]="{'d-block':activedProject != 2}">
                        <label for="firstname1" class="fw-500 hire-table label">Work Type</label>
                        <div class="flex flex-wrap gap-3">
                            <ng-container *ngFor="let work of typeOfWorks">
                                <p-chip label="{{ work?.name }}" (click)="selectTypeOfWork(work)"
                                    [ngClass]="{'active-chip': isSelectedWork == work?.id}"></p-chip>
                            </ng-container>
                        </div>
                    </div>
                    <div class="formgrid d-none" [ngClass]="{'d-block':activedProject != 2}">
                        <div class="field">
                            <label for="firstname1" class="label fw-500">How would like to pay</label>
                            <div class="field-checkbox flex flex-nowrap gap-6">
                                <ng-container *ngFor="let type of billingType">
                                    <div class="flex flex-nowrap gap-3 pay-block how-would-mobile">
                                        <p-radioButton [inputId]="type.id" name="billingType" formControlName="billingType"
                                            [value]="type.id"></p-radioButton>
                                        <label [for]="type.id">{{ type.name }}</label>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid hire-bottom-space">
                        <label for="firstname1" class="label mt-1 fw-500 mt-3">Price per hour (max $ 1000)</label>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-3">
                                <label for="firstname1" class="fw-500 sub-hire-table label">Min Budget</label>
                                <div class="p-inputgroup">
                                    <p-inputNumber placeholder="US$" formControlName="minPrice" [maxlength]="3"
                                        [useGrouping]="false">
                                    </p-inputNumber>
                                    <span class="p-inputgroup-addon">USD</span>
                                </div>
                            </div>
                            <div class="col-12 md:col-3">
                                <label for="firstname1" class="sub-hire-table label fw-500">Max Budget</label>
                                <div class="p-inputgroup">
                                    <p-inputNumber placeholder="US$" formControlName="maxPrice" [maxlength]="4" [max]="1000"
                                        [useGrouping]="false"></p-inputNumber>
                                    <span class="p-inputgroup-addon">USD</span>
                                </div>
                                <small id="email-helpp" class="p-error block"
                                    *ngIf="projectTypeFormControl.maxPrice.errors?.maxPriceValidator">Max price greater than
                                    min price
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col col-md-6 col-sm-12">
                            <label for="firstname2" class="sub-hire-table fw-500">Job Location</label>
                            <p-autoComplete formControlName="jobLocation" id="locationInfo" class="w-100" styleClass="w-100"
                                [suggestions]="locations" placeholder="Type city name here"
                                (completeMethod)="onLocationSearch($event)" field="formattedName" [virtualScroll]="true"
                                [virtualScrollItemSize]="20" [required]="true" [showEmptyMessage]="true"
                                [forceSelection]="true" [lazy]="true">
                            </p-autoComplete>
                        </div>
                    </div>
                  </form>
                    <div class="mt-auto bottom-sticky-div" >
                        <hr>
                        <div class="py-4 d-flex model-bottom-sec">
                            <div class="ml-auto px-4">
                                <button type="button" (click)="close()" class="cancel-btn mr-3">Cancel</button>
                                <button type="submit" [disabled]="projectTypeForm.invalid || !activedProject"
                                    (click)="onSubmitProjectType()" class="save-btn">Continue</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="over-layout-footer p-5">
                        <div></div>
                        <div class="p-absolute footer-buttons border-0">
                            <button type="button" (click)="close()" class="cancel-btn">Cancel</button>
                            <button type="submit" [disabled]="projectTypeForm.invalid || !activedProject"
                                (click)="onSubmitProjectType()" class="save-btn">Continue</button>
                        </div>
                    </div> -->
                <!-- <div class="p-absolute footer-buttons border-0">
                    <button type="button" (click)="close()" class="cancel-btn">Cancel</button>
                    <button type="submit" [disabled]="projectTypeForm.invalid || !activedProject"
                        (click)="onSubmitProjectType()" class="save-btn">Continue</button>
                </div> -->
            </div>
        </div>
        <div class="d-none" [ngClass]="{' d-block':activedProject==1 && !projectTypeSelection && selectedStep == 4}">
            <form [formGroup]="contractorForm">
                <div class="body-content">
                    <div class="alert alert-primary" role="alert">
                        <mat-icon> info </mat-icon> You can now unlock the
                        resources within software services agencies and hire them for your
                        contract positions
                    </div>
                    <div class="contactor-form pb-0">
                        <!-- <div class="formgrid">
                            <div class="field">
                                <label for="firstname1">Job Title</label>
                                <input id="firstname1" type="text" pInputText placeholder="Job Title"
                                    formControlName="jobTitle2"
                                    class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                            </div>
                            <div class="field">
                                <label for="lastname1">Job Description</label>
                                <textarea pInputTextarea rows="5" id="lastname1" placeholder="Description"
                                    formControlName="jobDescription"
                                    class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
                                <small id="email-helpp" class="p-error block"
                                    *ngIf="contractorFormControl.jobDescription.errors?.maxlength">Maximum 5000
                                    characters only</small>
                            </div>
                        </div> -->
                        <div class="formgrid flex gap-5 min-exp-below-mobile">
      
                            <div class="field field1">
                                <label for="firstname2" class="fw-500">Minimum Experience</label>
                                <div class="flex flex-nowrap gap-3">
                                    <div class="group-addon group-addon1 minimum-exp-mobile flex flex-nowrap">
                                        <p-dropdown [options]="minYears" placeholder="Select a Year" optionLabel="name"
                                            optionValue="id" formControlName="minExperienceYr"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Years</span>
                                    </div>
                                    <div class="group-addon group-addon1 minimum-exp-mobile flex flex-nowrap">
                                        <p-dropdown [options]="months" placeholder="Select a Month" optionLabel="name"
                                            optionValue="id" formControlName="minExperienceMonth"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Months</span>
                                    </div>
                                </div>
                            </div>
      
                            <div class="field ">
                                <label for="lastname2"  class="fw-500">Maximum Experience</label>
                                <div class="flex flex-nowrap gap-3">
                                    <div class="group-addon flex flex-nowrap">
                                        <p-dropdown [options]="maxYears" placeholder="Select a Year" optionLabel="name"
                                            optionValue="id" formControlName="maxExperienceYr" [style]="{ width: '120px' }"
                                            (onChange)="selectMaxExperienceYr($event.value)"></p-dropdown>
                                        <span class="addon">Years</span>
                                    </div>
                                    <div class="group-addon flex flex-nowrap" *ngIf="isMaxExperienceMonth">
                                        <p-dropdown [options]="months" placeholder="Select a Month" optionLabel="name"
                                            optionValue="id" formControlName="maxExperienceMonth"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Months</span>
                                    </div>
                                </div>
                            </div>
      
                        </div>
                        <div class="formgrid hire-bottom-space">
                            <label for="firstname1" class="hire-table fw-500">How long you need this resource?</label>
                            <div class="flex flex-wrap gap-3">
                                <ng-container *ngFor="let duration of durations">
                                    <p-chip label="{{ duration?.name }}" (click)="selectDuration(duration)"
                                        [ngClass]="{'active-chip': isSelectedDuration == duration?.id}"></p-chip>
                                </ng-container>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-6 flex flex-column preferred-time-width">
                                <label for="timeZone1" class="fw-500">Preferred Time zone</label>
                                <p-autoComplete formControlName="preferredTimeZoneId" class="w-100" styleClass="w-100"
                                    [suggestions]="filteredTimeZones" placeholder="Type time zone here"
                                    (completeMethod)="filterTimeZone($event)" field="ftimeZone" [required]="true"
                                    [showEmptyMessage]="true" [minLength]="1" [forceSelection]="true" dataKey="id"
                                    [completeOnFocus]="true" [minLength]="0">
                                </p-autoComplete>
                            </div>
      
                            <div class="field col-6 flex flex-column urgency-width">
                                <label for="lastname2" class="fw-500">Urgency</label>
                                <p-dropdown [options]="leadUrgency" optionLabel="groupValue" optionValue="id"
                                    placeholder="Select Urgency" formControlName="urgency"></p-dropdown>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field flex col flex-column contract-width">
                                <label for="lastname2" class="fw-500">Contract Type</label>
                                <p-dropdown [options]="contractPositionTypes" optionLabel="name" optionValue="id"
                                    [disabled]="true" formControlName="contractTypes"></p-dropdown>
                            </div>
                            <div class="field col">
                                <label for="firstname2" class="fw-500">Employer type</label>
                                <div class="flex flex-wrap gap-3">
                                    <ng-container *ngFor="let employer of typeOfEmployer">
                                        <p-chip label="{{ employer?.name }}" (click)="selectEmployerType(employer)"
                                            [ngClass]="{'active-chip': isSelectedEmployerType == employer?.id}"></p-chip>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="formgrid">
                            <div class="field">
                                <label for="firstname1" class="fw-500">Do you have an account in Amy?</label>
                                <div class="field-checkbox flex flex-nowrap gap-6">
                                    <ng-container *ngFor="let account of aumnics">
                                        <div class="flex flex-nowrap pay-block gap-3">
                                            <p-radioButton [inputId]="account.id" name="isRegisteredUser"
                                                formControlName="isRegisteredUser" [value]="account.id"></p-radioButton>
                                            <label [for]="account.id">{{ account.name }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="formgrid hire-bottom-space">
                            <div class="field col-6 enter-email-padding">
                                <label for="firstname1" class="fw-500">Enter your email</label>
                                <input id="firstname1" type="text" pInputText placeholder="Enter email"
                                    formControlName="userEmail"
                                    class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                    aria-describedby="email-help" />
                                <small id="email-helpp" class="p-error block"
                                    *ngIf="contractorFormControl.userEmail.errors && contractorFormControl.userEmail.errors.email">Email
                                    must be a valid email address</small>
                            </div>
                        </div>
      
                        <div class="capth-block">
                            <p-captcha id="recaptcha" [siteKey]="env.CAPTCHA_SITE_KEY" (onResponse)="validateCaptcha($event)"
                                (onExpire)="validateCaptcha($event)">
                            </p-captcha>
                            <p id="recaptchaError" class="error d-none">Required!</p>
                        </div>
      
                    </div>
                </div>
                <div class="mt-auto bottom-sticky-div" >
                    <hr>
                    <div class="py-4 px-3 d-flex model-bottom-sec">
                        <div class="ml-auto px-4">
                            <button type="button" class="cancel-btn mr-3" (click)="close()"> Close</button>
                            <button type="button" class="save-btn sm-mt-20" type="submit"
                                [disabled]="contractorForm.invalid" (click)="onSubmitContractorForm()">Continue</button>
                        </div>
                    </div>
                </div>
                    <!-- <div class="over-layout-footer p-5">
                        <div></div>
                        <div class="p-absolute footer-buttons border-0">
                            <button type="button" class="cancel-btn" (click)="close()"> Close</button>
                            <button type="button" class="save-btn sm-mt-20" type="submit"
                                [disabled]="contractorForm.invalid">Continue</button>
                        </div>
                    </div> -->
            </form>
        </div>
        <div class="d-none"  [ngClass]="{' d-block': selectedStep == 2 && !projectTypeSelection}">
            <app-verticles (varticlesEmit)="getVerticles($event)" [selectedVerticle]="selectedVerticle" [AddStyleClass]="true"
                [hideCss]="true" [buttonName]="'Back'" [isCompanyProfile]="false" [ProjectVerticle]="true" [projectPosting]="true"
                (CloseEmit)="closeSelectedVeticle()">
            </app-verticles>
        </div>
      
        <div class="d-none"  [ngClass]="{' d-block': selectedStep == 3 && !projectTypeSelection}">
          <app-skill-groups (hireContractor)="skillSelection($event)" [activedProject]="activedProject" (drawerClose)="closeSkillGroupModal($event)" [hideCss]="true"></app-skill-groups>
        </div>

        <div class="d-none " [ngClass]="{'d-block ': activedProject==2 && !projectTypeSelection && selectedStep == 4}">
            <form [formGroup]="projectForm">
                <div class="body-content">
                    <div class="alert alert-primary" role="alert">
                        <mat-icon> info </mat-icon> Outsource your project requirements to software services agencies around the world after you receive their proposals
                    </div>
                    <div class="contactor-form pb-0">
                        <div class="formgrid flex gap-5 min-exp-below-mobile">
                            <div class="field field1">
                                <label for="firstname2" class="fw-500">Minimum Experience</label>
                                <div class="flex flex-nowrap gap-3">
                                    <div class="group-addon group-addon1 minimum-exp-mobile flex flex-nowrap">
                                        <p-dropdown [options]="minYears" placeholder="Select a Year" optionLabel="name"
                                            optionValue="id" formControlName="minExperienceYr"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Years</span>
                                    </div>
                                    <div class="group-addon group-addon1 minimum-exp-mobile flex flex-nowrap">
                                        <p-dropdown [options]="months" placeholder="Select a Month" optionLabel="name"
                                            optionValue="id" formControlName="minExperienceMonth"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Months</span>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <label for="lastname2" class="fw-500">Maximum Experience</label>
                                <div class="flex flex-nowrap gap-3">
                                    <div class="group-addon flex flex-nowrap">
                                        <p-dropdown [options]="maxYears" placeholder="Select a Year" optionLabel="name"
                                            optionValue="id" formControlName="maxExperienceYr" [style]="{ width: '120px' }"
                                            (onChange)="selectMaxExperienceYrCopy($event.value)"></p-dropdown>
                                        <span class="addon">Years</span>
                                    </div>
                                    <div class="group-addon flex flex-nowrap" *ngIf="isMaxExperienceMonthCopy">
                                        <p-dropdown [options]="months" placeholder="Select a Month" optionLabel="name"
                                            optionValue="id" formControlName="maxExperienceMonth"
                                            [style]="{ width: '120px' }"></p-dropdown>
                                        <span class="addon">Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="formgrid">
                            <div class="field">
                                <label for="firstname1" class="fw-500">Project status</label>
                                <div class="field-checkbox flex gap-6 project-mobile">
                                    <ng-container *ngFor="let continuity of projectContinuities">
                                        <div class="flex flex-nowrap gap-3 pay-block">
                                            <p-radioButton [inputId]="continuity.id" name="projectLifeCycle"
                                                formControlName="projectLifeCycle" [value]="continuity.id"></p-radioButton>
                                            <label [for]="continuity.id">{{ continuity.groupValue }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="formgrid hire-bottom-space describes-width">
                            <div class="field flex flex-column">
                                <label for="lastname2" class="fw-500">Which best describes your project?</label>
                                <p-dropdown [options]="typesOfWork" optionLabel="groupValue" optionValue="id" placeholder="Which best describes your project?" formControlName="typesOfWork"></p-dropdown>
                            </div>
                        </div>
                        <div class="formgrid">
                            <div class="field">
                                <label for="firstname1" class="fw-500">Where are you in the life cycle of the project ?</label>
                                <div class="field-checkbox flex gap-3 where-life-cycle-mobile">
                                    <ng-container *ngFor="let lifeCycleStage of listOfLifeCycleStages">
                                        <div class="flex flex-nowrap gap-3 pay-block">
                                            <p-radioButton [inputId]="lifeCycleStage.id" name="lifeCycleStageId" formControlName="lifeCycleStageId" [value]="lifeCycleStage.id">
                                              </p-radioButton>
                                            <label [for]="lifeCycleStage.id">{{ lifeCycleStage.stage }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
      
                        <div class="formgrid grid">
                            <div class="field col-md-6 col-sm-12 flex flex-column preferred-time-width">
                                <label for="lastname2" class="fw-500">Preferred Time zone</label>
                                <p-autoComplete formControlName="preferredTimeZoneId" id="locationInfo" class="w-100"
                                    styleClass="w-100" [suggestions]="filteredTimeZones" placeholder="Type time zone here"
                                    (completeMethod)="filterTimeZone($event)" field="ftimeZone" [required]="true"
                                    [showEmptyMessage]="true" [lazy]="true" [minLength]="1" [forceSelection]="true"
                                    dataKey="id" [completeOnFocus]="true" [minLength]="0">
                                </p-autoComplete>
                            </div>
                        </div>
                        <div class="formgrid hire-bottom-space">
                            <div class="field flex flex-column">
                                <label for="lastname2" class="fw-500">Urgency</label>
                                <p-dropdown [options]="leadUrgency" optionLabel="groupValue" optionValue="id" placeholder="Select Urgency" formControlName="urgency">
                                  </p-dropdown>
                            </div>
                        </div>
                        <div class="formgrid">
                            <div class="field">
                                <label for="firstname1" class="fw-500">Do you have an account in Amy?</label>
                                <div class="field-checkbox flex flex-nowrap gap-6">
                                    <ng-container *ngFor="let account of aumnics">
                                        <div class="flex flex-nowrap gap-3">
                                            <p-radioButton [inputId]="account.id" name="isRegisteredUser" formControlName="isRegisteredUser" [value]="account.id"></p-radioButton>
                                            <label [for]="account.id">{{ account.name }}</label>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
      
                        <div class="formgrid hire-bottom-space">
                            <div class="field col-6 enter-email-padding">
                                <label for="firstname1" class="fw-500">Enter your email</label>
                                <input id="firstname1" type="text" pInputText placeholder="Enter email"
                                    formControlName="userEmail"
                                    class="text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                    aria-describedby="email-help" />
                                <small id="email-helpp" class="p-error block"
                                    *ngIf="projectFormControl.userEmail.errors && projectFormControl.userEmail.errors.email">Email
                                    must be
                                    a
                                    valid email address</small>
                            </div>
                        </div>
      
                        <div class="capth-block">
                            <p-captcha id="recaptcha" [siteKey]="env.CAPTCHA_SITE_KEY" (onResponse)="validateCaptcha2($event)"
                                (onExpire)="validateCaptcha2($event)">
                            </p-captcha>
                            <p id="recaptchaError2" class="error d-none">Required!</p>
                        </div>
      
                    </div>
                </div>
              <!--<div class="p-absolute footer-buttons border-0">
                    <button type="button" (click)="close()" class="cancel-btn">Cancel</button>
                    <button type="button" (click)="submitProject()" [disabled]="projectForm.invalid"
                        class="save-btn">Continue</button>
                </div> -->
                <!-- <div class="over-layout-footer p-5">
                    <div></div>
                    <div class="p-absolute footer-buttons border-0 ">
                        <button type="button" (click)="close()" class="cancel-btn">Cancel</button>
                        <button type="button" (click)="submitProject()" [disabled]="projectForm.invalid"
                            class="save-btn">Continue</button>
                    </div>
                </div> -->
                <div class="mt-auto bottom-sticky-div" >
                    <hr>
                    <div class="pt-4 pb-4 d-flex model-bottom-sec">
                        <div class="ml-auto px-4">
                            <button type="button" (click)="closeDetsilsModal($event)" class="cancel-btn mr-3">Cancel</button>
                            <button type="button" (click)="submitProject()" [disabled]="projectForm.invalid"
                                class="save-btn">Continue</button>
                        </div>
                    </div>
                </div>
            </form>
      
        </div>
    </div>
  </div>
  <p-toast></p-toast>