import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from './primeng.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MaterialModuleModule } from '../material-module/material-module/material-module.module';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PaginatorModule } from 'primeng/paginator';
import { ProfileViewCardComponent } from './profile-view-card/profile-view-card.component';
import {PanelModule} from 'primeng/panel';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { TabMenuModule } from 'primeng/tabmenu';
import {MatTabsModule} from '@angular/material/tabs';
import { CountriesListingModalComponent } from './countries-listing-modal/countries-listing-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
//import { AmyChatBotComponent } from '../amy-chat-bot/amy-chat-bot.component';
import { CarouselModule } from 'primeng/carousel';
import { NgImageSliderModule } from 'ng-image-slider';
import { SkillGroupsComponent } from '../skill-groups/skill-groups.component';
import { HireContractorComponent } from '../hire-contractor/hire-contractor.component';
import { MobileSearchComponent } from './mobile-search/mobile-search.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SkeletonModule } from 'primeng/skeleton';
import { VerticlesComponent } from './verticles/verticles.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const sharedComponent: any[] = [
  SkillGroupsComponent,
  HireContractorComponent,
  MobileSearchComponent,
  NotFoundPageComponent,
  ProfileViewCardComponent,
  CountriesListingModalComponent,
  SkeletonLoaderComponent,
  VerticlesComponent
  // AmyChatBotComponent
]

@NgModule({
  declarations: [
    sharedComponent
  ],
  imports: [
    CommonModule,
    MaterialModuleModule,
    PrimengModule,
    FormsModule,
    TranslateModule,
    BreadcrumbModule,
    PaginatorModule,
    DragDropModule,
    PanelModule,
    TabMenuModule,
    CarouselModule,
    MatTabsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    SkeletonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    sharedComponent,
    MaterialModuleModule,
    PrimengModule,
  ]
})
export class SharedModule { }
