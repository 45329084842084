<div class="scroll-y-container">
    <div class="dev-backgroud-cover">
        <div class="developer-banner-img">
            <img loading="lazy" src="../../../assets/images/dev-card/Mask Group 12519.png" alt="">
        </div> 
    </div>
    <div class="over-layout">
        <div class="over-layout-content container">
            <div class="card-bg">
                <div class="main-card" *ngIf="profile">
                  <div class="dev-main-card common-dev-card-div w-100">
                    <mat-icon class="dev-cancel cursor-pointer" aria-hidden="false" aria-label="Example clear icon" fontIcon="close" (click)="close()"></mat-icon>
                    <div class="d-flex card-content-sec">
                      <div class="">
                        <div class="profileLogoDiv">
                          <div class="profileLogo profileLogo-140 cursor-p"
                            *ngIf="!profile.userProfle.shortName" [style.background-image]="'url('+profile.userProfle.profilePicture+')'">
                          </div>
                          <div class="profileLogo profileLogo-140" *ngIf="profile.userProfle.shortName"
                            [style.background-color]="profile.userProfle.shortName.substring(2 ,9)">
                            <span>{{profile.userProfle.shortName.substring(0, 2)}}</span>
                          </div>
                        </div>
                        <div class="talent-scores">
                          <h2 class="fw-500 aumnics-blue title mb-0 mt-0">
                          <img loading="lazy" class="thunderIcon mr-0 mb-1"
                                src="../../../assets/images/dev-card/blue-talent-score-star.svg" alt="">
                            {{ profile.userProfle.talentScore ? profile.userProfle.talentScore : "-" }}</h2>
                          <p class="f-12" style="margin-top:-5px;">{{ 'Talent score' }} </p>
                        </div>
                      </div>
                      <div class="message-sec w-100">
                        <div class="profileDetailsDiv pl-4 sm-pl-2">
                          <div class="d-flex dev-res-contet justify-content-between m-0">
                            <div class="w-100 p-0">
                              <div class="row m-0">
                                <div class="col-md-10 col-sm-12 col-xs-12 p-0">
                                  <h4 class="mb-2 mt-3 dev-name">{{profile.userProfle.firstName}} {{profile.userProfle.lastName}}
                                  </h4>
                                  
                                  <p class="mb-1  paragraph-global-text sub-heading-color">{{profile.userProfle.designation}}</p>
                                  <p class="mb-1 d-center paragraph-global-text">
                                    <label class="location-bg">
                                      <img loading="lazy" src="../../../assets/images/dev-card/locatin-black-pin.svg" width="8" alt="location-icon" class="mr-2">
                                      {{profile.userProfle.cityName && profile.userProfle.cityName?.length > 0 && profile.userProfle.cityName ? profile.userProfle.cityName : '-'}},
                                      {{profile.userProfle.countryName && profile.userProfle.countryName?.length > 0 && profile.userProfle.countryName ?
                                      profile.userProfle.countryName : '-'}}
                                    </label>
                                    
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="p-0 text-right float-right">
                              <div class="text-right d-flex m-0">
                                <button class="cancel-btn mr-3">
                                    <a [href]="signUrl + 'sign-in?returnUrl=viewProfile/' + profile.userProfle.userId" target="_blank">Message</a>
                                </button>
                                <button class="save-btn">
                                    <a [href]="signUrl + 'sign-in?returnUrl=viewProfile/' + profile.userProfle.userId" target="_blank">Hire</a>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="prop marginStyle pt-3">
                            <div class="prop d-center align-items-center flex-wrap marginStyle pt-1 mb-2 col-12 pl-0 pr-0 column-gap-35">
                              <div class=" paddingStyle">
                                <p class="paragraph-global-text fw-500 mb-1">Amount earned</p>
                                <p class="paragraph-global-text ">{{profile.userProfle?.amountEarned ? ('$'+profile.userProfle.amountEarned) : '-'}}</p>
                              </div>
                              <div class="border-right"></div>
                              <div class=" paddingStyle" *ngIf="profile.userProfle?.jobSuccessScore">
                                <p class="paragraph-global-text  fw-500 mb-1">Job success rate</p>
                                <p class="paragraph-global-text "*ngIf="profile.userProfle?.jobSuccessScore > 0">{{profile.userProfle.jobSuccessScore}}%</p>
                                <p class="paragraph-global-text "*ngIf="profile.userProfle?.jobSuccessScore == 0 || !profile.userProfle.jobSuccessScore">-</p>
                              </div>
                         
                            <div class="border-right" *ngIf="profile.userProfle?.jobSuccessScore"></div>
                            <div class=" paddingStyle" *ngIf="profile.userProfle?.totalExperienceYr || profile.userProfle?.totalExperienceMonth">
                              <p class="paragraph-global-text  fw-500 mb-1">Experience</p>
                              <p class="paragraph-global-text ">
                                {{profile.userProfle?.totalExperienceYr}}
                                <span *ngIf="profile.userProfle?.totalExperienceMonth">.{{profile.userProfle?.totalExperienceMonth}}
                                </span>
                                Years
                              </p>
                            </div>
                            <div class="border-right" *ngIf="profile.userProfle?.totalExperienceYr || profile.userProfle?.totalExperienceMonth"></div>
                            <div class=" paddingStyle">
                              <p class="paragraph-global-text  fw-500 mb-1">Availability</p>
                              <p class="paragraph-global-text">{{profile.jobType || "Full time"}}</p>
                            </div>
                            <div class="border-right"></div>
                            <div class=" paddingStyle">
                              <p class="paragraph-global-text  fw-500 mb-1">{{profile.userProfle?.budgetType ? profile.userProfle.budgetType : 'Per hour'}}</p>
                              <p class="fw-500 paragraph-global-text  aumnics-blue">${{profile.userProfle?.budget ? profile.userProfle.budget : '-'}}</p>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                <div class="d-flex profile-sub-card">
                  <div class="profile-lft-sec">
                    <div class="common-dev-card-div">
                      <div class="">
                        <ng-container *ngIf="primarySkills && primarySkills?.length > 0">
                          <h6 class="heading-three" *ngIf="primarySkills && primarySkills.length > 0">
                          <img loading="lazy" src="../../../assets/images/dev-card/skills-icon-dev-card.png" class="pr-1 pb-1" alt="error" width="30">Skills ({{primarySkills.length}})</h6>
                          <div class="row d-flex tag-div m-0 p-1 pl-0 ml-n1" *ngIf="primarySkills && primarySkills.length > 0">
                            <span *ngFor="let skill of primarySkills,let i = index" class="m-1 mt-2 mb-3">
                              <span class="select-tag">{{skill.skillName.slice(0,20) }}{{ skill.skillName.length > 20 ? '...' : '' }}
                              </span>
                            </span>
                          </div>
                        </ng-container>
                       
                        <ng-container *ngIf="secondarySkills?.length > 0">
                          <h6 class="heading-three mt-3">Secondary skills ({{secondarySkills?.length}})</h6>
                          <div class="row d-flex tag-div m-0 p-1 pl-0 ml-n1" *ngIf="secondarySkills && secondarySkills?.length > 0">
                            <span *ngFor="let skill of secondarySkills,let i = index" class="m-1 mt-2 mb-3">
                              <span class="select-tag">{{skill.skillName.slice(0,20) }}{{ skill.skillName.length > 20 ? '...' : '' }}
                              </span>
                            </span>
                          </div>
                        </ng-container>
                      </div>
                      <hr class="my-3 mt-4">
                      <div class="mt-4">
                        <h5 class="mb-4 heading-three">{{'Languages'}} ({{profile.languages?.length}})</h5>
                        <div class="d-inline-flex" style="flex-wrap:wrap;">
                          <div class="mb-3 mr-2 p-1 sm-min-width-none" *ngFor="let lang of profile.languages">
                            <h5 class="mb-1 d-block paragraph-global-text">{{lang.languageName}}</h5>
                            <p class="mb-0 paragraph-global-text">{{languageLevel[lang.languageLevelId -1].name}}</p>
                          </div>
                        </div>
                        <hr class="my-3 mt-4 mb-4">
                        <div *ngIf="profile">
                          <div class="mb-3" *ngIf="profile?.userProfle.userTimezone">
                            <h5 class="mb-1 d-block paragraph-global-text">Current - Timezone</h5>
                            <p class="mb-0 paragraph-global-text">{{profile.userProfle.userTimezone ? profile.userProfle.userTimezone: ''}}</p>
                          </div>
                          <div class="mb-3" *ngIf="profile?.userProfle.workAsPerClientTimezone">
                            <h5 class="mb-1 d-block paragraph-global-text">Preferred - Timezone</h5>
                            <p class="mb-0 paragraph-global-text">Can work in any timezone</p>
                          </div>
                          <div class="mb-3"
                            *ngIf="!profile.userProfle.workAsPerClientTimezone && profile?.userProfle.preferredTimezoneList && profile.userProfle.preferredTimezoneList?.length > 0">
                            <h5 class="mb-1 d-block mb-1 paragraph-global-text">Preferred</h5>
                            <p class="mb-0 paragraph-global-text" *ngFor="let list of profile.preferredTimezoneList,let i = index">
                              {{list.ftimeZone}}
                            </p>
                          </div>
                          <div class="mb-3">
                            <h5 class="mb-1 d-block mb-1 paragraph-global-text">
                              Candidate will adjust meeting times as per the client requirements</h5>
                            <p class="mb-0 paragraph-global-text">{{profile.userProfle.adjustMeetingTimesAsPerClient ? 'Yes' : 'No'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
                <div class="view-components profile-rgt-sec">
              
                  <div class="common-dev-card-div">
                    <h5  class="heading-three"><img loading="lazy" src="../../../assets/images/dev-card/about-info.png" class="pr-1 pb-1" alt="error" width="35"> About</h5>
                    <p class="projDescTruck paragraph-global-text pre" *ngIf="!sharedService.developerPitch && profile.userProfle.userAboutMe">
                      {{profile.userProfle.userAboutMe}}
                    </p>
                    <div class="projDescTruck paragraph-global-text pre" *ngIf="sharedService.developerPitch"  [innerHtml]="sharedService.developerPitch"></div>
                  </div>    
                  
                  <div class="common-dev-card-div">
                    <h5  class="heading-three"><img loading="lazy" src="../../../assets/images/dev-card/work-exp-bag.png" class="pr-1 pb-1" alt="error" width="35">Work experience</h5>
                    <div class="w-100 py-5 my-5 d-flex flex-column align-items-center justify-content-center">
                      <p class="text-center paragraph-global-text mb-3">Log in to see developer full details.</p>
                      <div class="save-btn">
                        <a class="no-text-wrap" [href]="signUrl + 'sign-in?returnUrl=viewProfile/' + profile.userProfle.userId" target="_blank">{{"Header.LogIn" | translate}}</a>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
        </div>
    </div>
  </div> 
</div>