export enum AppConstants {

    // default  min and max lengths
    INPUT_MINLENGTH = 5,
    INPUT_MAXLENGTH = 100,
  
    // input min lengths
    INPUT_MINLENGTH_3 = 3,
    INPUT_MINLENGTH_5 = 5,
    INPUT_MINLENGTH_10 = 10,
    INPUT_MINLENGTH_15 = 15,
    INPUT_MINLENGTH_20 = 20,
    INPUT_MINLENGTH_50 = 50,
    INPUT_MINLENGTH_9 = 9,
  
    // input max lengths
    INPUT_MAXLENGTH_10 = 10,
    INPUT_MAXLENGTH_20 = 20,
    INPUT_MAXLENGTH_45 = 45,
    INPUT_MAXLENGTH_50 = 50,
    INPUT_MAXLENGTH_100 = 100,
    INPUT_MAXLENGTH_150 = 150,
    INPUT_MAXLENGTH_250 = 250,
    INPUT_MAXLENGTH_2000 = 2000,
    INPUT_MAXLENGTH_9 = 9,
  
    // text area min and max lengths
    TEXTAREA_MINLENGTH = 100,
    TEXTAREA_MAXLENGTH = 2000,
  
    // others
    PAN_CARD_LENGTH_10 = 10,
    TWO_MB_MAX_FILE_SIZE = 2,
  
    //EMAIL MAX LENGTH
    EMAIL_MAX_LENGTH = 320,
  
    // date formats
    DATE_FORMAT = 'dd M, yyyy',
    YEAR_FIRST_DATE_FORMAT = 'YYYY-MM-DD',
    SHORT_DATE_FORMAT = 'dd MMM yyyy',
    DATE_FORMAT_WITH_DAY = 'DD, MM d, yyyy',
    DATE_SORTS_FORMAT = 'MMM DD, YYYY',
    MOMENT_TIME_FORMAT = 'dddd, MMMM Do YYYY, h:mm a',
    AUMNICS_DATE_VIEW_FORMAT = 'MMM dd, yyyy',
    SHORT_TIME_FORMAT = 'hh:mm a',
    TEAM_OBJECT = 'teamObj',
    TEAM_CODE = 'teamCode',
    COMPANY_NAME = 'companyName',
    CREATE_COMPANY = 'create-company',
    COMPANY_LOGO = 'companyLogo',
    PHONE_NUMBER_DEFAULT_COUNTRY_CODE = 'US',
    PHONE_NUMBER_DEFAULT_PREFIX = '+1',
    OTP = 'otp',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    MONTH_YEAR_FORMAT = 'M yyyy',
    MONTHS = 'months',
    TEAM = 'team',
    COMPANY_MEMBER = 'Company Member',
    FREELANCER = 'freelancer',
    PROJECT_HEADER = 'projectHeader',
    NEW_PROJECT = 'newProject',
    TAB = 'tab',
    PROJECT_DETAILS_OBJECT = 'projectDetailsObj',
    PROJECT_TYPE_OBJECT = 'projectTypeObj',
    PROJECT_DESCRIPTION_OBJECT = 'projectDescriptionObj',
    ACCEPT_PROVIDER_DETAILS = 'acceptProviderDetails',
    LOGGEDIN = 'loggedIn',
    CHAT_LOCAL_SESSION_JID = 'sessionJid',
    PROJECT_ID = 'projectId',
    REQUIREMENT_ID = 'requirementId',
    NO_OF_SUGGESTIONS = 20,
    NO_OF_PAGES = 20,
    INPUT_MAX_LENGTH_4 = 4,
    BILLING_TYPE_PER_HOUR = 106,
    BILLING_TYPE_MONTHLY_FIXED = 107,
    JOB_TYPE_FULL_TIME = 64,
    JOB_TYPE_PART_TIME = 65,
    HOUR_LIMIT_PER_WEEK = 111,
    HOUR_LIMIT_PER_MONTH = 112,
    FULL_CALENDAR_DATE_FORMAT = 'DD , dd MM',
    SHORT_CALENDAR_DATE_FORMAT = 'M d, yyyy',
    LOCAL_TIME_FORMAT = 'LT',
    REGISTRATION_FLOW_TYPE = 'registrationFlowType',
    FILES_TOGGLE_LIST = 'filesToggleList',
    CLIENT = 'client',
    EMPLOYEE = 'employee',
    CONVERSTAION_TYPE_PUBLIC_GROUP_CHAT = 1,
    CONVERSTAION_TYPE_USER_MULTI_CHAT = 2,
    CONVERSTAION_TYPE_CLIENT_MULTI_CHAT = 3,
    CONVERSTAION_TYPE_THREAD_CHAT = 4,
    CONVERSTAION_TYPE_USER_ONE_TO_ONE_CHAT = 5,
    CONVERSTAION_TYPE_PRIVATE_GROUP_CHAT = 6,
    CONVERSTAION_TYPE_CLIENT_ONE_TO_ONE_CHAT = 7,
    CHAT_SORT_TYPE = 'chatSortType',
    PROJECT_POSTING_TYPE = 'project-posting-type',
    PROFESSIONAL_TO_BE_INVITED = 'professionalToBeInvited',
    PROFESSIONAL_TO_BE_HIRED = 'professionalToBeHired',
    ADD_CLIENT_FOR_SUB_CONTRACT = 'addClientForSubContracting',
    PACKAGE_SUBSCRIPTION_INFO = 'packageSubscriptionInfo',
    COMPANY_PITCHES_INFO = 'companyPitchesInfo',
    COMPANY_PITCHES_SKILLS_INFO = 'companyPitchesSkillsInfo',
    VIEW_PROFILE = 'viewProfile',
    SHARED_PROFILE_DATA = 'sharedProfileData',
    AFFILIATE_MARKETER = 'amp',
    AFFILIATE_MARKETER_SIGNUP = 'signUp',
    SEARCH_COMPANY_PITCHES_SKILLS_INFO = 'searchcompanyPitchesSkillsInfo',
    VIEW_SAVED_SEARCHES = 'viewSavedSearches',
    ACP_SUBSCRIBER_USER_ID = 'acpSubscribedUserId',
    TUTORIAL_OBJECT = 'tutorials',
    REPORT_ABUSE = 'reportAbuse',
    FOLLOW_AGENCY = 'followAgency',
    // publish steps types
    PUBLISH_MEMBERS_CHOSEN = 2,
    PUBLISH_VERIFY_MEMBERS = 3,
    PUBLISH_SETUP_PRICE = 4,
    PUBLISH_TEAM_LEAD_SURVEY = 5,
    PUBLISH_VETTED_MEMBERS = 8,
    PUBLISH_ON_CONNECT = 9,
  
    // sign in logs 
    LOGIN = 'LOGIN',
    BREAK = 'BREAK',
    RETURN_FROM_BREAK = 'RETURN_FROM_BREAK',
    SIGNING_OFF = 'SIGNING_OFF',
  
    // publish email and notification modal requset types
    PUBLISH_PROFILE_REQUEST = 1,
    PUBLISH_IDENTITY_REQUEST = 2,
    PUBLISH_PROJECT_AND_MODULE_REQUEST = 3,
    PUBLISH_SCHEDULE_QUALIFYING_ROUND_INTERVIEW_REQUEST = 4,
    PUBLISH_SCHEDULE_LOGICAL_ROUND_INTERVIEW_REQUEST = 5,
    PUBLISH_SCHEDULE_SUBJECT_ROUND_INTERVIEW_REQUEST = 6,
    PUBLISH_USER_AGREEMENT_REQUEST = 7,
    PUBLISH_SETUP_PRICE_DETAILS_REQUEST = 1,
    PUBLISH_PAYMENT_DETAILS_REQUEST = 2,
    PUBLISH_AGREEMENT_DETAILS_REQUEST = 3,
  
    // calendar event user response
    CALENDAR_EVENT_RESPONSE_NO = 0,
    CALENDAR_EVENT_RESPONSE_YES = 1,
    CALENDAR_EVENT_RESPONSE_MAYBE = 2,
  
    // Apps integration
    GITLAB_ID = 8,
    JENKINS_ID = 9,
    GOOGLE_CALENDAR_ID = 10,
    BIT_BUCKET_ID = 9,
    AUMNICS_DESK = 12,
    AUMNICS_BLOG = 13,
    CAREERS_PAGE = 14,
    AUMNICS_NEWS_PAGE = 15,
    AUMNICS_CMS = 16,
    AMY_CONNECT_APP = 1,
  
    USER_COMMENT_COMMENTS_DATA = 'commentsData',
    USER_COMMENT_CLIENT_WORK_ID = '${clientWorkId}',
    USER_COMMENT_SP_WORK_ID = '${spWorkId}',
    USER_COMMENT_DEV_WORK_ID = '${devWorkId}',
    USER_COMMENT_REQ_ID = '${reqId}',
    USER_COMMENT_DATE_TIME = '${dateTime}',
  
    SCHEDULE_MEETING = 'scheduleMeeting',
  
    PAYMENT_TYPE_HOURLY = 106,
    PAYMENT_TYPE_MONTHLY = 107,
    PAYMENT_TYPE_MILESTONE = 210,
    FREE_TRAIL_PERIOD = '1 Week',
    RESTRICTIONS = '1 Month',
    FIRST_MONTH_BILLING_CYCLE = 'Bi weekly + 7 days',
    SECOND_MONTH_BILLING_CYCLE = 'T + 7 days',
    THIRD_MONTH_BILLING_CYCLE = 'T + 30 days',
  
    WEBSITE_REQUIREMENT = 'websiteRequirement',
    ORDER_PAYMENT = 'orderPayment',
    SUBSCRIPTION_PAYMENT = 'subscriptionPayment',
    BANK_ACCOUNT_VERIFICATION = 'bankAccountVerification',
    FREE_TRIAL = 'freeTrial',
  
    US_COUNTRY_ID = 237,
    INDIA_COUNTRY_ID = 103,
  
    NMI_COLLECT_JS_TOKEN = '4UPPAu-dwq7tx-xeq6EW-6B9HaX',
  
    APP_TOUR_VIEWED_STATUS = 'appTourViewedStatus',
    COMPANY_ONBOARDING_STATUS = 'companyOnboardingStatus',
    INDIA_TZ_ID_IST = 76507,
    USA_TZ_ID_IST = 136517,
    INDIA_TZ_PLACE_HOLDER = 'India (IST +05:30)',
  
    PUBLIC_SHARED_PROFILE_ID = 'shareProfileId',
    USER_SKIP_DEMAND_INFO = 'userSkipDemandInfo',
    PUBLIC_SHARED_PROJECT_ID = 'shareProjectId',
    SHARED_PROFILE_UU_ID = 'staticProfileUuid',
    DEVELOPER_DATA_FOR_SEARCH = 'developerDataForSearch',
    PROPOSAL_ID = 'proposalId',
    APP_HOME_PAGE_SKILLS_OBJ = 'appHomePageSkillsObj',
    HIRE_TALENT_USER_OBJ = 'hireTalentUserObject',
    HIRE_TALENT_SEARCH_RESULTS_FILTERS = 'hireTalentSearchResultsFilters',
    HIRE_TALENT_SEARCH_RESULTS_API_FILTERS = 'hireTalentSearchResultsApiFilters',
    VERTICLES_OBJ = 'verticlesObj',
    WEBSITE_PROJECT_ID = 'websiteRequirementId',
  
    MODERATOR_RECENT_SELECTION = 'moderatorRecentSelection',
    EXECUTIVE_SUMMARY = 'executiveSummary',
  
    TOP_DEMAND = 212,
    MEDIUM_DEMAND = 213,
    LOW_DEMAND = 214,
    NEW_dEMAND =215,
    SUBSCRIBED_PACKAGE_INFO = 'subscribedPackageInfo',
    SUBSCRIBED_PACKAGE_INFO_ID = 'subscribedPackageInfoId',
    CART_ORDERS_PAYMENT = 'cartOrdersPayment',
  
    AUMNICS_DESK_SEO = 1,
    AUMNICS_TICKET_SEO = 2,
    AUMNICS_BLOG_SEO = 3,
    CAREERS_PAGE_SEO = 4,
    AUMNICS_NEWS_PAGE_SEO = 5,
    AMY_APP_ID = 2,
  
    //projecttypes
    OUTSOURCE_YOUR_REQUIREMENT = 'Outsource your requirement',
    LARGE_PROJECT = 'Large project',
    HIRE_CONTACTOR = 'Hire contractor',
    CROSS_HIRE = 'Cross hire',
  
    //filter options
    DEE_FILTER_OBJ = 'deeFilterObject',
  
  
    //timer related keys
    TIMER_DETAILS = 'timerDetails',
  
    //project management
    TASK_VIEW_MODE = 'taskViewMode'
  }
  