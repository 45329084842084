import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../app/core/services/shared.service';

declare var $:any;

@Component({
  selector: 'app-countries-listing-modal',
  templateUrl: './countries-listing-modal.component.html',
  styleUrls: ['./countries-listing-modal.component.scss']
})
export class CountriesListingModalComponent implements OnInit {

  constructor(private router: Router,public sharedService : SharedService) { }

  @Output() drawerClose = new EventEmitter();

  Countries = [
    {
      name: "United States",
      id: "usa-item",
      imgUrl: "../assets/icons/Explore-countries/usa_explore.svg",
      navLink: "en/connect/software-services/united-states",
      active: true,
      startedOparations: true,
    },
    {
      name: "India",
      id: "india-item",
      imgUrl: "../assets/icons/Explore-countries/India_explore.svg",
      navLink: "en/connect/software-services/india",
      active: true,
      startedOparations: true,
    },
    {
      name: "United Arab Emirates",
      id: "uae-item",
      imgUrl: "../assets/icons/Explore-countries/uae_explore.svg",
      navLink: "en/connect/software-services/united-arab-emirates",
      active: false,
      startedOparations: true,
    },
    {
      name: "Philippine's",
      id: "phillipine-item",
      imgUrl: "../assets/icons/Explore-countries/philippines_explore.svg",
      navLink: "en/connect/software-services/philippines",
      active: false,
      startedOparations: true,
    },
    {
      name: "United Kingdom",
      id: "uk-item",
      imgUrl: "../assets/icons/Explore-countries/uk_explore.svg",
      navLink: "en/connect/software-services/united-kingdom",
      active: true,
      startedOparations: false,
    },
    {
      name: "Australia",
      id: "australia-item",
      imgUrl: "../assets/icons/Explore-countries/australia_explore.svg",
      navLink: "en/connect/software-services/australia",
      active: true,
      startedOparations: false,
    },
    {
      name: "Germany",
      id: "germany-item",
      imgUrl: "../assets/icons/Explore-countries/germany_explore.svg",
      navLink: "en/connect/software-services/germany",
      active: true,
      startedOparations: false,
    },
    {
      name: "Norway",
      id: "norway-item",
      imgUrl: "../assets/icons/Explore-countries/norway_explore.svg",
      navLink: "en/connect/software-services/norway",
      active: true,
      startedOparations: false,
    },
  ];
  world = {
    name: "Global Software Services Overview",
    id: "world-item",
    imgUrl: "../assets/icons/Explore-countries/global_explore.svg",
    navLink: "en/connect/software-services/global-overview",
    active: true,
    startedOparations: true,
  };

  ngOnInit(): void {
  }

  selectCountry(country) {
    const newRouterLink = country.navLink;
    this.router.navigate([newRouterLink]);
    this.drawerClose.emit();
    this.sharedService.sendValue(false);
    this.sharedService.setShowCountries(false);
  }


  closeMatDrawer(){
    this.drawerClose.emit();
    this.sharedService.sendValue(false);
    this.sharedService.setShowCountries(false);
    $("body").toggleClass("open");
  }

}
