import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(private http: HttpClient) {
  }

  /**
   * 
   * @param url 
   * @param object 
   * @returns 
   */
  public globalGetMethod(url: string, object: object, options?: object){
    const obj = JSON.parse(JSON.stringify(object));
    const keysArray = Object.keys(obj);
    let mainUrl: string = environment.API_URL + url;
    let loopCount = 0;
    for (const key of keysArray) {
      if (obj[key] != null || obj[key] != undefined) {
        loopCount++;
        if (loopCount === 1) {
          mainUrl = mainUrl + '?' + key + '=' + obj[key];
        } else {
          mainUrl = mainUrl + '&' + key + '=' + obj[key];
        }
      }

    }
    if(options){
      return this.http.get(mainUrl, options);
    }else{
    return this.http.get(mainUrl);
    }
  }

  /**
   * 
   * @param url 
   * @returns 
   */
  public globalGetMethodWithoutParams(url: string) {
    const mainUrl: string = environment.WEBAPP_URL + url;
    return this.http.get(mainUrl);
  }

  /**
   * 
   * @param url 
   * @param object 
   * @returns 
   */
  public globalPostMethod(url: string, object: object) {
    const mainUrl: string = environment.WEBAPP_URL + url;
    return this.http.post(mainUrl, object);
  }

  /**
   * 
   * @param url 
   * @param object 
   * @returns 
   */
  public globalPutMethod(url: string, object: object) {
    const mainUrl: string = environment.WEBAPP_URL + url;
    return this.http.put(mainUrl, object);
  }

  /**
   * 
   * @param url 
   * @param params 
   * @param object 
   * @returns 
   */
  public globalPutMethodWithParams(url: string, params: object, object: object) {
    const obj = JSON.parse(JSON.stringify(params));
    const keysArray = Object.keys(obj);
    let mainUrl: string = environment.WEBAPP_URL + url;
    let loopCount = 0;
    for (const key of keysArray) {
      if (obj[key]) {
        loopCount++;
        if (loopCount === 1) {
          mainUrl = mainUrl + '?' + key + '=' + obj[key];
        } else {
          mainUrl = mainUrl + '&' + key + '=' + obj[key];
        }
      }
    }
    return this.http.put(mainUrl, object);
  }

  /**
   * 
   * @param url 
   * @param object 
   * @returns 
   */
  public globalDeleteMethod(url: string, object: object) {
    const obj = JSON.parse(JSON.stringify(object));
    const keysArray = Object.keys(obj);
    let mainUrl: string = environment.WEBAPP_URL + url;
    let loopCount = 0;
    for (const key of keysArray) {
      loopCount++;
      if (loopCount === 1) {
        mainUrl = mainUrl + '?' + key + '=' + obj[key];
      } else {
        mainUrl = mainUrl + '&' + key + '=' + obj[key];
      }
    }
    return this.http.delete(mainUrl);
  }


  /**
   * 
   * @param url 
   * @param object 
   * @returns 
   */
  public globalGeoLocationGetMethod(url: string, object: object) {
    const obj = JSON.parse(JSON.stringify(object));
    const keysArray = Object.keys(obj);
    let mainUrl: string = environment.LOCATION_URL + url;
    let loopCount = 0;
    for (const key of keysArray) {
      loopCount++;
      if (loopCount === 1) {
        mainUrl = mainUrl + '?' + key + '=' + obj[key];
      } else {
        mainUrl = mainUrl + '&' + key + '=' + obj[key];
      }
    }
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + environment.DATA_CENTER_TOKEN);
    return this.http.get(mainUrl, { headers });
  }



    /**
   * 
   * @param url 
   * @returns 
   */
    public saveSubscribe(url: string,data:any) {
      const mainUrl: string = environment.WEBAPP_URL + url;
      return this.http.post(mainUrl,data);
    }
}
