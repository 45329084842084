import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from '../core/services/common.service';
import { SharedService } from '../core/services/shared.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-skill-groups',
  templateUrl: './skill-groups.component.html',
  styleUrls: ['./skill-groups.component.scss'],
})
export class SkillGroupsComponent implements OnInit {
  skillGroups: any[] = [];
  copySkillGroups: any[] = [];
  selectedGroup = {};
  categoryGroups: any[] = [];
  isSelectedGroup: boolean = false;
  selectedGroupName: string = '';
  searchTerm: string = '';
  selectedGroupskillsCount: number;
  isActiveGroup: boolean = false;
  selectedSkills: any[] = [];

  @Output() hireContractor = new EventEmitter<any>();
  @Output() drawerClose = new EventEmitter<any>();

  skillGroupPath:any;
  primaryItems: any[]=[];
  secondaryItems: any[]=[];
  mandatorySkills: any;
  searchPrimarySkills: string;
  searchableSkills: any=[];
  searchSkills: boolean = false;
  showDropDown: boolean = false;
  searchsecondarySkills: string;
  showSecondaryDropDown: boolean = false;
  clicked: boolean;
  searchSecondarySkillsNoData: boolean =  false;
  secondaryDragDropSkills: any[];
  primaryDragDropSkills: any[];
  leftDragSkills: boolean = true;
  loader = false;
  @Input() hideCss = false;
  skillGroupIdList = [];
  @Input() activedProject = 1;
  selectedSkillGroups = [];

  constructor(
    private commonService: CommonService,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.skillGroupPath = environment.imgpathUrl;
    this.getAllSkillGroups();
  }
  

  close(type = 2) {
    this.searchTerm = '';
    this.serachGroup();
    this.isSelectedGroup = false;
    this.sharedService.sendValue(false);
    if(type == 1) {
      this.drawerClose.emit(type);
    }
  }

  public getAllSkillGroups() {
    this.loader = true;
    this.commonService.getAllSkillGroups(true).subscribe((res: any) => {
      this.skillGroups = res.message;
      this.copySkillGroups = this.skillGroups;
      this.loader = false;
      if(this.commonService.getItem('selectedSkillGroupId')){
        this.findSelectedSkillGroup();
      }
    });
  }

  serachGroup() {
    if (this.searchTerm && this.searchTerm.length >= 2) {
      this.copySkillGroups = this.skillGroups.filter((group) =>
        group?.name?.toLowerCase().includes(this.searchTerm?.toLowerCase())
      );
    } else if(!this.searchTerm.length){
      this.copySkillGroups = this.skillGroups;
    }
  }

  // find Selected skill group
  findSelectedSkillGroup(){
    let selectedSkillGroup:any = this.commonService.getItem('selectedSkillGroupId');
    let selectedGroup = this.skillGroups.find((group) => group.id === selectedSkillGroup);
    if(selectedGroup){
    this.selectGroup(selectedGroup);
    }
    this.saveAndNext();
    this.commonService.removeItem('selectedSkillGroupId');
  }

  selectGroup(group: any) {
    this.selectedGroup = group;
    this.selectedGroupName = group?.name;
    this.categoryGroups = this.groupBy(group?.skills, 'categoryId');
  }

  groupBy(array: any[], key: string) {
    const groupedArray = array.reduce((resuilt, obj) => {
      let category = resuilt[obj[key]];
      if (!category) {
        category = {
          categoryId: obj.categoryId,
          categoryName: obj.categoryName,
          skills: [],
        };
        resuilt[obj[key]] = category;
      }
      if(!obj.mandatorySkill){
      category.skills.push(obj);
      }
      return resuilt;
    }, 
    {});
   // Create a new array to hold the mandatory skills
    const mandatorySkills = array.filter(skill => {
    if (skill.mandatorySkill) {
    return true;
    }
    return false;
    });
    this.primaryItems = mandatorySkills;
    this.secondaryItems = [];
    // Remove the mandatory skills from the original array
    const nonMandatorySkills = array.filter(skill => {
    if (!skill.mandatorySkill) {
    return true;
    }
     return false;
    });
    //this.searchableSkills = nonMandatorySkills;
    this.searchableSkills = array;
    return Object.values(groupedArray);
  }

  saveAndNext() {
    this.leftDragSkills = true;
    if (Object.keys(this.selectedGroup).length == 0) return;
    this.isSelectedGroup = true;
    this.selectedGroupskillsCount = this.isMandatorySkill(this.categoryGroups);
  }

  changeSelected(skill: any) {
    this.selectedGroupskillsCount = this.isMandatorySkill(this.categoryGroups);
    this.getSelectedSkill(this.categoryGroups);
  }

  isMandatorySkill(data: any[]) {
    var counter = 0;
    for (var i = 0; i < data.length; i++) {
      for (var index = 0; index < data[i].skills.length; index++) {
        if (data[i].skills[index].mandatorySkill === true) {
          counter++;
        }
      }
    }
    return counter;
  }

  getSelectedSkill(data: any[]) {
    let selectedSkills: any[] = [];
    for (var i = 0; i < data.length; i++) {
      for (var index = 0; index < data[i].skills.length; index++) {
        if (data[i].skills[index].mandatorySkill === true) {
          let obj = data[i].skills[index];
          delete obj.categoryId;
          delete obj.categoryName;
          selectedSkills.push(obj);
        }
      }
    }
    return selectedSkills;
  }

  public onSkillDragStart(skill){
    if(skill){
    this.leftDragSkills = true;
    }
  }

  public onDrop(event: CdkDragDrop<any[]>, section: string,previousContainerData) {
    
    const selectedItem = event.item.data;
    if (section === "primary") {
      if (this.primaryItems == undefined) {
        this.primaryItems = [];
      }
      this.primaryItems.push(selectedItem);
      const indexToRemove = this.secondaryItems.findIndex(item => item.skillName === selectedItem.skillName);

      if (indexToRemove !== -1) {
      this.secondaryItems = this.secondaryItems.filter(item => item.skillName !== selectedItem.skillName);
      this.leftDragSkills = false;
      }
    } else {
      if (this.secondaryItems == undefined) {
        this.secondaryItems = [];
      }
      this.secondaryItems.push(selectedItem);
      const indexToRemove = this.primaryItems.findIndex(item => item.skillName === selectedItem.skillName);

      if (indexToRemove !== -1) {
      this.primaryItems = this.primaryItems.filter(item => item.skillName !== selectedItem.skillName);
      this.leftDragSkills = false;
      }
    }

    if(this.leftDragSkills){
      let objectIndex = -1;
      let found = false;
      previousContainerData.forEach((obj, i) => {
        const index = obj.skills.findIndex(skill => event.previousIndex-- === 0);
        if (index >= 0) {
          objectIndex = i;
          found = true;
          obj.skills.splice(index, 1);
        }
        return !found; 
      });
    }
  }

  public removeFromSection(item: any, section: string) {
    if (section === "primary") {
      if (item.categoryName) {
        this.categoryGroups.forEach(obj => {
          if (obj.categoryName === item.categoryName) {
            obj.skills.push(item);
          }
        });
      }
      this.primaryItems = this.primaryItems.filter(i => i !== item);
     } 
    else {
      if (item.categoryName) {
        this.categoryGroups.forEach(obj => {
          if (obj.categoryName === item.categoryName) {
            obj.skills.push(item);
          }
        });
      }
      this.secondaryItems = this.secondaryItems.filter(i => i !== item);
    }
  }

  
  nextToHire() {
    this.selectedSkills = this.getSelectedSkill(this.categoryGroups);
    this.selectedSkills.forEach((skill:any, index) =>{
      skill.skillLevelId = 2;
      skill.skillOrder = index +1;
      return skill;
    })
    if (this.primaryItems) {
      const skillsPrimary = this.primaryItems.map(obj => {
        return { ...obj, skillLevelId: 4 , skillPreferenceId:1 };
      });
      this.primaryDragDropSkills = skillsPrimary;
    }
    if (this.secondaryItems) {
      const skillsSecondary = this.secondaryItems.map(obj => {
        return { ...obj, skillLevelId: 2 , skillPreferenceId:2};
      });
      this.secondaryDragDropSkills = skillsSecondary;
    }
    const obj = {
      skillGroupId: this.selectedGroup['id'],
      primarySkills: this.primaryDragDropSkills,
      secondarySkills : this.secondaryDragDropSkills
    };
    if(this.selectedSkillGroups && this.selectedSkillGroups.length > 0) {
      obj['skillGroupIdList'] = [];
      this.selectedSkillGroups.forEach(element => {
        obj['skillGroupIdList'].push(element.id)
      });
    }
    
    this.hireContractor.emit(obj);
  }

  filteredSkills = this.searchableSkills;
  filterSkills() {
    const searchQuery = this.searchPrimarySkills.toLowerCase();
    if (this.searchPrimarySkills === null) {
      this.filteredSkills = null;
    } else {
    this.filteredSkills = this.searchableSkills.filter(skill =>
      skill.skillName.toLowerCase().includes(searchQuery)
    );
    }
    if(this.filteredSkills.length != 0 && this.searchPrimarySkills){
    this.showDropDown = true;
    } else {
      this.showDropDown = false;
      this.searchSkills = true;
    }
    if(searchQuery == '' || (searchQuery != '' && this.filteredSkills.length != 0) ){
      this.searchSkills = false;
    }
  }

  filteredSecondarySkills = this.searchableSkills;
  filterSecondarySkills() {
    const searchQuery = this.searchsecondarySkills.toLowerCase();
    if (this.searchsecondarySkills === null) {
      this.filteredSecondarySkills = null;
    } else {
    this.filteredSecondarySkills = this.searchableSkills.filter(skill =>
      skill.skillName.toLowerCase().includes(searchQuery)
    );
    }
    if(this.filteredSecondarySkills.length != 0 && this.searchsecondarySkills){
    this.showSecondaryDropDown = true;
    } else {
      this.showSecondaryDropDown = false;
      this.searchSecondarySkillsNoData = true;
    }
    if(searchQuery == '' || (searchQuery != '' && this.filteredSecondarySkills.length != 0)){
      this.searchSecondarySkillsNoData = false;
    }
  }

  public onskillsObj1(skillObj) {
    this.searchPrimarySkills = '';
    this.showDropDown = false;
    if (this.primaryItems == undefined) {
      this.primaryItems = [];
    }
    const primaryItemIndex = this.primaryItems.findIndex(obj => obj.skillName.toLowerCase() === skillObj.skillName.toLowerCase());
    const index2 = this.secondaryItems.findIndex(obj => obj.skillId === skillObj.skillId);
  
    if (primaryItemIndex === -1 && index2 === -1) {
      const matchingCategoryIndex = this.categoryGroups.findIndex(obj => obj.categoryName.toLowerCase() === skillObj.categoryName.toLowerCase());
      if (matchingCategoryIndex !== -1) {
        const matchingSkillIndex = this.categoryGroups[matchingCategoryIndex].skills.findIndex(obj => obj.skillName.toLowerCase() === skillObj.skillName.toLowerCase());
        if (matchingSkillIndex !== -1) {
          this.categoryGroups[matchingCategoryIndex].skills.splice(matchingSkillIndex, 1);
        }
      }
      this.primaryItems.push(skillObj);
    }
  }

  public onskillsObj2(skillObj) {
    this.searchsecondarySkills = '';
    this.showSecondaryDropDown = false;
    if (this.secondaryItems == undefined) {
      this.secondaryItems = [];
    }
    const index1 = this.primaryItems.findIndex(obj => obj.skillId === skillObj.skillId);
    const secondaryItemIndex = this.secondaryItems.findIndex(obj => obj.skillName.toLowerCase() === skillObj.skillName.toLowerCase());
    if (index1 === -1 && secondaryItemIndex === -1) {
      const matchingCategoryIndex = this.categoryGroups.findIndex(obj => obj.categoryName.toLowerCase() === skillObj.categoryName.toLowerCase());
      if (matchingCategoryIndex !== -1) {
        const matchingSkillIndex = this.categoryGroups[matchingCategoryIndex].skills.findIndex(obj => obj.skillName.toLowerCase() === skillObj.skillName.toLowerCase());
        if (matchingSkillIndex !== -1) {
          this.categoryGroups[matchingCategoryIndex].skills.splice(matchingSkillIndex, 1);
        }
      }
      this.secondaryItems.push(skillObj);
    }
  }

  selectSkillGroups(item) {
    const index = this.selectedSkillGroups.indexOf(item);
    if (index > -1) {
      this.selectedSkillGroups.splice(index, 1);
    } else {
      this.selectedSkillGroups.push(item);
    }
    if(this.selectedSkillGroups && this.selectedSkillGroups.length > 0) {
      this.selectedGroup = this.selectedSkillGroups[0];
      this.selectedGroupName = this.selectedSkillGroups[0]?.name;
    }
  }

  saveAndNext2() {
    let skills = []
    this.leftDragSkills = true;
    this.isSelectedGroup = true;
    this.primaryItems = [];
    this.selectedSkillGroups.forEach(element => {
      if(element.skills && element.skills.length > 0) {
        element.skills.forEach(skill => {
          skills.push(skill)
        });
      }
    });
    const uniqueItems = skills.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.skillId === item.skillId
      ))
    );
    let i= 0;
    uniqueItems.forEach(element => {
      if(element.mandatorySkill && i < 20) {
        this.primaryItems.push(element)
        i++;
      }
    });
    this.categoryGroups = this.groupBy(uniqueItems, 'categoryId');
    this.selectedGroupskillsCount = this.primaryItems.length;
  }

  isSelected(skill): boolean {
    if(this.selectedSkillGroups && this.selectedSkillGroups.length > 0) {
      return this.selectedSkillGroups.includes(skill);
    }
  }

}
