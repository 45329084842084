import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Inject, Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SchemasService {

  websiteUrl = environment.WEBSITE_URL;

  constructor( @Inject(DOCUMENT) private document: Document ,private router : Router){
    //remove any existing script
    this.removeScript()
    this.loadSchemaOnRouter();
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
         this.removeScript()
         this.loadSchemaOnRouter();
      };
    })
  }
  
public amySchema = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Aumnics inc",
  "alternateName": "Amy.pro, amy, workplace assistant",
  "url": "${environment.WEBSITE_URL}en",
  "logo": "${environment.WEBSITE_URL}assets/images/home/Amy-logo-white.png",
  "Founded": "2021-06-14",
  "founder": {
    "@type": "Person",
    "name": "Kiran Teegala",
    "url": "${environment.WEBSITE_URL}en",
    "image": "https://media.licdn.com/dms/image/C5103AQFd6GyXBuroKQ/profile-displayphoto-shrink_800_800/0/1561427044215?e=1718236800&v=beta&t=rafL7JdXgpGn-Pzf9HLVj31JJLWKFi6bWDmqy7X5ObA",
    "sameAs": "https://www.linkedin.com/in/kiranteegala/",
    "jobTitle": "Founder",
    "worksFor": {
      "@type": "Organization",
      "name": "Aumnics Inc"
    }
  },

  "description": "Aumnics Inc is a leading provider of innovative solutions in the field of corp-to-corp hiring. Our platform, Amyconnect, serves as a comprehensive marketplace connecting businesses with skilled professionals for contract assignments. With a focus on efficiency and reliability, we facilitate seamless hiring processes and foster mutually beneficial relationships between employers and talents.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Aumnics Inc. 16192 Coastal Highway",
    "addressLocality": "Lewes",
    "addressRegion": "DE",
    "postalCode": "19958",
    "addressCountry": "United States"
  },
  "telephone": "+1 (201) 984-1339",
  "email": "info@amy.pro",
  "sameAs": [
    "https://www.facebook.com/Amyproofficial/",
    "https://twitter.com/amy_profficials",
    "https://www.instagram.com/amy_pro_official/",
    "https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ",
    "https://www.linkedin.com/company/aumnicsinc/",
    "https://medium.com/@aumnicsai"
  ]

  }

`;

public amyConnectSchema = `
{
  "@context": "https://schema.org/",
  "@type": "Website",
  "name": "Amy.pro",
  "url": "${environment.WEBSITE_URL}en/connect"
}
`;
public amyConnectOraganisationSchema = `
{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Aumnics inc",
    "alternateName": "Amy connect, amy, saas marketplace, C2C marketplace",
    "url": "${environment.WEBSITE_URL}en",
    "logo": "${environment.WEBSITE_URL}assets/images/home/Amy-logo-white.png",
    "Founded": "2021-06-14",
    "founder": {
      "@type": "Person",
      "name": "Kiran Teegala",
      "url": "${environment.WEBSITE_URL}en",
      "image": "https://media.licdn.com/dms/image/C5103AQFd6GyXBuroKQ/profile-displayphoto-shrink_800_800/0/1561427044215?e=1718236800&v=beta&t=rafL7JdXgpGn-Pzf9HLVj31JJLWKFi6bWDmqy7X5ObA",
      "sameAs": "https://www.linkedin.com/in/kiranteegala/",
      "jobTitle": "Founder",
      "worksFor": {
        "@type": "Organization",
        "name": "Aumnics Inc"
      }
    },
  
    "description": "Aumnics Inc is a leading provider of innovative solutions in the field of corp-to-corp hiring. Our platform, Amyconnect, serves as a comprehensive marketplace connecting businesses with skilled professionals for contract assignments. With a focus on efficiency and reliability, we facilitate seamless hiring processes and foster mutually beneficial relationships between employers and talents.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Aumnics Inc. 16192 Coastal Highway",
      "addressLocality": "Lewes",
      "addressRegion": "DE",
      "postalCode": "19958",
      "addressCountry": "United States"
    },
    "telephone": "+1 (201) 984-1339",
    "email": "info@amy.pro",
    "sameAs": [
      "https://www.facebook.com/Amyproofficial/",
      "https://twitter.com/amy_profficials",
      "https://www.instagram.com/amy_pro_official/",
      "https://www.youtube.com/channel/UC3H2RaCut8grc3oBHzAdnjQ",
      "https://www.linkedin.com/company/aumnicsinc/",
      "https://medium.com/@aumnicsai"
    ]
  
  }
  `;

  public hireFromAgenciesSchema = `
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "${environment.WEBSITE_URL}en/connect"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Hire from Agencies",
      "item": "${environment.WEBSITE_URL}en/connect/hire-from-agencies"  
    }]
  }
  `;

  public hireFromAgenciesFaqsSchema = `
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "name": "FAQs for Hiring from Agencies on Amy connect",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How does hiring from agencies work on Amy connect?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>On Amy Connect, hiring corp-to-corp contract talent from software agencies involves agencies publishing their employees as consultants for other companies to hire them for corp-to-corp contract assignments. You can browse through agency talent profiles, review candidate profiles, and schedule and interview before you try them for a week.</p>"
        }
      },
      {
        "@type": "Question",
        "name": "What benefits do hiring from agencies offer on Amy connect?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Hiring from agencies on Amy connect offers several benefits, including access to verified agencies and thoroughly pre-vetted talent, standard agreements, and standard payment terms to eliminate any delays in negotiations and make hiring happen in minutes as opposed to a couple of weeks. Amy Connect does not allow freelancers or individuals on the platform; only agencies can provide their employees as consultants. Amy connect is exclusively built for corp-to-corp contract assignments.</p>"
        }
      },
      {
        "@type": "Question",
        "name": "Can I trust the quality of talent provided by agencies on Amy connect??",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Absolutely as every agency on amy connect is verified and every engineer thoroughly vetted. The best part is you can try for a week before you hire any resource eliminating any risk involved. With Amy connect you can hire corp-to-corp contract talent with peace of mind</p>"
        }
      },
      {
        "@type": "Question",
        "name": "How does the billing process work when hiring from agencies on Amy connect?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>There are standard payment terms common for all organizations. Payment terms are as follows: a. First 80 hours of work is paid in advance by the client b. The second 80 hours + or - actual hours worked in the first 15 days is adjusted and paid. C. Third payment will be rounding off the month, for example, if you hired a resource on the 14th of March, This is also an advance payment adjusted to make the billing date the first of every month. D. The fourth payment will be days which means the payment will be shifting to a postpaid model for example: if the consultant works the whole month of March, an invoice is raised on April 1st and the payment should be made before April 7th. E. Fifth payment till the consultant is on roles will be T+30days which means for example: if the consultant worked for the month of March, the invoice will be raised on April 1st but the payment will be cleared by April 30th. T+30 days will continue till either the service provider or the hiring manager cancels the contract. F. When the contract is cancelled, one week's notice is given to the other party and all the payment should be cleared at this point along with one week's notice period 40 hours in advance. After the week the consultant loses the access to workspace. These standard payment terms make everything transparent and eliminate the need for negotiation between the parties standardizing the payments and billing cycles.</p>"
        }
      }
    ]
  }
  `;

  public hireFromSkillsSchema = `
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "${environment.WEBSITE_URL}en/connect"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Browse Contractors by Skills ",
      "item": "${environment.WEBSITE_URL}en/connect/hire-contractors-by-skills"  
    }]
  }
  `;

  public hireFromSkillsFaqsSchema = `
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "name": "FAQs",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What types of skills do Amy connect contractors possess?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Amy connect has contractors including but not limited to:</p><ul><li>Developers</li><li>Mobile App Development (iOS, Android)</li><li>UI/UX Designer</li><li>Quality Assurance (QA)</li><li>Data Science and Analytics</li><li>Project Management</li><li>Scrum masters</li></ul><p>Amy connect has engineering talent in all software technologies with various verticals, ensuring that we can match you with the right talent for your project needs.</p>"
        }
      },
      {
        "@type": "Question",
        "name": "How do Amy connect to ensure the quality of contractors?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>At Amy connect, every agency is verified and every contractor is thoroughly vetted by our global network of interviewers that is rigorous to ensure that we only onboard top talent. This includes evaluating their technical skills, industry experience, and communication abilities. Additionally, we continuously monitor and evaluate contractor performance based on client feedback and project outcomes to maintain high standards of quality.</p>"
        }
      },
      {
        "@type": "Question",
        "name": "Can I request contractors with specific skills?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Yes, absolutely! We understand that every project is unique and may require specific skills. You can specify your skill requirements when submitting your project details, and we will match you with contractors who possess the skills you need. Whether you're looking for expertise in a particular programming language, framework, or technology stack, we've got you covered. We divide the expertise into 4 parts to make it simple Beginner (2 to 4 years ) of working experience), Intermediate talent( 4 to 8 years), expert talent(8 to 12 years) and top talent( 12+ years) </p>"
        }
      },
      {
        "@type": "Question",
        "name": "What if I'm not satisfied with the contractor's work?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>Your satisfaction is our top priority. We provide 1 week trial period for every developer you hire and if you are not happy within a week you can hire another resource and you will not be charged for that week saving you money. After one week If you're not satisfied with the contractor's work for any reason, you can cancel the contract at any time with one week's notice. You can also reach out our support team immediately. We will work with you to address any concerns and, if necessary, find a replacement contractor who better meets your needs. Our goal is to ensure that you receive high-quality results and a positive experience throughout your project.</p>"
        }
      }
    ]
  }  
  `;

  public discountPackagesSchema = `
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "${environment.WEBSITE_URL}en/connect"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Discounted Packages",
      "item": "${environment.WEBSITE_URL}en/connect/discounted-packages  
    }]
  }
  `;

  public discountPackagesFaqSchema = `
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "name": "FAQs about Discounted Packages on Amy Connect",
    "mainEntity": [
      {
    "@type": "Question",
        "name": "What is Amy connect Discounted Packages",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Regular requirements like WordPress website or e-commerce store or salesforce implementations or zen desk implementation or search module using the elastic search or solr  or sentiment analysis of amazon reviews and many more generic requirements we identified and listed them all at a discounted rate so customers can take advantage of our discounted packages and save in some cases up to 60% on software development so they can use this money for marketing and customer acquisition"
        }
      },
      {
        "@type": "Question",
        "name": "What types of discounted packages are available on Amy to Connect?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Amy connect offers hundreds of discounted packages on WordPress website development to shopify e-commerce store to building a CRM or salesforce or zendesk implementation, etc anything that is not only specific to your business is all covered on amy connect discounted packages. Please visit https://connect.amy.pro to browse all our discounted packages. 
        }
      },
      {
        "@type": "Question",
        "name": "Can I customize the discounted packages according to my specific requirements?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, absolutely! Amy Connect's discounted packages are flexible and can be tailored to meet your unique needs. Whether you require additional features, specific design elements, or customization options, all you need to do is click on customize this package button available in every package and specify your needs it goes as a requirement to subscribed agencies and they will send the quotes and you can choose the best agency. Also, our team is here to assist you in creating a package that fits your requirements perfectly."
        }
      },
      {
        "@type": "Question",
        "name": "How do I purchase a discounted package on Amy Connect?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Availing the discounted packages on Amy Connect is simple. Visit our website and navigate to the discounted packages section. Choose the package that best suits your needs and follow the prompts to complete your purchase. Our team will then assist you in getting started with your discounted package and creating your ideal website solution."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any limitations or restrictions with the discounted packages?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "While our discounted packages offer exceptional value, there may be certain limitations or restrictions depending on the specific package you choose. We recommend reviewing the details of each package carefully to ensure it meets your requirements. If you have any questions or concerns, our team is available to provide clarification and assistance."
        }
      }
    ]
  }
  `;


  loadSchemaOnRouter() {
    if(this.router.url == '/en'){
        this.loadScript(this.amySchema);
     } else if(this.router.url == '/en/connect'){
        this.loadScript(this.amyConnectSchema);
        this.loadScript(this.amyConnectOraganisationSchema);
     } else if(this.router.url == '/en/connect/hire-from-agencies'){
        this.loadScript(this.hireFromAgenciesSchema);
        this.loadScript(this.hireFromAgenciesFaqsSchema);
     } else if(this.router.url == '/en/connect/hire-contractors-by-skills'){
        this.loadScript(this.hireFromSkillsSchema);
        this.loadScript(this.hireFromSkillsFaqsSchema);
     } else if(this.router.url == '/en/connect/discounted-packages'){
        this.loadScript(this.discountPackagesSchema);
        this.loadScript(this.discountPackagesFaqSchema);
     }
    }


  public loadScript(schema) {
    const script = this.document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = schema;
    this.document.head.appendChild(script);
  }

  public removeScript() {
    const scripts = this.document.querySelectorAll('script[type="application/ld+json"]');
    scripts.forEach(script => {
      script.remove();
    });
  }
  
}



