import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { CommonService } from '../core/services/common.service';

declare const $: any;
declare const jQuery: any;
declare const grecaptcha: any;

@Component({
  selector: 'app-subscribe-now',
  templateUrl: './subscribe-now.component.html',
  styleUrls: ['./subscribe-now.component.scss']
})
export class SubscribeNowComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SubscribeNowComponent>,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  subscribeForm: FormGroup;
  public env = environment;
  subscribeImgPath: any;

  ngOnInit(): void {
    this.subscribeImgPath = environment.imgpathUrl;
    this.subscribeForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.subscribeForm.controls;
  }
  subscribeNow() {
    const useName = this.subscribeForm.get('userName').value;
    const isCaptchaVerified = this.validateCaptcha();
    if (this.subscribeForm.valid && isCaptchaVerified) {
      this.dialogRef.close(useName);
    } else {
      this.subscribeForm.get('userName').markAsTouched();
    }
  }

  public validateCaptcha() {
    $('#recaptchaError').addClass('d-none');
    if (!grecaptcha.getResponse()) {
      $('#recaptchaError').removeClass('d-none');
    }
    return grecaptcha.getResponse() ? true : false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
