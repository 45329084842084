import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { AppComponent } from "../../app.component";
import { ServerStorage } from "./server-storage";
import { Routes } from "../enums/routes.enum";
import { AppConstants } from "../enums/app-constants.enum";
import { cloneDeep } from 'lodash';
import { EndPoint } from "../enums/end-point.enum";
declare var tippy: any;
declare var $:any;

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {return undefined;}
  key(index: number): string | null {return undefined;}
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

declare var AktaAccount: any;
@Injectable({
  providedIn: 'root'
})
export class StorageService implements Storage {
  
  private storage: Storage;
  private passedObject : any;
  public allColourCodes : any;
  public selectedModalSubTask: any;
  
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = new ServerStorage();
    }
  }

  isBrowser(): boolean{
    return this.platformId == 'browser';
  }

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.storage.clear();
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  setItem(key: string, value: any, storageType = StorageType.LOCAL_STORAGE): void {
    if(value!=null && this.isJsonObject(value)){
      value  = JSON.parse(value);
    }
    if (storageType === StorageType.LOCAL_STORAGE) {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(key,value) ;
      }
    } else if (storageType === StorageType.SESSION_STORAGE) {
      sessionStorage.setItem(key, value);
    } 
  }

  getItem(key: string, storageType = StorageType.LOCAL_STORAGE): any {
    if (storageType === StorageType.LOCAL_STORAGE) {
      if (isPlatformBrowser(this.platformId)) {
        let value = localStorage.getItem(key);
        if(value!=null && this.isJsonObject(value)){
          value = JSON.parse(value);
        }
        return value !== null ? value: null;
      }
    } else if (storageType === StorageType.SESSION_STORAGE) {
      let value = sessionStorage.getItem(key);
      if(value!=null && this.isJsonObject(value)){
        value = JSON.parse(value);
      }
      return value !== null ? value: null;

    }
  }

  removeItem(key: string, storageType = StorageType.LOCAL_STORAGE): void {
    if (storageType === StorageType.LOCAL_STORAGE) {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem(key);
      }
    } else if (storageType === StorageType.SESSION_STORAGE) {
      sessionStorage.removeItem(key);
    }
  }

  public getConstantRandomColorsForTags(shortName): string {
    return this.allColourCodes?.[shortName] || '#e52900';
  }


  public getConstantRandomColors(shortName): string {
    return this.allColourCodes?.[shortName] || '#e52900';
  }

  public getShortName(name) {
    if (name != null && name.trim().length>0) {
      let shortName = name.match(/\b(\w)/g).join('');
      shortName = shortName.substring(0, 2).toUpperCase();
      if (shortName.length < 2) {
        shortName = name.substring(0, 2).toUpperCase();
      }
      return shortName;
    } else {
      return 'au';
    }
  }
  
  generateUniqueId(): string {
    const storedVisitorSessionId = this.getItem('visitorSessionId');
    if (storedVisitorSessionId) {
      return storedVisitorSessionId;
    } else {
      const timestamp = Date.now().toString(36);
      const random = Math.random().toString(36).substring(2, 8);
      const visitorSessionId = timestamp + random;
      this.setItem('visitorSessionId', visitorSessionId);
      return visitorSessionId;
    }
  }


    getUserAndSessionIds(result: any){
      let fingerprint = result.visitorId;
      let visitorSessionId = this.generateUniqueId();
      // Use the fingerprint to identify the user
      const browserVersion = ''+navigator.userAgent;
      const storedBrowserVersion = this.getItem('browserVersion');
      if (browserVersion !== storedBrowserVersion) {
        // Browser version has changed, update the visitor ID
        this.setItem('browserVersion', browserVersion);
        this.setItem('visitorId', fingerprint);
        this.setItem('visitorSessionId', visitorSessionId);
        this.setItem('visitorSessionId', visitorSessionId);
      }else{
        this.setItem('visitorSessionId', visitorSessionId);
        this.setItem('visitorSessionId', visitorSessionId);
      }
    }

    isJsonObject(value: any) {
      try {
        JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    }

    clearSessionId(){
      this.removeItem('visitorSessionId');
    }

  // cloning deep using the lodash
  public cloneDeep(obj: any): any {
    return cloneDeep(obj)
  }


  // For Storing Values Into Local Storage
  public storeObject(key: any, value: any): boolean {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    return true;
  }

  // For Updating Values In Local Storage
  public storeAFieldInObject(localKey: any, key: any, value: any): boolean {
    if (isPlatformBrowser(this.platformId)) {
      this.passedObject = JSON.parse(localStorage.getItem(localKey));
      this.passedObject[key] = value;
      localStorage.setItem(localKey, JSON.stringify(this.passedObject));
    }
    return true;
  }

  // For Getting Values From The Local Storage
  public getObject(localKey: any): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(localKey)) {
        return JSON.parse(localStorage.getItem(localKey));
      } else {
        return null;
      }
    }
  }

  // For Getting A Particular Value From The Local Storage
  public getAFieldInObject(localKey: any, key: any): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(localKey)) {
        this.passedObject = JSON.parse(localStorage.getItem(localKey));
        if (this.passedObject[key]) {
          return this.passedObject[key];
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  // For Removing Values From The Local Storage
  public removeObject(localKey: any): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(localKey)) {
        localStorage.removeItem(localKey);
        return true;
      } else {
        return null;
      }
    }
  }

  // For Removing A Particular Value From The Local Storage
  public removeAFieldInObject(localKey: any, key: any): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(localKey)) {
        this.passedObject = JSON.parse(localStorage.getItem(localKey));
        if (this.passedObject[key]) {
          delete this.passedObject[key];
          this.storeObject(localKey, this.passedObject);
          return true;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  public getRoutes() {
    return Routes;
  }

  public getAppConstants() {
    return AppConstants;
  }

  public setColor(colorCode: string): string {
    if(!colorCode){
      return;
    }
    // Convert HEX color to RGB
    const rgb = this.hexToRgbColor(colorCode);
    
    // Calculate luminance to determine if the color is light or dark
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    
    // Return 'white' for dark colors and 'black' for light colors
    return luminance > 0.5 ? 'black' : 'white';
  }
  
  // Helper method to convert HEX color to RGB
  private hexToRgbColor(hex: string): { r: number; g: number; b: number } {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
  
    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
  
    return { r, g, b };
  }

  instance;
  /**
   * This method is used to set show status text and icon in the tool tip
   */

  //Manual tooltip method
  
  public showManualToolTip(toolTipMessage,position,width) {
    const cardElement = document.querySelector('.toolTipWidth') as HTMLElement;
    var cardWidth : any;
    if(width){
       cardWidth = width;
    } else {
     cardWidth = cardElement? cardElement.offsetWidth : '300';

    }
    const html = '<div class="col-12 p-0" style="width: ' + cardWidth + 'px;">' + toolTipMessage + '</div>';
    if (this.instance) {
      this.instance.forEach((e) => {
        e.destroy();
      });
    }
    this.instance = tippy('.toolTipWidth', {
      content(reference) {
        return html;
      },
      placement: position ? position: 'bottom',
      allowHTML: true,
      animation: 'fade'
    });
  }

  amyDateFilter = {
    datefilterId : null,
    startDate: null,
    endDate: null,
    selectedDisplayValue : null
  }

  getConstantPredefinedColorFromInput(input: string): string {
    const colors = ['#295bac', '#188a42', '#ae510f', '#b32b23', '#000000','#a006ae','#e52900'];
  
    // Ensure the input is a string and convert to lowercase
    const str = input.toString().toLowerCase();
  
    // Simple hash function to convert the input string to a number
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
    }
  
    // Get a positive number and map it to one of the color codes
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }

  public getTaskEmoji(react) {
    return this.getEmoji(react);
  }

   /**
 * This method is used to remove Colon's from the Emoji attribute of Quill Delta.
 */
   public getEmoji(react) {
    if (!(react?.emojiObj || react.emojiObj?.shortNames[0])) {
    this.getEmojiUrl(react)
    return; 
  }
    let skinTone= react.emojiObj.skinTone;
    let emojiURL = this.getUnifiedEmojiUrlBySkinTone(skinTone, react.emojiObj)
    return emojiURL;
  }

  public getEmojiUrl(emojiObj) {
    let emojiIcon = JSON.parse(emojiObj);
    if (!emojiIcon || !emojiIcon.unified) {
      return;
    }
    return this.getEmojiUnifiedUrl(emojiIcon.unified);
  }
  
  public getEmojiUnifiedUrl(unified) {
    // Assume you have a base URL for emoji images
    const emojiBaseUrl = 'https://twemoji.maxcdn.com/v/13.0.1/72x72/';
    return `${emojiBaseUrl}${unified.toLowerCase()}.png`;
  }

  public getUnifiedEmojiUrlBySkinTone(skinTone, emojiObj) {
    var unified = emojiObj.unified;
    if(!skinTone) skinTone = 2;
    if (skinTone < 1 || skinTone > 6) {
      throw new Error('Invalid skin tone number. It should be between 1 and 5.');
    }else if(emojiObj.skinVariations && emojiObj.skinVariations[skinTone-1]){
      unified = emojiObj.skinVariations[skinTone - 1].unified;
    }
    return this.getEmojiUrlByUnifiedCode(unified)
  }

  
  public getEmojiUrlByUnifiedCode(unified) {
    let url =  this.getEmojiUrlByUnifiedCodeAndUrl(EndPoint.EMOJIS_PATH , unified);
    return url;
  }

  public getEmojiUrlByUnifiedCodeAndUrl(emojibaseUrl, unified) {
    if(unified && emojibaseUrl){
      let url =  emojibaseUrl + unified.toLowerCase()+".png";
      return url;
    }
  }

  removeImageLoading(e) {
    $(e.target).parent().removeClass('img-loading');
  }
}

export enum StorageType {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage',
  COOKIE_STORAGE = 'cookieStorage'
}