import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { CommonService } from '../core/services/common.service';
import { SharedService } from '../core/services/shared.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare const $: any;
declare const jQuery: any;
declare const grecaptcha: any;

@Component({
  selector: 'app-hire-contractor',
  templateUrl: './hire-contractor.component.html',
  styleUrls: ['./hire-contractor.component.scss'],
  providers: [MessageService],
})
export class HireContractorComponent implements OnInit {
  locations: any[] = [];
  timeZones: any[] = [];
  leadUrgency: any[] = [];
  contractPositionTypes: any[] = [];
  isMaxExperienceMonth: boolean = false;
  isMaxExperienceMonthCopy: boolean = false;
  isSelectedDuration: number = 67;
  isSelectedWork: number = 1;
  isSelectedEmployerType: number = 1;
  @Input() selectedSkills: any[] = [];
  @Output() drawerClose = new EventEmitter<any>();
  projectContinuities: any[] = [];
  typesOfWork: any[] = [];
  listOfLifeCycleStages: any[] = [];
  filteredTimeZones: any[] = [];

  contractorForm: FormGroup;
  projectTypeForm:FormGroup;
  projectForm: FormGroup;
  tab=0;
  tabText = "Select Project type";
  projectTypeSelection = true;
  projectTypes = [
    {
      projectTypeId: 3,
      projectIcon: '../../assets/icons/project_accets/active_state – 2.svg',
      projectTitle: 'Hire contact talent',
      description1: 'Hire skilled professionals on a temporary basis for short- or long term-term assignments.'
    },
    {
      projectTypeId: 1,
      projectIcon: '../../assets/icons/project_accets/active_state.svg',
      projectTitle: 'Outsource your project (Find other MSPs)',
      description1: 'Access deep consulting experience or specialized project teams to solve business challenges'
    }
  ];
  @Input() activedProject:any;
  public env = environment;
  public minYears = [];
  public maxYears = [];
  public months = [];
  public selectedStep = 1;

  billingType = [
    { name: 'Per hour', id: 106 },
    { name: 'Per Month', id: 107 },
  ];

  durations = [
    { name: '1 - 3 Months', id: 66 },
    { name: '3 - 6 Months', id: 67 },
    { name: '6 - 12 Months', id: 68 },
    { name: 'More than a year', id: 69 },
    { name: 'For a month', id: 70 },
    { name: 'For a week', id: 71 },
    { name: 'I will decide later', id: 72 },
  ];

  typeOfWorks = [
    { id: 1, name: 'Can work remotely from anywhere' },
    { id: 2, name: 'Can work remotely from the United States' },
    { id: 3, name: 'Hybrid work' },
    { id: 4, name: 'Onsite (From our office)' },
  ];

  typeOfEmployer = [
    { id: 1, name: 'Direct Hire' },
    { id: 2, name: 'Recruiter' },
  ];

  aumnics = [
    { id: true, name: 'Yes, I have' },
    { id: false, name: 'No, I do not have' },
  ];

  steps = [
    { name: 'Project details', status: 0, id: 1, icons: 'src/assets/latest-icon/skills-blue.svg'},
    { name: 'Verticles', status: 0, id: 2, icons: 'src/assets/latest-icon/skills-blue.svg'},
    { name: 'Skill groups', status: 0, id: 3, icons: 'src/assets/latest-icon/skills-blue.svg' },
    // { name: 'Skills', status: 0, id: 4, icons: 'src/assets/latest-icon/skills-blue.svg' },
    { name: 'Requirement details', status: 0, id: 4, icons: 'src/assets/latest-icon/skills-blue.svg' },
  ];
  isInputFocused = false;
  isTextareaFocused = false;
title1: any;
title2: any;
title3: any;
  isValidrecapta = false;
  selectedVerticle: any;
  industryType: any;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private sharedService: SharedService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    // Generate minYears, maxYears, and months arrays
    for (let i = 1; i <= 50; i++) {
      const year = { id: i, name: String(i) };
      this.minYears.push(year);
      this.maxYears.push(i === 1 ? { id: -1, name: 'Any' } : year);
    }

    for (let i = 0; i <= 12; i++) {
      this.months.push({ id: i, name: String(i) });
    }
    this.getAllTimeZones();
    this.getProjectContinuityResources();
    this.initializeProjectTypeForm();
    this.initializeContractorForm();
    this.initializeProjectForm();
  }

  private initializeContractorForm() {
    this.contractorForm = this.formBuilder.group(
      {
        projectType: [3],
        // jobTitle2: ['', Validators.required],
        // jobDescription: ['', [Validators.required, Validators.maxLength(5000)]],
        minExperienceYr: [this.minYears[1]?.id, Validators.required],
        minExperienceMonth: [this.months[0]?.id, Validators.required],
        maxExperienceYr: [this.maxYears[0]?.id, Validators.required],
        maxExperienceMonth: [this.months[0]?.id, Validators.required],
        duration: [this.isSelectedDuration, Validators.required],
        urgency: ['', Validators.required],
        preferredTimeZoneId: ['', Validators.required],
        contractTypes: ['', Validators.required],
        employerType: [this.isSelectedEmployerType, Validators.required],
        isRegisteredUser: [null, Validators.required],
        userEmail: ['', [Validators.required, Validators.email]],
      }
    );
  }

  get contractorFormControl() {
    return this.contractorForm.controls;
  }

  onSubmitContractorForm() {
    // const isCaptchaVerified = this.isValidrecapta;
    // if (!isCaptchaVerified) return;
    const email = this.contractorFormControl.userEmail.value;
    let formData = this.contractorForm.value;
    formData.jobLocation = this.projectTypeForm.value.jobLocation;
    formData.billingType = this.projectTypeForm.value.billingType;
    formData.maxPrice = this.projectTypeForm.value.maxPrice;
    formData.minPrice = this.projectTypeForm.value.minPrice;
    formData.workTypeId = this.projectTypeForm.value.workTypeId;
    formData['jobTitle'] = this.projectTypeForm.value.jobTitle;
    formData['jobDescription'] = this.projectTypeForm.value.jobDescription;
    formData.contractTypes = [this.contractorFormControl.contractTypes.value];
    formData.preferredTimeZoneId = this.contractorFormControl.preferredTimeZoneId.value?.id;
    let data = Object.assign(formData, this.selectedSkills, {verticleId: this.selectedVerticle});
    this.validateEmailDomain(email, data);
  }

  public onLocationSearch(event: any) {
    this.commonService.getCities(event.query).subscribe((res: any) => {
      this.locations = res.message.responseData;
      for (const loc of this.locations) {
        loc['formattedName'] =
          loc.cityName + ' - ' + loc.stateName + ' - ' + loc.countryName;
      }
    });
  }

  public getAllTimeZones() {
    this.commonService.getAllTimeZones('+05:30').subscribe((res: any) => {
      this.timeZones = res.message.allTimeZones;
    });
  }

  public getProjectContinuityResources() {
    this.commonService.getProjectContinuityResources().subscribe((res: any) => {
      this.leadUrgency = res.message.leadUrgency;
      this.contractPositionTypes = res.message.contractPositionTypes;
      this.contractorForm.controls.contractTypes.setValue(
        this.contractPositionTypes[0]?.id
      );
      this.projectContinuities = res.message.projectContinuities;
      this.typesOfWork = res.message.typesOfWork;
      this.listOfLifeCycleStages = res.message.listOfLifeCycleStages;
    });
  }

  showSuccess(message) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }

  showError(message) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }

  selectMaxExperienceYr(event: any) {
    const value = event.name;
    this.isMaxExperienceMonth = value != 'Any' ? true : false;
    // this.contractorForm.controls.maxExperienceMonth.setValidators(
    //   value != 'Any' ? [Validators.required] : null
    // );
    // this.contractorForm.controls.maxExperienceMonth.updateValueAndValidity();
  }

  selectDuration(chip: any) {
    this.isSelectedDuration = chip?.id;
    this.contractorForm.controls.duration.setValue(this.isSelectedDuration);
  }

  selectTypeOfWork(chip: any) {
    this.isSelectedWork = chip?.id;
    this.projectTypeForm.controls.workTypeId.setValue(this.isSelectedWork);
  }

  selectEmployerType(chip: any) {
    this.isSelectedEmployerType = chip?.id;
    this.contractorForm.controls.employerType.setValue(
      this.isSelectedEmployerType
    );
  }
  close() {
    this.sharedService.sendValue(false);
    this.drawerClose.emit();
    this.selectedStep = 2;
    this.steps[1].status = 0;
  }

  public closeSkillGroupModal(event) {
    this.selectedStep = 2;
    this.steps[1].status = 0;
  }

  public closeDetsilsModal(event) {
    this.selectedStep = 3;
    this.steps[2].status = 0;
  }

  public getVerticles(event) {
    this.selectedVerticle = event.verticleId;
    this.industryType = event.verticleTypeName;
    this.steps[1].status = 1;
    this.selectedStep = 3
  }
  
  public closeSelectedVeticle() {
    this.steps[0].status = 0;
    this.selectedStep = 1;
    this.projectTypeSelection = true;
  }

  private initializeProjectForm() {
    this.projectForm = this.formBuilder.group({
      projectType: [1],
      // jobTitle1: ['', Validators.required],
      // jobDescription: ['', [Validators.required, Validators.maxLength(5000)]],
      minExperienceYr: [this.minYears[1]?.id, Validators.required],
      minExperienceMonth: [this.months[0]?.id, Validators.required],
      maxExperienceYr: [this.maxYears[0]?.id, Validators.required],
      maxExperienceMonth: [this.months[0]?.id, Validators.required],
      projectLifeCycle: ['', Validators.required],
      billingType: [210],
      typesOfWork: ['', Validators.required],
      lifeCycleStageId: ['', Validators.required],
      urgency: ['', Validators.required],
      preferredTimeZoneId: ['', Validators.required],
      isRegisteredUser: [null, Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
    });
  }

  get projectFormControl() {
    return this.projectForm.controls;
  }

  selectMaxExperienceYrCopy(event: any) {
    const value = event.name;
    this.isMaxExperienceMonthCopy = value != 'Any' ? true : false;
  }

  submitProject(){
    // const isCaptchaVerified = this.isValidrecapta;
    // if (!isCaptchaVerified) return;
      const email = this.projectFormControl.userEmail.value;
      let formData = this.projectForm.value;
      formData.jobLocation = this.projectTypeForm.value.jobLocation;
      formData.maxPrice = this.projectTypeForm.value.maxPrice;
      formData.minPrice = this.projectTypeForm.value.minPrice;
      formData['jobTitle'] = this.projectTypeForm.value.jobTitle;
      formData['jobDescription'] = this.projectTypeForm.value.jobDescription;
      formData.preferredTimeZoneId = this.projectFormControl.preferredTimeZoneId.value?.id;
      let data = Object.assign(formData, this.selectedSkills, {verticleId: this.selectedVerticle}); 
      this.validateEmailDomain(email, data);
  }
  onSubmitProjectForm() {
    // const isCaptchaVerified = this.isValidrecapta;
    // if (!isCaptchaVerified) return;
      const email = this.projectFormControl.userEmail.value;
      let formData = this.projectForm.value;
      formData.jobLocation = this.projectTypeForm.value.jobLocation;
      formData.maxPrice = this.projectTypeForm.value.maxPrice;
      formData.minPrice = this.projectTypeForm.value.minPrice;
      formData['jobTitle'] = this.projectTypeForm.value.jobTitle;
      formData['jobDescription'] = this.projectTypeForm.value.jobDescription;
      formData.preferredTimeZoneId = this.projectFormControl.preferredTimeZoneId.value?.id;
      let data = Object.assign(formData, this.selectedSkills, {verticleId: this.selectedVerticle});
      this.validateEmailDomain(email, data);
  }

  validateEmailDomain(email, formdata) {
    this.commonService.validateEmailDomains(email).subscribe((res: any) => {
      if (res) {
        this.saveData(formdata);
      } else {
        this.showError('Enter valid email address');
      }
    });
  }
  saveData(obj) {
    this.commonService.saveNewRequirement(obj).subscribe({
      next: (res) => {
        let webSiteUrl = res?.['message']?.websiteRequirementId;
        //console.log(webSiteUrl);
        //console.log(`${environment.WEB_URL}/hire-talent?websiteRequirementId=${webSiteUrl}`);
        $('#projectPostingTypeModal').modal('hide');
        this.showSuccess('Data saved successfuly');
        // this.router.navigateByUrl(
        //   `${environment.WEB_URL}/hire-talent?websiteRequirementId=${webSiteUrl}`
        // );
        window.open(`${environment.WEB_URL}/hire-talent?websiteRequirementId=${webSiteUrl}`, "_blank");
        this.close();
      },
      error: (error) => {
        this.showError(error?.error?.message.errorMessage);
      },
    });
  }

  public validateCaptcha(grecaptcha) {
    this.isValidrecapta = true;
    $('#recaptchaError').addClass('d-none');
    if (!grecaptcha) {
      $('#recaptchaError').removeClass('d-none');
      this.isValidrecapta = false;
    }
    // return grecaptcha.getResponse() ? true : false;
  }

  public validateCaptcha2(grecaptcha) {
    this.isValidrecapta = true;
    $('#recaptchaError2').addClass('d-none');
    if (!grecaptcha) {
      $('#recaptchaError2').removeClass('d-none');
      this.isValidrecapta = false;
    }    
    // return grecaptcha.getResponse() ? true : false;
  }

  filterTimeZone(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    let query = event.query;

    for(let i = 0; i < this.timeZones.length; i++) {
        let country = this.timeZones[i];
        if (country.ftimeZone?.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(country);
        }
    }

    this.filteredTimeZones = filtered;
}
private initializeProjectTypeForm() {
  this.projectTypeForm = this.formBuilder.group(
    {
      jobTitle: ['', Validators.required],
      jobDescription: ['', [Validators.required, Validators.maxLength(5000)]],
      billingType: ['', this.activedProject != 2 ? Validators.required : null],
      minPrice: [null, Validators.required],
      maxPrice: [null, Validators.required],
      workTypeId: [this.isSelectedWork, Validators.required],
      jobLocation: ['', Validators.required]
    },
    {
      validators: MaxPriceValidator('minPrice', 'maxPrice'),
    }
  );
}

get projectTypeFormControl() {
  return this.projectTypeForm.controls;
}
onSelectProjectType(type){
  this.activedProject = type;
  //this.initializeProjectTypeForm();
}

  onSubmitProjectType() {
    this.projectTypeSelection = false;
    this.steps[0].status = 1;
    this.selectedStep = 2;
    if (this.activedProject == 1) {
      this.tabText = "Hire contract talent";
    } else {
      this.tabText = "Outsource your project (Find other MSPs)";
    }
  }

  public skillSelection(event) {
    this.selectedSkills = event;
    this.steps[2].status = 1;
    this.selectedStep = 4;
  }

  onFocus(id) {
    if(id == 1) {
      this.isInputFocused = true;
      this.title1  = 'Develop an android app for a startup.';
      this.title2  = 'SEO optimization for my website.';
      this.title3  = 'Social media marketing for my buisness.';
    } else {
      this.isTextareaFocused = true;
      this.title1  = 'Develop an android app for a startup.';
      this.title2  = 'SEO optimization for my website.';
      this.title3  = 'Social media marketing for my buisness.';
    } 
  }

  onBlur(id) {
    if(id == 1) {
      this.isInputFocused = false;
    } else {
      this.isTextareaFocused = false;
    }
  } 
  
  public showTextareaExample() {
    $('#showTextareaExample').modal('show');
    $('.cc-floating').css('display', 'none');
    $('.chat-bot').css('display', 'none');
    $('.overlay-msg').css('display', 'none');
  }

  public showInputExample() {
    $('#showInputExample').modal('show');
    $('.overlay-msg').css('display', 'none');
    $('.cc-floating').css('display', 'none');
    $('.chat-bot').css('display', 'none');
  }
}

export function MaxPriceValidator(minControl: string, maxControl: string) {
  return (formGroup: FormGroup) => {
    let minValue = formGroup.controls[minControl];
    let maxValue = formGroup.controls[maxControl];
    if (maxValue.errors && !maxValue.errors.maxPriceValidator) {
      return;
    }
    if (maxValue.value < minValue.value) {
      maxValue.setErrors({ maxPriceValidator: true });
    } else {
      maxValue.setErrors(null);
    }
  };
}