export const environment = {
    name: 'staging',
    production: true,
    API_URL: 'https://staging-app.amy.pro/api/',
    LOCATION_URL: 'https://staging-app.amy.pro/aum-location/',
    WEBAPP_URL: 'https://staging-app.amy.pro/api/',
    DATA_CENTER_TOKEN: '530a4d4d-8d13-43f0-b583-2a8982543bb8',
    CAPTCHA_SITE_KEY: '6Lf_LxApAAAAABQs38LJmdbO2_S9YSgY70iW_lhX',
    imgpathUrl : "https://aumnicsaiwebsitedatastore.s3.amazonaws.com/aumnics-website",
    WEB_URL: 'https://staging-app.amy.pro/',
    WEBSITE_URL: 'https://staging.amy.pro/',
    locales: 'http://localhost:5000/',
    browserDirPath: 'dist/browser',
    CMS_APPS_URL: 'http://staging-cms-apps-test2.aumnics.com/',
    careersAccessToken : 'CA-PP26NFM42BGC',
    newsAndMediadAccessToken : 'BL-EVMOVF5GGLM9',
    blogsAccessToken: 'BL-MA1A0UOS6BML',
    showGoogleTagManager : false,
    cookieDomain : "amy.pro",
    AMY_BOT_URL: 'https://staging-bot.amy.pro',
    AMY_BOT_ID: 'amy-bot'
};
