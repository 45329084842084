import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgcCookieConsentModule, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent, NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieService } from '../core/services/cookie-service.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.css']
})
export class CookiesConsentComponent implements OnInit {

  constructor(private ccService: NgcCookieConsentService,private cookieService: CookieService,@Inject(PLATFORM_ID) private platformId: Object) { }

    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;


  ngOnInit(): void {

    this.cookieService.setCookie('loggedInDomain' , environment.cookieDomain );
  }


  isBraveBrowser(): Promise<boolean> {
    return new Promise((resolve) => {
      if ((navigator as any).brave) {
        (navigator as any).brave.isBrave().then((response: boolean) => {
          resolve(response);
        });
      } else {
        resolve(false);
      }
    });
  }



  initializeCookies(){
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
      //var type = event.status;
      //if (type = "allow") {
      //  alert("Hello me");
      //}
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

  this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
    });
  }


  ngOnDestroy() {

    if (isPlatformBrowser(this.platformId)) {
      // Check if the user agent contains the word "Brave"
      const isBraveBrowser = window.navigator.userAgent.includes('Brave');

      // Load cookie only if not using the Brave browser
      if (!isBraveBrowser) {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
      }
    }
  }

}
