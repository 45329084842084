<div class="full-height pt-0">
    <div class="">
        <div class="p-0 pt-2">
            <div class="category-listing" *ngIf="projcetModules && projcetModules?.length > 0">
                <ng-container *ngFor="let sug of projcetModules; let i=index">
                    <div class="category cursor-p"
                        [ngClass]="{'selectedProject': ProjectVerticle? selectedVerticle == sug.id: isVerticalSelected(sug.id)}"
                        (click)="ProjectVerticle? onSelectProjcet(sug): onSelectCategory(sug)"
                        *ngIf="sug?.showInSearchCard">
                        <div class="profileLogoDiv mr-2">
                            <div class="profileLogo m-auto" *ngIf="sug.imageUrl"
                                [style.background-image]="'url('+sug.imageUrl+')'">
                            </div>
                            <div class="profileLogo m-auto" *ngIf="sug.imageUrl==='' || !sug.imageUrl"
                                [style.background-color]="storageService.getShortName(sug.name)?.substring(2,9)">
                                <span class="fw-400">
                                    {{storageService.getShortName(sug?.name).substring(0,2)}}
                                </span>
                            </div>
                        </div>
                        <span class="text-wrap fw-400">{{isClient ? sug?.name : sug?.secondaryName}}</span>
                    </div>
                </ng-container>
            </div>
            <div class="h-100 h-68vh d-items-center"
                *ngIf="projcetModules && projcetModules?.length == 0 && !skillLoader">
                <div class="loader title-color"></div>
            </div>
        </div>
    </div>
    <div class="mt-auto bottom-sticky-div" >
        <hr>
        <div class="px-4 d-flex model-bottom-sec">
            <div>
            </div>
            <div class="pb-4">
                <button class="cancel-btn" (click)="onCloseModel()">Back</button>
                <button class="save-btn ml-3" (click)="onVerticlesProceed()" [disabled]="!selectedVerticle"> Continue </button>
            </div>
        </div>
    </div>
</div>