import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscribeNowComponent } from '../subscribe-now/subscribe-now.component';
import { environment } from '../../environments/environment';
import { CommonService } from '../core/services/common.service';
import { MessageService } from 'primeng/api';

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ThankyouModalComponent } from '../thankyou-modal/thankyou-modal.component';
import { env } from 'process';
import { SharedService } from '../core/services/shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [MessageService],
})
export class FooterComponent implements OnInit {
  emailValidations: FormGroup;
  submitted = false;
  languageCode :string ="en";
  web_url :string =environment.CMS_APPS_URL
  showRegularFooter = true;
  companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
  footerDescription = 'Amy, your workplace assistant. Enterprise collaboration for the AI generation. ';
  logoUrl = '/en';
  appName = 'amy';
  signUrl = environment.WEB_URL;
  @Input() headerColor : any;
  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    this.setFooterContents()
  }

  footerUrlPath: any;

  public websiteUrl = environment.WEBSITE_URL;

  ngOnInit(): void {

    this.footerUrlPath = environment.imgpathUrl;
    this.emailValidations = this.formBuilder.group({
      emailId: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
          ),
        ],
      ],
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setFooterContents()
      }})
  }


  switchLang(lang: string) {
    this.translate.use(lang);
  }
  get f(): { [key: string]: AbstractControl } {
    return this.emailValidations.controls;
  }

  onSubmit() {
    if (this.emailValidations.invalid) return;
    const email = this.emailValidations.get('emailId').value;
    this.validateEmailDomain(email);
  }

  openMatDrawer() {
    this.sharedService.sendValue(true);
  }

  openDailog() {
    const dialogRef = this.dialog.open(SubscribeNowComponent, {
      width: '550px',
      height:'780px',
      panelClass: 'subscribe-modal',
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let formData = this.emailValidations.value;
        formData.name = res;
        this.commonService.saveSubscribeData(formData).subscribe({
          next: (res) => {
            //console.log(res);
            this.openThankYouDailog();
          },
          error: (error) => {
            this.showError(error?.error?.message.errorMessage);
          },
        });
      }
    });
  }

  validateEmailDomain(email) {
    this.commonService.validateEmailDomains(email).subscribe((res: any) => {
      if (res) {
        this.openDailog();
      } else {
        this.showError('Enter valid email address');
      }
    });
  }
  showError(message) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }
  openThankYouDailog() {
    const dialogRef = this.dialog.open(ThankyouModalComponent, {
      width: '550px',
      height:'700px',
      panelClass: 'subscribe-modal',
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.emailValidations.reset();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headerColor) {
      this.setFooterContents();
    }
  }

  setFooterContents(){
    if(this.router.url.includes('/cms')){
      this.companyLogo = './../assets/icons/Homepage/amy-cms-logo.png';
      this.logoUrl = '/en/cms';
      this.appName = 'cms';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.cmsDesc');
    }else if(this.router.url.includes('/clockit')){
      this.companyLogo = '../../assets/images/home/amy-clockit-logo.png';
      this.logoUrl = '/en/clockit';
      this.appName = 'clockit';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.clickItDesc');
    }else if(this.router.url == '/en' || this.router.url.includes('/en?') || this.router.url.includes('/why-amy') || this.router.url.includes('/features') || this.router.url.includes('/pricing') || this.router.url.includes('/about-us') || this.router.url.includes('/amy-service-provider') || this.router.url.includes('/contact-us')){
      this.companyLogo = '../../assets/images/home/Amy-logo-white.png';
      this.logoUrl = '/en';
      this.appName = 'amy';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.amyDesc');
    }else {
      this.companyLogo = '../../assets/images/home/Amy-logo-white.png';
      this.logoUrl = '/en';
      this.appName = 'amy';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.amyDesc');

      // this.companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
      // this.logoUrl = '/en/connect';
      // this.appName = 'amyConnect';
      // this.showRegularFooter = true;
      // this.footerDescription = this.translate.instant('headerApps.amyConnectDesc');
      // this.route.queryParams.subscribe((params) => {
      //   let agreementType = params['agreementType'];
      //   let policyType = params['policyType'];
      //   this.setFooterContentsOnType(agreementType ? agreementType : policyType);
      // });
    }
    this.sharedService.setSocialLinks(this.appName);
  }

  setFooterContentsOnType(type){
    if(type == 'cms'){
      this.companyLogo = this.headerColor ? './../assets/icons/Homepage/amy-cms-logo.png' : './../assets/images/home/amy-cms-logo-white.png';
      this.logoUrl = '/en/cms';
      this.appName = 'cms';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.cmsDesc');
    }else if(type == 'clockit'){
      this.companyLogo = this.headerColor ? '../../assets/images/home/amy-clockit-logo.png' : '../../assets/images/home/amy-clockit-logo-white.png';
      this.logoUrl = '/en/clockit';
      this.appName = 'clockit';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.clickItDesc');
    }else if(type == 'amy'){
      this.companyLogo = this.headerColor ? '../../assets/images/home/Amy-logo-white.png' : '../../assets/images/home/Amy-logo-white.png';
      this.logoUrl = '/en';
      this.appName = 'amy';
      this.showRegularFooter = false;
      this.footerDescription = this.translate.instant('headerApps.amyDesc');
    }else if(type == 'amyConnect'){
      this.companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
      this.logoUrl = '/en/connect';
      this.appName = 'amyConnect';
      this.showRegularFooter = true;
      this.footerDescription = this.translate.instant('headerApps.amyConnectDesc');
    }
  }
}
