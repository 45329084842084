<div class="mobile-search-div search-input">
    <div class="d-flex align-items-center">
        <input class="form-control text-wrap" [type]="'search'" [(ngModel)]="searchKey" placeholder="Search agency talent, skills, discounted packages" id="mobileSearchInput"
        (keyup)="filterCountry()" (keydown)="filterCountry()" (onClear)="filterCountry()">
        <div class="ml-3">
            <mat-icon class="cursor-p" aria-hidden="false" aria-label="Example clear icon" fontIcon="close" (click)="closeSearch()"></mat-icon>
          </div>
    </div>     
    <div class="p-2 res-listing-div" *ngIf="filteredCountries.length > 0">
        <div class="flex align-items-center justify-content-between w-100 my-2" *ngFor="let country of filteredCountries" (click)="goToType(country)">
            <div class="text-wrap d-flex">
                <div class="fw-500 text-wrap">{{ country.name }}</div> <div class="type-tag ml-2">&#x2022; {{country.type}}</div>
            </div>
            <div class="profileLogoDiv">
                <div class="seacrh-profile cursor-p" [ngClass]="{'package' :country.typeId == 1}"
                  *ngIf="country.imageUrl" [style.background-image]="'url('+country.imageUrl+')'">
                </div>
                <div class="seacrh-profile" *ngIf="!country.imageUrl" [ngClass]="{'package' :country.typeId == 1}"
                  [style.background-color]="sharedService.getConstantRandomColors(country.name?.length)">
                  <span class="s-dev-short-name">{{sharedService.getShortName(country.name)}}</span>
                </div>
              </div>
        </div>
    </div>
  </div>
    