import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../app/core/services/common.service';
import { SharedService } from '../../../app/core/services/shared.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent implements OnInit {

  constructor(private commonService : CommonService,private sharedService : SharedService,private router : Router) { }

  searchKey = '';
  ngOnInit(): void {
  }

  selectedCountries : any;
  filteredCountries = []

  filterCountry(){
    if(this.searchKey == ''){
      this.filteredCountries = [];
      return;
    }
    this.commonService.getSearchResults(this.searchKey).subscribe(results => {
      this.filteredCountries = results.message
    })

  }

  goToType(obj: any){
    this.closeSearch();
    if(obj.typeId == 1){
      const navigationExtras: NavigationExtras = {
        queryParams: { subscription: 'premium' }
      };
      this.router.navigate(['en/connect/discounted-packages/' + obj.acpCategorySlug +'/' + obj.packageSlug], navigationExtras);
    }else if(obj.typeId == 2){
      this.router.navigateByUrl('en/connect/hire-from-agencies/' + obj.companySlug)
    }else if(obj.typeId == 3){
      this.router.navigateByUrl('en/connect/hire-contractors-by-skills/' + obj.skillGroupSlug)
    }else if(obj.typeId == 4){

      this.sharedService.sendValue(true);
      this.sharedService.setDeveloperWorkId(obj.workId);
    }
  }

  closeSearch(){
    this.sharedService.sendValue(false);
    this.sharedService.setSearchValue(false)
  }
  
}
