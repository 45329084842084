export enum EndPoint {

    GET_ALL_TIMEZONES = 'aum-user-service/api/location/getCountryCode',
    SEARCH_CITIES = 'geoData/searchCity',
    GET_ALL_SKILL_GROUPS = 'aum-user-service/api/skillGroups',
    GET_SKILL_GROUPS_WITH_ACTIVE_DEVELOPERS = 'aum-user-service/api/getSkillGroupsOfActiveDevelopers',
    GET_DEMO_SLOTS = 'aum-schedule-service/api/calendar/executives/availablily',
    SAVE_CONNECT_DEMO_BOOKING = 'aum-leavemanagement-service/api/saveConnectDemoBooking',
    VALIDATE_EMAIL_DOMAINS = 'aum-user-service/api/validateEmailDomain',
    GET_PROJECT_CONTINUITITY = 'aum-project-service/api/projectStatic/getProjectContinuityResources',
    NEW_REQUIREMENT = 'aum-user-service/api/website/requirement/new',
    Save_Subscriber_User = "aum-leavemanagement-service/api/demand/saveSubscribeUser",
    Get_Home_Blog = "aum-blog-and-media-service/api/blog/open/blogsInfoToShowOnWebsite",
    GET_COUNTRY_PHONE_NUMBER_PREFIX = 'aum-user-service/api/location/countries',
    GET_BLOGS_ACCESS_TOKEN = 'aum-blog-and-media-service/api/blog/open/getBlogsAccessToken',
    GET_DESIGNATIONS = 'aum-user-service/api/profile/searchDesignations',
    GET_AUMNICS_NEWS_AND_MEDIA = 'aum-blog-and-media-service/api/news/open/getNewsAndCustomization',
    JOB_POSTED_BY_BY_COMPANY = 'aum-careers-service/api/careers/open/jobsPosted',
    GET_ALL_NEW_PLANS = 'aum-billing-service/api/subscription/plans/website',
    GET_AMY_SUBSCRIPTION_PLANS = 'aum-billing-service/api/subscription/getAmySubscriptionPlans',
    GET_AUMNICS_CERTIFIED_PACKAGES = 'aum-leavemanagement-service/api/acp/webiste/aumnicsCertifiedPackages',
    GET_AUMNICS_CERTIFIED_PACKAGE = 'aum-leavemanagement-service/api/acp/webiste/aumnicsCertifiedPackagesByuuid',
    GET_COPANY_PITCHES = 'aum-leavemanagement-service/api/acp/webiste/getCompanyPitchesOfActiveDevelopers',
    GET_DEVELOPER_PICHES = 'aum-leavemanagement-service/api/acp/webiste/getDeveloperPitchesOfCompany',
    GET_DEVELOPERS_OF_SKILLSGROUP = 'aum-leavemanagement-service/api/acp/webiste/getDeveloperPitchesOfSkillGroup',
    GET_DEVELOPER_PROFILE = 'aum-user-service/api/user/public/profile/getStaticProfile',
    GET_PACKAGES_BY_CATEGORY = 'aum-leavemanagement-service/api/acp/webiste/aumnicsCertifiedPackagesByCategories',
    GET_TUTORIAL_BY_CATEGORY = 'aum-helpdesk-service/api/tutorial/getActiveTutorialsInfo',

    GET_TOP_DEMAND_COMPANIES = 'aum-leavemanagement-service/api/demand/getTopDeamdSkillCompanies',
    GET_ALL_ACP_CATEGORIES = 'aum-leavemanagement-service/api/acpcategory/getAllAcpCategoriesWithSubCategories',
    GET_AGREEMENT_DATA_BY_ID = 'aum-user-service/api/privacyPolicy/getPrivacyPolicyOrUserAgreementInfo',
    GET_TOP_DEMAND_CHART = 'aum-leavemanagement-service/api/demandChart/demandChartInfo_v2',
    GET_TEMPLATES_AVAILABLE_CATEGORIES_WITH_SUB_CATEGORIES = 'aum-leavemanagement-service/api/acpcategory/getTemplatesAvailableCategoriesWithSubCategories',

    //Seacrh
    GET_SEARCH_RESULTS = 'aum-search-service/api/professional/searchSuggestionsForWebsite',
    GET_ALL_INDUSTRY_TYPES = 'aum-project-service/api/project/getAllIndustryTypes',

    // URLS for Emojis & their Skins
    EMOJIS_PATH = 'https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/amy-emojis/',
    skin_tone_light = 'st_light', // code 2
    skin_tone_medium_light = 'st_medium_light', // code 3
    skin_tone_medium = 'st_medium', // code 4
    skin_tone_medium_dark = 'st_medium_dark', // code 5
    skin_tone_dark = 'st_dark', // code 6


    // templates 
    GET_TEMPLATE_BY_UUID = 'aum-user-service/api/pmtemplates/getTemplateInfoByUuid',
    GET_STATUSES_OF_PROJECTS = 'aum-user-service/api/provider/task/projectTaskStatus',
    GET_CUSTOM_DATA_TYPES = 'aum-user-service/api/task/customfield/getCustomFieldDatatypes',
    GET_CUSTOM_FIELDS = 'aum-user-service/api/task/customfield/getCustomeFields',
    GET_ALL_DYNAMIC_COLUMNS_OF_PROJECT = 'aum-user-service/api/task/customfield/getProviderProjectFieldPreferences',
    GET_CURRENCY_TYPE_LIST = 'aum-user-service/api/provider/pipeline/getDefaultCurrencyInfo',
    GET_TASK_LISTING_OF_PROJECT_POSTING_BY_GROUPING = 'aum-user-service/api/provider/task/getGroupingInfo',
    GET_TASK_LISTING_OF_PROJECT_POSTING = 'aum-user-service/api/provider/task/getProjectTasksList',
    GET_SUB_TASK_LISTING_OF_PROJECT_POSTING = 'aum-user-service/api/provider/task/getProjectSubTasksList',
    GET_TASKLIST_FOR_PLAN_WITH_SUBTASKS = 'aum-user-service/api/provider/goal/getProjectTasksList',
    GET_TASKS_OF_COMPANY = 'aum-user-service/api/provider/task/getProjectTasksOfCompany',
    GET_PROJECT_PIPELINE = 'aum-user-service/api/provider/pipeline/getProviderProjectPipeline',
    GET_MAIN_CHECK_LIST = 'aum-user-service/api/provider/task/getMainCheckListInfo',
    GET_TAGS_BY_PROJECT_ID = 'aum-billing-service/api/timelog/getAllTagsByProject',
    GET_CUSTOM_FIELD_FOR_FILTERS = 'aum-user-service/api/task/customfield/getCustomFieldForFilter',
    GET_CHECK_LIST = 'aum-user-service/api/provider/task/getCheckListInfo',
}