<div class="over-layout" *ngIf="!isSelectedGroup">
    <div class="over-layout-header">
        <div class="hearder-content">
            <div class="header-title">
                <img loading="lazy" src="{{skillGroupPath + '/courses/hire.svg'}}" alt="cource hire">
                <h2>{{"hireContractors.WhoDowantHire" | translate}}</h2>
            </div>
            <div class="search-box">
                <div class="search-sec">
                    <input type="text" name="searchTerm" [(ngModel)]="searchTerm" class="form-control"
                        placeholder="{{'buttons.search' | translate }}" (keyup)="serachGroup()">
                    <button class="btn">{{"buttons.search" | translate}}</button>
                </div>
                <mat-icon aria-hidden="false" aria-label="Example clear icon" fontIcon="close"
                    (click)="close()" *ngIf="!hideCss"></mat-icon>
            </div>
        </div>
    </div>
    <div class="over-layout-content">
        <div class="course-container">
            <div *ngIf="loader" class="d-flex align-items-center justify-content-center h-65 m-auto">
                <div class="loader">Loader</div>
            </div>
            <ng-container *ngFor="let group of copySkillGroups">
                <div class="course-tile" (click)="activedProject == 2? selectSkillGroups(group): selectGroup(group)"
                    [ngClass]="{'active-group': activedProject == 2? isSelected(group): selectedGroup == group}">
                    <div class="course-icons">
                        <img loading="lazy" src="{{group?.image}}" height="35" width="120px" alt="{{group.name}}">
                    </div>
                    <div class="course-title">
                        <h2>{{group.name}}</h2>
                    </div>
                    <div class="course-content-new">
                        <span>{{group?.skills?.length}} skills <mat-icon>navigate_next</mat-icon> </span>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="searchTerm && searchTerm?.length > 0 && copySkillGroups?.length == 0" 
                class="h-65 w-100 d-flex align-items-center justify-content-center">
                <div class="text-center">
                    <img width="200" src="../../assets/icons/featureInLogos/no-results.svg" alt="">
                    <span class="text-gray d-block mt-3">No results found with {{searchTerm}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-auto bottom-sticky-div" >
        <hr>
        <div class="px-4 d-flex model-bottom-sec">
            <div>
                <span *ngIf="selectedGroupName && !isSelectedGroup">
                    <i class="fa-solid fa-circle-check"></i>&nbsp;&nbsp;{{selectedGroupName}}
                </span>
            </div>
            <div class="pb-4">
                <button (click)="close(1)" class="cancel-btn mr-3">{{hideCss ? 'Back' : "buttons.cancel" | translate}}</button>
                <button class="save-btn" (click)="activedProject == 2? saveAndNext2(): saveAndNext()">{{"buttons.Savenext" | translate}}</button>
            </div>
        </div>
    </div>
    <!-- <div class="over-layout-footer" [ngClass]="{'p-5': hideCss}">
        <div>
            <span *ngIf="selectedGroupName && !isSelectedGroup"><i
                    class="fa-solid fa-circle-check"></i>&nbsp;&nbsp;{{selectedGroupName}}</span>
        </div>
        <div class="footer-buttons" [ngClass]="{'p-absolute border-0 pb-4': hideCss}">
            <button mat-stroked-button (click)="close()" class="cancel">{{"buttons.cancel" | translate}}</button>
            <button mat-flat-button color="primary" (click)="saveAndNext()">{{"buttons.Savenext" | translate}}</button>
        </div>
    </div> -->
</div>

<!-------------if selected-------------------->
<div class="over-layout" *ngIf="isSelectedGroup">
    <div class="over-layout-header" *ngIf="!hideCss">
        <div class="hearder-content">
            <div class="header-title select-skills">
                <h2>{{"hireContractors.SelectSkills" | translate}} {{selectedGroupName}}</h2>
            </div>
            <div class="search-box skill-group">
                <mat-icon aria-hidden="false" aria-label="Example clear icon" fontIcon="close"
                    (click)="close()"></mat-icon>
            </div>
        </div>
        <div>
        </div>
    </div>
    <ng-container>
        <div class="row skill-selection-main-section">
            <!------  Default Drag skill section-->
            <div class="col-4 left-drag-sec ml-3 mt-3" cdkDropList #defaultList="cdkDropList"
                [cdkDropListData]="category?.skills" [cdkDropListConnectedTo]="[primaryList.id, secondaryList.id]">
                <div class="mb-4" *ngFor="let category of categoryGroups">
                    <div class="">
                        <h5 class="category mt-3">{{ category?.categoryName}}</h5>
                    </div>
                    <div class="">
                        <span class="skill-tag" *ngFor="let skill of category?.skills" [cdkDragData]="skill" cdkDrag  (cdkDragStarted)="onSkillDragStart(skill)">
                            {{skill?.skillName}}
                        </span>
                    </div>
                </div>
            </div>
            <!-- Drop skills sections -->
            <div class="col-8 mt-3 right-drop-section">
                <div class="pt-3 right-drop-inner-section">
                    <!----primary skills section-->
                    <h5 class="category mb-3 ml-3">Primary skills</h5>
                    <div style="position:relative;">
                        <input type="text" class="form-control tagsField search-icon ml-3 mb-3"
                            placeholder="Search for primary skills here or drag and drop skills from left menu"
                            name="{{id}}" id="{{id}}" maxlength="150" [(ngModel)]="searchPrimarySkills"
                            (input)="filterSkills()">
                        <ng-container *ngIf="filteredSkills.length > 0 && showDropDown">
                            <ul class="dropdown-menu mb-3 ml-3" aria-labelledby="{{id}}">
                                <li *ngFor="let skill of filteredSkills">
                                    <a class="dropdown-item" (click)="onskillsObj1(skill)">{{ skill.skillName }}</a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="searchSkills">
                            <ul class="dropdown-menu mb-3 ml-3" aria-labelledby="{{id}}">
                                <li>
                                    <a class="dropdown-item">No primary skills matching</a>
                                </li>
                            </ul>
                        </ng-container>
                    </div>

                    <div #primaryList="cdkDropList" cdkDropList [cdkDropListData]="primaryItems"
                        [cdkDropListConnectedTo]="[defaultList.id, secondaryList.id]"
                        (cdkDropListDropped)="onDrop($event, 'primary',categoryGroups,primaryItems)"
                        class="section skill-drop-sec ml-3" style="min-height: 23vh; width: 95%;"
                        [ngClass]="{'nodata': primaryItems?.length == 0}">
                        <div class="" *ngIf="primaryItems?.length > 0">
                            <ng-container>
                                <span class="skill-tag active" *ngFor="let skill of primaryItems" [cdkDragData]="skill"
                                    cdkDrag>
                                    {{skill?.skillName}}
                                    <i class="remove pi pi-times-circle"
                                        (click)="removeFromSection(skill, 'primary')"></i>
                                </span>
                            </ng-container>
                        </div>
                        <div *ngIf="primaryItems?.length == 0" class="text-center p-5">
                            <p>Drag & drop primary skills here or search and add primary skills here</p>
                        </div>
                    </div>
                    <!----secondary skills section-->
                    <h5 class="category mt-3 mb-3 ml-3">Secondary skills <span class="sub-title sub-heading-color">
                            (Optional)</span></h5>
                    <div style="position:relative;">
                        <input type="text" class="form-control tagsField search-icon ml-3 mb-3"
                            placeholder="Search for secondary skills here or drag and drop skills from left menu"
                            name="{{id}}" id="{{id}}" maxlength="150" [(ngModel)]="searchsecondarySkills"
                            (input)="filterSecondarySkills()">
                        <ng-container *ngIf="filteredSecondarySkills.length > 0 && showSecondaryDropDown">
                            <ul class="dropdown-menu mb-3 ml-3" aria-labelledby="{{id}}">
                                <li *ngFor="let skill of filteredSecondarySkills">
                                    <a class="dropdown-item" (click)="onskillsObj2(skill)">{{ skill.skillName }}</a>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="searchSecondarySkillsNoData">
                            <ul class="dropdown-menu mb-3 ml-3" aria-labelledby="{{id}}">
                                <li>
                                    <a class="dropdown-item">No secondary skills matching</a>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                    <div #secondaryList="cdkDropList" cdkDropList [cdkDropListData]="secondaryItems"
                        [cdkDropListConnectedTo]="[defaultList.id, primaryList.id]"
                        (cdkDropListDropped)="onDrop($event, 'secondary',categoryGroups)"
                        class="section skill-drop-sec ml-3" style="min-height: 23vh; width: 95%;"
                        [ngClass]="{'nodata': secondaryItems?.length == 0}">
                        <div class="" *ngIf="secondaryItems?.length > 0">
                            <ng-container>
                                <span class="skill-tag active" *ngFor="let skill of secondaryItems"
                                    [cdkDragData]="skill" cdkDrag>
                                    {{skill.skillName}}
                                    <i class="remove pi pi-times-circle"
                                        (click)="removeFromSection(skill, 'secondary')"></i>
                                </span>
                            </ng-container>
                        </div>
                        <div *ngIf="secondaryItems?.length == 0" class="text-center p-5">
                            <p>Drag & drop secondary skills here or search and add secondary skills here</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!---------------->
    <div class="mt-auto bottom-sticky-div" >
        <hr>
        <div class="px-4 d-flex model-bottom-sec">
            <div>
                <span *ngIf="isSelectedGroup"><i class="fa-solid fa-circle-check"></i>&nbsp;&nbsp;{{primaryItems?.length +
                    secondaryItems?.length}} skills selected</span>
            </div>
            <div class="pb-4">
                <button class="cancel-btn mr-3" (click)="close(2)">{{hideCss ? 'Back' : 'Cancel'}}</button>
                <button class="save-btn mr-3" (click)="nextToHire()">Save and next</button>
            </div>
        </div>
    </div>
    <!-- <div class="over-layout-footer" [ngClass]="{'p-5': hideCss}">
        <div>
            <span *ngIf="isSelectedGroup"><i class="fa-solid fa-circle-check"></i>&nbsp;&nbsp;{{primaryItems?.length +
                secondaryItems?.length}} skills selected</span>
        </div>
        <div class="footer-buttons" [ngClass]="{'p-absolute border-0 pb-4': hideCss}">
            <button mat-stroked-button (click)="close()">Cancel</button>
            <button mat-flat-button color="primary" (click)="nextToHire()">Save and next</button>
        </div>
    </div> -->
</div>