<div class="packages-sec">
    <div class="container">
      <div class="mb-5 mt-5 pt-4">
        <div class="px-5 py-5 d-flex justify-content-between align-items-center skeloton-bg">
          <div class="w-100 m-5">
            <p-skeleton width="200px" height="1.5rem" styleClass="mb-5"></p-skeleton>
            <p-skeleton width="250px" height="1rem" styleClass="mb-4"></p-skeleton>
            <p-skeleton width="80%" height="1rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="40%" height="1rem" styleClass="mb-2"></p-skeleton>
          </div>
          <div class="m-5">
            <p-skeleton width="100px" height="7rem" ></p-skeleton>
          </div>
        </div>
      </div>
      <div class="card-margin-container">
        <div class="d-flex cards-container package-listing-div">
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="res-card skeloton-bg">
            <div>
              <div class="custom-skeleton p-2">
                <div class="text-center my-5  mx-auto" style="max-width: 5rem;">
                  <p-skeleton shape="circle" size="5rem"></p-skeleton>
                </div>
                <div>
                  <p-skeleton width="10rem" styleClass="mb-4 d-flex justify-content-center" class="d-flex justify-content-center"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                  <p-skeleton height=".8rem" styleClass="mb-1"></p-skeleton>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                  <p-skeleton width="4rem" height="1.5rem"></p-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>