import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
declare let $: any;
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { environment } from '../../environments/environment';
import { Router ,NavigationEnd, Event} from '@angular/router';
import { SharedService } from '../core/services/shared.service';
import { HttpResponse } from '@angular/common/http';
import { CommonService } from '../core/services/common.service';
import { ElementRef, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy , AfterViewInit{
  worldElement: any;
  usaElement: any;
  indiaElement: any;
  ukElement: any;
  uaeElement: any;
  phillipineElement: any;
  germanyElement: any;
  norwayElement: any;
  australiaElement: any;
  appName = 'amy';
  cart
  companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
  logoUrl = '/en'
  public expandedOptions = false;

  @Input() headerColor = false;

  constructor(
    public translate: TranslateService,
    private vps: ViewportScroller,
    public router: Router,
    public sharedService: SharedService,
    private commonService: CommonService,
    private el: ElementRef, private renderer: Renderer2,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ref: ChangeDetectorRef
  ) {
    translate.addLangs(["en", "nl"]);
    translate.setDefaultLang("en");
    this.setNavbarContents();
  }
  buttonElement: any;
  cloBtnElement: any;
  countryElement: any;
  countryWebElement: any;
  countryDropElement: any;
  cartWebElement: any;
  cartDropElement: any;
  saWebElement: any;
  saDropElement: any;
  businessEcosystemElement : any;
  industriesElement : any;
  pricingElement : any;
  learnElement : any;
  businessEcosystemMobileElement : any;
  industriesMobileElement : any;
  pricingMobileElement : any;
  learnMobileElement : any;
  productsDropElement: any;
  navbarPathUrl: any;
  signUrl: any;
  languageCode: string = "en";
  homeBlog: any;
  appsUrl = environment.CMS_APPS_URL;
  activeNavLink = "en/connect/software-services/global-overview";
  whiteLinks = false;
  public websiteUrl = environment.WEBSITE_URL;

  developers = [ ]
  showRegularHeader = false;
  showRoutingLinks = false;

  Countries = [
    {
      name: "United States",
      id: "usa-item",
      imgUrl: "../assets/icons/Explore-countries/usa_explore.svg",
      navLink: "en/connect/software-services/united-states",
      active: true,
      startedOparations: true,
    },
    {
      name: "India",
      id: "india-item",
      imgUrl: "../assets/icons/Explore-countries/India_explore.svg",
      navLink: "en/connect/software-services/india",
      active: true,
      startedOparations: true,
    },
    {
      name: "United Arab Emirates",
      id: "uae-item",
      imgUrl: "../assets/icons/Explore-countries/uae_explore.svg",
      navLink: "en/connect/software-services/united-arab-emirates",
      active: false,
      startedOparations: true,
    },
    {
      name: "Philippine's",
      id: "phillipine-item",
      imgUrl: "../assets/icons/Explore-countries/philippines_explore.svg",
      navLink: "en/connect/software-services/philippines",
      active: false,
      startedOparations: true,
    },
    {
      name: "United Kingdom",
      id: "uk-item",
      imgUrl: "../assets/icons/Explore-countries/uk_explore.svg",
      navLink: "en/connect/software-services/united-kingdom",
      active: true,
      startedOparations: false,
    },
    {
      name: "Australia",
      id: "australia-item",
      imgUrl: "../assets/icons/Explore-countries/australia_explore.svg",
      navLink: "en/connect/software-services/australia",
      active: true,
      startedOparations: false,
    },
    {
      name: "Germany",
      id: "germany-item",
      imgUrl: "../assets/icons/Explore-countries/germany_explore.svg",
      navLink: "en/connect/software-services/germany",
      active: true,
      startedOparations: false,
    },
    {
      name: "Norway",
      id: "norway-item",
      imgUrl: "../assets/icons/Explore-countries/norway_explore.svg",
      navLink: "en/connect/software-services/norway",
      active: true,
      startedOparations: false,
    },
  ];
  world = {
    name: "Global Software Services Overview",
    id: "world-item",
    imgUrl: "../assets/icons/Explore-countries/global_explore.svg",
    navLink: "en/connect/software-services/global-overview",
    active: true,
    startedOparations: true,
  };

  ngOnInit(): void {
    this.signUrl = environment.WEB_URL;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setNavbarContents()
      }})
    

    this.matIconRegistry.addSvgIcon(
      'my-custom-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('path/to/icon.svg')
    );

    this.navbarPathUrl = environment.imgpathUrl;

    if (isPlatformBrowser(this.platformId)) {
      this.getHomeBlog();
    }

    this.router.events.subscribe((event: Event) => {
      if (isPlatformBrowser(this.platformId)) {
        if (event instanceof NavigationEnd) {
          this.headerColor = false;
          this.setNavbarContents();
          setTimeout(() => {
            this.onChangeRoute();
          },1000);
        }  
      }
    });   

    this.commonService.loadUrlGuideEvt().subscribe((res: any) => {
      if(res == 'amp') {
        this.appName = 'amp/packages';
        this.commonService.SignupUrl = 'get-started/u/setup-profile?returnUrl='+this.appName;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headerColor) {
      this.setNavbarContents()
    }
  }

  // This method will be called on route change and check for the matching route and make it highlight or blue 
  onChangeRoute() {
    this.ngAfterViewChecked(true);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  menuClick() {
    $("body").toggleClass("open");
  }

  closeClick() {
    $("body").removeClass("open");
  }

  private getHomeBlog() {
    this.commonService.getHomeBlog().subscribe((blog) => {
      this.homeBlog = blog;
      this.homeBlog = this.homeBlog.message.blogs[0];
    });
  }

  public gotToRoute(route: string){
    this.router.navigateByUrl(route);
  }

  // Array of element IDs to trigger panel close on hover
private hoverCloseElements = ["bgOverlay", "logoElement", "loginElement", "signupElement"];

// Method to add hover event listeners to specified elements
private addHoverListenersToClosePanel() {
  const self = this;
  this.hoverCloseElements.forEach(id => {
    const element = this.document.getElementById(id);
    if (element) {
      element.addEventListener('mouseenter', () => {
        self.recetWebNavElemets();
      });
    }
  });
}

// close and recets web nav options panel 
recetWebNavElemets() {
  const elements = {
    business: { main: "#businessEcosystemElement", relative: "#businessrelativeDiv" },
    industry: { main: "#industriesElement", relative: "#indutryrelativeDiv" },
    pricing: { main: "#pricingElement", relative: "#picingrelativeDiv" },
    learn: { main: "#learnElement", relative: "#learnrelativeDiv" }
  };

  // Remove 'expanded' class from all elements and their relative divs
  Object.keys(elements).forEach(key => {
    $('#relativeDiv').removeClass("expanded");
    $(elements[key].main).removeClass("expanded");
    $(elements[key].relative).removeClass("expanded");
  });

  // Reset state variables
  this.currentExpandedElement = null;
  this.expandedOptions = false;
}

// Call this method in ngAfterViewInit to set up hover listeners
ngAfterViewInit() {
  this.addHoverListenersToClosePanel();
}



  // Utility to add click event listeners to elements
  private addClickListener(elementId: string, callback: () => void): HTMLElement | null {
    const element = this.document.getElementById(elementId);
    if (element && !element.hasAttribute('data-click-bound')) {
      element.addEventListener('click', callback);
      element.setAttribute('data-click-bound', 'true'); // Mark to prevent duplicate listeners
    }
    return element;
  }

  // Utility to remove event listeners
  private removeEventListener(element: HTMLElement | null, callback: () => void) {
    if (element) {
      element.removeEventListener('click', callback);
      element.removeAttribute('data-click-bound');
    }
  }

  // Called after the view is checked
  ngAfterViewChecked(reload = false) {
    // Menu
    this.buttonElement = this.addClickListener('menu-toggle', this.menuClick);
    this.cloBtnElement = this.addClickListener('navbar-toggler1', this.closeClick);

    // Business ecosystem and other elements
    this.businessEcosystemElement = this.addClickListener('businessEcosystemElement', () => this.onClickWebElement('business'));
    this.industriesElement = this.addClickListener('industriesElement', () => this.onClickWebElement('industry'));
    this.pricingElement = this.addClickListener('pricingElement', () => this.onClickWebElement('pricing'));
    this.learnElement = this.addClickListener('learnElement', () => this.onClickWebElement('learn'));

    // Mobile elements
    this.businessEcosystemMobileElement = this.addClickListener('businessEcosystemMobileElement', () => this.onClickMobileElement('business'));
    this.industriesMobileElement = this.addClickListener('industriesMobileElement', () => this.onClickMobileElement('industry'));
    this.pricingMobileElement = this.addClickListener('pricingMobileElement', () => this.onClickMobileElement('pricing'));
    this.learnMobileElement = this.addClickListener('learnMobileElement', () => this.onClickMobileElement('learn'));
  }

  // Remove all event listeners when component is destroyed
  ngOnDestroy() {
    this.removeEventListener(this.buttonElement, this.menuClick);
    this.removeEventListener(this.cloBtnElement, this.closeClick);

    this.removeEventListener(this.businessEcosystemElement, () => this.onClickWebElement('business'));
    this.removeEventListener(this.industriesElement, () => this.onClickWebElement('industry'));
    this.removeEventListener(this.pricingElement, () => this.onClickWebElement('pricing'));
    this.removeEventListener(this.learnElement, () => this.onClickWebElement('learn'));

    this.removeEventListener(this.businessEcosystemMobileElement, () => this.onClickMobileElement('business'));
    this.removeEventListener(this.industriesMobileElement, () => this.onClickMobileElement('industry'));
    this.removeEventListener(this.pricingMobileElement, () => this.onClickMobileElement('pricing'));
    this.removeEventListener(this.learnMobileElement, () => this.onClickMobileElement('learn'));
  }

  // A global variable to track the currently expanded element
public currentExpandedElement = null;

onClickWebElement(type) {
  const elements = {
    business: { main: "#businessEcosystemElement", relative: "#businessrelativeDiv" },
    industry: { main: "#industriesElement", relative: "#indutryrelativeDiv" },
    pricing: { main: "#pricingElement", relative: "#picingrelativeDiv" },
    learn: { main: "#learnElement", relative: "#learnrelativeDiv" }
  };

  // Get the current state of the clicked element
  const isAlreadyExpanded = $(elements[type].main).hasClass("expanded");

  // Remove the 'expanded' class from all elements and their relative divs
  Object.keys(elements).forEach(key => {
    $('#relativeDiv').removeClass("expanded");
    $(elements[key].main).removeClass("expanded");
    $(elements[key].relative).removeClass("expanded");
  });

  // If the clicked element wasn't already expanded, toggle the 'expanded' class on both the main and relative divs
  if (!isAlreadyExpanded) {
    $('#relativeDiv').addClass("expanded");
    $(elements[type].main).addClass("expanded");
    $(elements[type].relative).addClass("expanded");
    this.currentExpandedElement = type;
    this.expandedOptions = true; 
  } else {
    this.currentExpandedElement = null;
    this.expandedOptions = false;
  }

  // Handle click outside of the expanded relative elements to close them
  $(document).off("click").on("click", (event) => {
    const clickedOutside = !$(event.target).closest(elements[type].relative).length && !$(event.target).closest(elements[type].main).length;

    // Check if clicked outside and if any element is currently expanded
    if (clickedOutside && this.currentExpandedElement) {
      Object.keys(elements).forEach(key => {
        $('#relativeDiv').removeClass("expanded");
        $(elements[key].main).removeClass("expanded");
        $(elements[key].relative).removeClass("expanded");
      });

      this.currentExpandedElement = null;
      this.expandedOptions = false; // Reset headerColor if all elements are collapsed
    }
  });

  // Handle 'Esc' key press to close the expanded element
  $(document).off("keydown").on("keydown", (event) => {
    if (event.key === "Escape" && this.currentExpandedElement) {
      Object.keys(elements).forEach(key => {
        $('#relativeDiv').removeClass("expanded");
        $(elements[key].main).removeClass("expanded");
        $(elements[key].relative).removeClass("expanded");
      });

      this.currentExpandedElement = null;
      this.expandedOptions = false;
    }
  });
}

  onClickMobileElement(type) {
    const elements = {
      business: { main: "#businessEcosystemMobileElement", relative: "#businessrelativeDiv" },
      industry: { main: "#industriesMobileElement", relative: "#indutryrelativeDiv" },
      pricing: { main: "#pricingMobileElement", relative: "#picingrelativeDiv" },
      learn: { main: "#learnMobileElement", relative: "#learnrelativeDiv" }
    };

    // Get the current state of the clicked element
    const isAlreadyExpanded = $(elements[type].main).hasClass("open");

    
    // Remove 'open' and add 'd-none' to all other elements except the clicked one
    Object.keys(elements).forEach(key => {
      if(isAlreadyExpanded){
        $(elements[key].main).removeClass("open").removeClass("d-none");
        $(elements[key].relative).removeClass("open").removeClass("d-none");
      } else {
        if (key !== type) {
          $(elements[key].main).removeClass("open").addClass("d-none");
          $(elements[key].relative).removeClass("open").addClass("d-none");
        }
      }     
    });

    // If the clicked element was already expanded, just close it without adding 'd-none'
    if (isAlreadyExpanded) {
      $(elements[type].main).removeClass("open");
      $(elements[type].relative).removeClass("open");
      $('#mobileHeaderLogo').removeClass("d-none");
      $('#backText').addClass("d-none");
    } else {
      // If not expanded, show it and remove 'd-none'
      $(elements[type].main).addClass("open").removeClass("d-none");
      $(elements[type].relative).addClass("open").removeClass("d-none");
      $('#mobileHeaderLogo').addClass("d-none");
      $('#backText').removeClass("d-none");
    }
}

 resetAndCloseMobileElements(){
  this.recetAllMobileElements();
  this.closeClick();
 }

  recetAllMobileElements() {
    const elements = {
      business: { main: "#businessEcosystemMobileElement", relative: "#businessrelativeDiv" },
      industry: { main: "#industriesMobileElement", relative: "#indutryrelativeDiv" },
      pricing: { main: "#pricingMobileElement", relative: "#picingrelativeDiv" },
      learn: { main: "#learnMobileElement", relative: "#learnrelativeDiv" }
    };
    Object.keys(elements).forEach(key => {
      $(elements[key].main).removeClass("open").removeClass("d-none");
      $(elements[key].relative).removeClass("open").removeClass("d-none");
    });
    $('#mobileHeaderLogo').removeClass("d-none");
    $('#backText').addClass("d-none");
  }

  openMatDrawer() {
    this.closeClick();
    this.sharedService.sendValue(true);
    this.sharedService.setShowCountries(true);
  }


  onClickCountry() {
   this.openMatDrawer();
  }


  setNavbarContents(){
    if(this.router.url.includes('/cms')){
      this.companyLogo = this.headerColor ? './../assets/icons/Homepage/amy-cms-logo.png' : './../assets/images/home/amy-cms-logo-white.png';
      this.logoUrl = '/en/cms';
      this.appName = 'amy-cms';
      this.showRegularHeader = false;
      this.showRoutingLinks = false;
      this.setFavicon('./assets/images/features/ai-imgs6.svg');
    }else if(this.router.url.includes('/clockit')){
      this.companyLogo = this.headerColor ? '../../assets/images/home/amy-clockit-logo.png' : '../../assets/images/home/amy-clockit-logo-white.png';
      this.logoUrl = '/en/clockit';
      this.appName = 'amy-clockit';
      this.showRegularHeader = false;
      this.showRoutingLinks = false;
      this.setFavicon('./assets/images/features/ai-imgs5.svg');
    }else if(this.router.url == '/en/connect' || this.router.url.includes('/hire-from-agencies') || this.router.url.includes('/hire-contractors-by-skills') || this.router.url.includes('/discounted-packages')
       || this.router.url.includes('/en/connect')){
      this.companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
      this.logoUrl = '/en/connect';
      this.appName = 'amy-connect';
      this.showRegularHeader = true;
      this.showRoutingLinks = false;
      this.setFavicon('./assets/images/features/ai-imgs4.svg');
      if(this.router.url == '/en/connect' || this.router.url.includes('/hire-from-agencies') || this.router.url.includes('/hire-contractors-by-skills') || this.router.url.includes('/discounted-packages')){
        //   this.companyLogo = this.headerColor ? '../../assets/icons/Homepage/amy-connect-logo.png' : '../../assets/icons/Homepage/amy-connect-white.png';
         }else{
          this.headerColor = true;
          this.route.queryParams.subscribe((params) => {
            let agreementType = params['agreementType'];
            let policyType = params['policyType'];
            if(policyType != undefined || agreementType != undefined) {
              this.setNavbarContentsOnType(agreementType ? agreementType : policyType);
            } else {
              this.setNavbarContentsOnType('amyConnect');
            }
          });
        }
    }else {

      // can keep links whch page bg is white 
      if(this.router.url == '/en/pricing'){
        this.headerColor = true;
      }

      this.companyLogo = this.headerColor ? '../../assets/icons/Homepage/amy-logo.png' : '../../assets/images/home/Amy-logo-white.png';
      this.logoUrl = '/en';
      this.appName = 'amy';
      this.showRegularHeader = false;
      this.showRoutingLinks = true;
      this.setFavicon('./assets/icons/aumnicsicon.png');
      this.route.queryParams.subscribe((params) => {
        let agreementType = params['agreementType'];
        let policyType = params['policyType'];
        if(policyType != undefined || agreementType != undefined) {
          this.headerColor = true;
          this.setNavbarContentsOnType(agreementType ? agreementType : policyType);
        }
      });
    }
    this.commonService.SignupUrl = 'get-started/u/setup-profile?returnUrl='+this.appName;
    this.sharedService.setSocialLinks(this.appName);

    if(this.router.url.includes('/about-us') || this.router.url.includes('/contact-us')){
      this.whiteLinks = true;
    } else {
      this.whiteLinks = false;
    }
  }

  setNavbarContentsOnType(type){
    this.appName = 'amy';
    this.headerColor = false;
    if(type == 'cms'){
      this.companyLogo = './../assets/icons/Homepage/amy-cms-logo.png';
      this.logoUrl = '/en/cms';
      this.showRegularHeader = false;
      this.showRoutingLinks = false;
      this.appName = 'amy-cms';
      this.setFavicon('./assets/images/features/ai-imgs6.svg');
    }else if(type == 'clockit'){
      this.companyLogo = '../../assets/images/home/amy-clockit-logo.png';
      this.logoUrl = '/en/clockit';
      this.showRegularHeader = false;
      this.showRoutingLinks = false;
      this.appName = 'amy-clockit';
      this.setFavicon('./assets/images/features/ai-imgs5.svg');
    }else if(type == 'amy'){
      this.companyLogo = '../../assets/icons/Homepage/amy-logo.png';
      this.logoUrl = '/en';
      this.showRegularHeader = false;
      this.showRoutingLinks = true;
      this.appName = 'amy';
      this.setFavicon('./assets/icons/aumnicsicon.png');
    }else if(type == 'amyConnect'){
      this.companyLogo = '../../assets/icons/Homepage/amy-connect-logo.png';
      this.logoUrl = '/en/connect';
      this.showRegularHeader = true;
      this.showRoutingLinks = false;  
      this.appName = 'amy-connect';
      this.setFavicon('./assets/images/features/ai-imgs4.svg');    
      if(this.router.url == '/en/connect' || this.router.url.includes('/hire-from-agencies') || this.router.url.includes('/hire-contractors-by-skills') || this.router.url.includes('/discounted-packages')){
        this.headerColor = false;
      }else{
        this.headerColor = true;
      }
    }

    if (this.router.url.includes('/user-agreement') || this.router.url.includes('/privacy-policy') || this.router.url.includes('/responsible-use-policy')) {
      this.headerColor = true;
    }
    
    this.commonService.SignupUrl = 'get-started/u/setup-profile?returnUrl='+this.appName
  }

  //to set fav icon
  setFavicon(newFaviconUrl: string): void {
    const faviconElement = this.document.getElementById('favicon') as HTMLLinkElement;

    if (faviconElement) {
      faviconElement.href = newFaviconUrl;
    }
  }

    // trigger the changes
    public triggerChange() {
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    }
}
