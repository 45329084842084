<div *ngIf="showAllCards && isAmyConnectFlow" class="chat-bot">
  <div class="chat-bubble overlay-msg" id="client">
    <!-- <div class="triangle">
      </div> -->
    <div class="content" id="client_content">
      <!-- Outsource your requirement? -->
    </div>
  </div>
  
  <div class="chat-bubble-2 overlay-msg" id="service_provider">
    <div class="content" id="service_provider_content">
      <!-- Are you a serice provider? -->
    </div>
  </div>
  
  <div class="chat-bubble-2 overlay-msg" id="glad_to_see">
    <div class="content" id="glad_to_see_help">
      <!-- Are you a serice provider? -->
    </div>
  </div>
  
  <div class="overlay-msg cancel_btn2" id="cancel_btn2" *ngIf="showCards">
    <div class="content px-0" id="cancel_cross_btn2">
    </div>
  </div>
  <div class="overlay-msg cancel_btn3" id="cancel_btn2" *ngIf="!showCards">
    <div class="content px-0" id="cancel_cross_btn3">
    </div>
  </div>
  <div class="chat-bubble-2 overlay-msg service_provider_1" id="service_provider_1" *ngIf="showCards">
    <div class="content" id="download_report">
    </div>
  </div>
  
  <div class="overlay-msg" id="cancel_btn" *ngIf="showCards">
    <div class="content px-0" id="cancel_cross_btn">
    </div>
  </div>
</div>

<div *ngIf="showAllCards && !isAmyConnectFlow" class="chat-bot">  
  <div class="chat-bubble-2 overlay-msg glad_to_see" id="glad_to_see">
    <div class="content" id="glad_to_see_help">
      <!-- Are you a serice provider? -->
    </div>
  </div>
  
  <div class="overlay-msg cancel_btn3" id="cancel_btn2" [ngClass]="{'amy-cancelbtn': appicationName == 'Amy'}">
    <div class="content px-0" id="cancel_cross_btn2">
    </div>
  </div>
</div>
