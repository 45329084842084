<!-- <div class="join-us hide-on-tab" *ngIf="homeBlog && showRegularHeader">
  <p><span>{{"HomePage.new" | translate }}</span><a href="{{websiteUrl}}en/cms/blogs/{{homeBlog?.blogSlugName}}">
    {{ homeBlog?.blogTitle.length > 60 ? homeBlog?.blogTitle.substr(0, 60) + '...' : homeBlog?.blogTitle }} &nbsp;
    {{"HomePage.readMore" | translate}} <i class="fa-sharp fa fa-solid fa-angle-right"></i></a></p>    
</div> -->

<!-- Header for Amy/cms/clockit pages-->
<header class="header new-header-style" #header id="header" [ngClass]="{'headerColor' : (headerColor || expandedOptions), 'white-links-header' : (whiteLinks && !headerColor && !expandedOptions), 'expanded-options' : expandedOptions}">
      <div class="container-nav px-5 new-header">
        <div class="header-nav">
          <div class="logo web-logo" >
            <a [routerLink]="logoUrl" (click)="closeClick()" id="logoElement"><img loading="lazy"  height="45" [src]="(whiteLinks && !headerColor && !expandedOptions) ? '../../assets/images/home/Amy-logo-white.png' : '../../assets/icons/Homepage/amy-logo.png'" alt="amy-logo" [ngClass]="{'scaledUpLogo' : logoUrl !== '/en'}"></a>
          </div>
          <nav class="navbar navbar-expand-md navbar-dark">
            <button class="navbar-toggler menu-toggle" id="menu-toggle" type="button">
              <!-- <span class="navbar-toggler-icon"></span> -->
              <mat-icon> menu </mat-icon>
            </button>
            <div class="navbar-collapse">
              
              <div class="mobile-humberge">
                <a [routerLink]="logoUrl" (click)="closeClick()" class="mobileHeaderLogo" id="mobileHeaderLogo"><img loading="lazy" height="45" [src]="'../../assets/icons/Homepage/amy-logo.png'" alt="amy-logo"></a>
                <span class="backText primary-color f-16 d-none" id="backText" (click)="recetAllMobileElements()"> <i class="pi pi-arrow-left primary-color mr-2" style="font-size: 13px;"></i> Back</span>
                <mat-icon class="navbar-toggler1" id="navbar-toggler1" (click)="recetAllMobileElements()"> close </mat-icon>
              </div>
              <span></span>
  
              <ul class="navbar-nav search-nav-sec show-on-mobile">
                <!-- mobile nav links  -->
                <li class="nav-item show-on-mobile" id="businessEcosystemMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap ">{{"Header.newBusinessEcosystem" | translate}} </a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>     
                  <ng-container *ngTemplateOutlet="businessMobileOptions"></ng-container>        
                </li>  
                <li class="nav-item show-on-mobile" id="wantToAmyMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap ">{{"Header.wantToAmyAgency" | translate}} </a>
                  </div>      
                </li>  
                <li class="nav-item show-on-mobile" id="industriesMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " >{{"Header.topIndustries" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>      
                  <ng-container *ngTemplateOutlet="indutryMobileOptions"></ng-container>              
                </li>
                <li class="nav-item show-on-mobile" id="pricingMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " >{{"Header.plansAndPricing" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>   
                  <ng-container *ngTemplateOutlet="picingMobileOptions"></ng-container>  
                </li>
                <li class="nav-item show-on-mobile" id="compareMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap ">{{"Header.compare" | translate}} </a>
                  </div>      
                </li>
                <li class="nav-item show-on-mobile" id="learnMobileElement">
                  <div class="d-flex align-items-center justify-content-between">
                    <a class="nav-link dropbtn mobile-link special-link text-wrap " >{{"Header.learn" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-chevron-right ml-1"></i></span>
                  </div>  
                  <ng-container *ngTemplateOutlet="learnMobileOptions"></ng-container>                  
                </li>
                <li class="mobile-padding-div"></li>
              </ul>
  
                 <!-- Web nav links  -->
              <div class="humberge-menu-section w-100">
              <ul class="navbar-nav signup-last">
                <li class="nav-item hide-on-mobile" id="businessEcosystemElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="router.url" >{{"Header.newBusinessEcosystem" | translate}} </a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li> 
                <li class="nav-item hide-on-mobile" id="wantToAmyElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="router.url" >{{"Header.wantToAmyAgency" | translate}} </a>
                  </div>                  
                </li>  
                <li class="nav-item hide-on-mobile" id="industriesElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " >{{"Header.topIndustries" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li>
                <li class="nav-item hide-on-mobile" id="pricingElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " >{{"Header.plansAndPricing" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>   
                </li>
                <li class="nav-item hide-on-mobile" id="compareElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " [routerLink]="router.url" >{{"Header.compare" | translate}} </a>
                  </div>                  
                </li> 
                <li class="nav-item hide-on-mobile" id="learnElement">
                  <div class="d-flex align-items-center">
                    <a class="nav-link dropbtn special-link text-wrap " >{{"Header.learn" | translate}}</a>
                    <span class="mt-1"><i class="pi pi-angle-down ml-1"></i></span>
                  </div>                  
                </li>
                <ng-container *ngIf="whiteLinks && !headerColor && !expandedOptions">
                  <li class="nav-item ml-auto">
                    <a class="nav-link dropbtn special-link text-wrap" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="showMore-btn white-btn border-none"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>
                </ng-container>
                <ng-container *ngIf="!(whiteLinks && !headerColor && !expandedOptions)">
                  <li class="nav-item ml-auto">
                    <a class="nav-link dropbtn special-link text-wrap" [href]="signUrl+'sign-in?returnUrl='+appName" id="loginElement">
                      {{"Header.logIn" | translate}}
                    </a>
                  </li>
                  <li class="nav-item signup">
                    <a id="signupElement" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName"> 
                      <button class="save-btn cfa"> {{"Header.getStarted" | translate}}</button> 
                    </a>
                  </li>
                </ng-container>                
              </ul>
  
               <div class="social-icons mobile-social-icons">
  
                <ul>
                  <li>
                    <a href="{{sharedService.twitter}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.youtube}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.facebook}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.instagram}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-instagram"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.linkedin}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-linkedin"></i></a>
                  </li>
                  <li>
                    <a href="{{sharedService.medium}}" target="_blank" (click)="closeClick()"><i class="fab fa fa-medium"></i></a>
                  </li>
  
                </ul>
            </div>
          </div>
             
            </div>
          </nav>
  
        </div>
        <a class="mobile-sign-up save-btn cfa" [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName">{{'Header.getStarted' | translate}}</a>
      </div>  

      <!-- forms gray bg below options  -->
      <div class="header-overlay" id="bgOverlay" [ngClass]="{'show' : expandedOptions}"></div>
</header>

<!-- web menu options  -->

<div class="relativeDiv" id="relativeDiv">
  <ul class="c-extnav-level__1">
    <ng-container *ngIf="currentExpandedElement === 'business'">
         <!-- Business content here -->
      <div class="businessrelativeDiv" id="businessrelativeDiv">
        <ul class="businessOptionsParent px-5 py-4">
          <div class="row mx-0">

            <div class="col-md-3">
              <div class="round-corners mr-4">
                <div class="card-body service-provider-card card p-4">
                  <div class="d-flex">
                    <div class="opt-img" style="padding: 5px 5px 5px 0; margin-right: 5px;">
                      <img src="../../assets/icons/nav-bar/service-provider.svg" alt="{{'expandBusiness.title' | translate}}">
                    </div>
                    <h5 class="card-title">{{"expandBusiness.title" | translate}}</h5>
                  </div>
                  <p class="paragraph-global-text pr-4">{{"expandBusiness.desc" | translate}}</p>
                  <!-- <p class="paragraph-global-text pr-4">{{"expandBusiness.descAfter" | translate}}</p> -->
                  <a [routerLink]="['/', languageCode, 'amy-service-provider']" (click)="onClickWebElement('business')">
                    <button class="save-btn fw-500 w-100 mt-3">{{"expandBusiness.btnServiceProvider" | translate}}</button>
                  </a>
                  <a >
                    <button class="cancel-btn fw-500 transparent-bg w-100 mt-4">{{"expandBusiness.btnStartNow" | translate}}</button>
                  </a>
                  
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <div class="">
                <div class="row mx-0">
                  <div class="col-md-3 h-100">
                    <div class="">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="title-icon">
                            <img src="../../assets/icons/nav-bar/ask-amy.svg" alt="">
                          </div>
                          <h5 class="card-title">{{"askAmy.title" | translate}} {{"askAmy.title2" | translate}}</h5>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-answers'" (click)="recetWebNavElemets();">{{"askAmy.items.getAnswers" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/automate-tasks'" (click)="recetWebNavElemets();">{{"askAmy.items.automateTasks" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/hire-contract-talent'" (click)="recetWebNavElemets();">{{"askAmy.items.hireContractTalent" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-insights'" (click)="recetWebNavElemets();">{{"askAmy.items.findInsights" | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 h-100 border-left">
                    <div class="h-100">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="title-icon">
                            <img src="../../assets/icons/nav-bar/project-management.svg" alt="">
                          </div>
                          <h5 class="card-title">{{"projectManagement.title" | translate}} {{"projectManagement.title2" | translate}}</h5>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/plan-collaborately'" (click)="recetWebNavElemets();">{{"projectManagement.items.planCollaborately" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/set-goals-targets'" (click)="recetWebNavElemets();">{{"projectManagement.items.setGoals" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/surveys'" (click)="recetWebNavElemets();">{{"projectManagement.items.sprintSurveys" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/tasks-subtasks-bugs'" (click)="recetWebNavElemets();">{{"projectManagement.items.tasksAndBugs" | translate}}</a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"projectManagement.items.mentionsAndComments" | translate}}</a>
                          </li> -->
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none"  [routerLink]=" '/' + languageCode + '/time-sheets'" (click)="recetWebNavElemets();">{{"projectManagement.items.timeSheets" | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
        
                  <div class="col-md-3 h-100 border-left">
                    <div class="h-100">
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="title-icon">
                            <img src="../../assets/icons/nav-bar/integrated-marketplace.svg" alt="">
                          </div>
                          <h5 class="card-title">{{"marketplaceServices.title" | translate}} {{"marketplaceServices.title2" | translate}}</h5>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/industry-wise-templates'" (click)="recetWebNavElemets();">{{"marketplaceServices.items.industryTemplates" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/compare-prices-of-agencies'" (click)="recetWebNavElemets();">{{"marketplaceServices.items.comparePrices" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/experience-vertical-marketplace'" (click)="recetWebNavElemets();">{{"marketplaceServices.items.verticalMarketplace" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/manage-in-house-teams'" (click)="recetWebNavElemets();">{{"marketplaceServices.items.manageTeams" | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
        
                  <div class="col-md-3 h-100 border-left">
                    <div >
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <div class="title-icon">
                            <img src="../../assets/icons/nav-bar/enterprise-collaboration.svg" alt="">
                          </div>
                          <h5 class="card-title">{{"enterpriseCollaboration.title" | translate}} {{"enterpriseCollaboration.title2" | translate}}</h5>
                        </div>
                        
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/one-to-one-chat'" (click)="recetWebNavElemets();">{{"enterpriseCollaboration.items.oneToOneChat" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/group-chat'" (click)="recetWebNavElemets();">{{"enterpriseCollaboration.items.groupChat" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.videoCalls" | translate}}</a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.screenSharing" | translate}}</a>
                          </li> -->
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.fileSharing" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.calendar" | translate}}</a>
                          </li>
                          <!-- <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"enterpriseCollaboration.items.invoices" | translate}}</a>
                          </li> -->
                        </ul>
                      </div>                
                    </div>
                    <div >
                      <div class="card-body">
                        <div class="d-flex align-items-start">
                          <h5 class="card-title">{{"appsAndIntegrations.title" | translate}} {{"appsAndIntegrations.title2" | translate}}</h5>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none">{{"appsAndIntegrations.items.amyCMS" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/gitlab'" (click)="recetWebNavElemets();">{{"appsAndIntegrations.items.gitlab" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/jenkins'" (click)="recetWebNavElemets();">{{"appsAndIntegrations.items.Jenkins" | translate}}</a>
                          </li>
                          <li class="list-group-item paragraph-global-text mb-0">
                            <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/google-calendar'" (click)="recetWebNavElemets();">{{"appsAndIntegrations.items.googleCalendar" | translate}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="currentExpandedElement === 'industry'">
         <!-- Industry content here -->
        <div class="indutryrelativeDiv" id="indutryrelativeDiv">
          <ul class="industryOptionsParent px-5 py-4">
            <div class="row mx-0">
              <div class="col-md-4">
                <div class="round-corners border-right pr-4">
                  <div class="card-body opt-card card p-3 mb-4">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/Amy-gold.svg" alt="{{'amyGold.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
                        <div>{{"amyGold.title" | translate}} {{"askAmy.title2" | translate}}</div> 
                        <i class="pi pi-chevron-right"></i> 
                      </h5>
                      <p class="paragraph-global-text">{{"amyGold.description" | translate}}</p>
                    </div>
                  </div>
                  <div class="card-body opt-card card p-3 mb-4">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/amy-enterprise.svg" alt="{{'amyEnterprise.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
                        <div>{{"amyEnterprise.title" | translate}} {{"amyEnterprise.title2" | translate}}</div> 
                        <i class="pi pi-chevron-right"></i>
                      </h5>
                      <p class="paragraph-global-text">{{"amyEnterprise.description" | translate}}</p>
                    </div>
                  </div>
                  <div class="card-body opt-card card p-3">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/contact-sales.svg" alt="{{'contactSales.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
                        <div>{{"contactSales.title" | translate}} {{"contactSales.title2" | translate}}</div> 
                        <i class="pi pi-chevron-right"></i>
                      </h5>
                      <p class="paragraph-global-text">{{"contactSales.description" | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
          
              <div class="col-md-8">
                <div class="">
                  <div class="row mx-0">
                    <div class="col-md-4 h-100">
                      <div >
                        <div class="card-body">
                          <h5 class="card-title">{{"topIndustries.title" | translate}} {{"topIndustries.title2" | translate}}</h5>
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.softwareAndITService" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.design" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.digitalMarketing" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.writingEditing" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.mediaProduction" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.musicAudioVoice" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.corporateServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.marketingSalesConsulting" | translate}}</a>
                            </li>
                          </ul>                  
                        </div>
                      </div>
                    </div>
          
                    <div class="col-md-4 h-100">
                      <div class="h-100">
                        <div class="card-body">
                          <h5 class="card-title mb-5 pb-3"></h5>
                          <ul class="list-group list-group-flush">                    
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.professionalTrainingCoaching" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.managementConsulting" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.trainingEducationalServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.researchAnalysis" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.eventsServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.architecturePlanning" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.fashionLifestyleServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.logisticsSupplyChain" | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-md-4 h-100">
                      <div class="h-100">
                        <div class="card-body">
                          <h5 class="card-title mb-5 pb-3"></h5>
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.environmentalServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.healthcareServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.intellectualPropertyLawServices" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.insurance" | translate}}</a>
                            </li>
                            <li class="list-group-item paragraph-global-text mb-0">
                              <a class="text-decoration-none">{{"topIndustries.industriesList.realEstateServices" | translate}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="currentExpandedElement === 'pricing'">
        <!-- Pricing content here -->
        <div class="picingrelativeDiv" id="picingrelativeDiv">
          <ul class="px-5 py-4 picingOptionsParent">
            <div class="row mx-0"> 
              <div class="col-md-8 border-right">
                <div class="row mx-0 pr-3">
                  <div class="col-md-4">
                    <div class="pricing-card card mx-2">
                      <div class="card-body">
                        <div class="opt-img-circular mx-auto">
                          <img src="../../assets/icons/nav-bar/free.svg" alt="{{'free.title' | translate}} Icon" class="icon">
                        </div>
                        <h5 class="card-title mb-5 pb-0">{{"free.title" | translate}} </h5>
                        <p class="paragraph-global-text">{{'free.description' | translate}}</p>
        
                        <div class="mt-5">
                          <button class="cancel-btn price-btn mx-auto">GET STARTED</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="pricing-card card mx-2">
                      <div class="card-body">
                        <div class="opt-img-circular mx-auto">
                          <img src="../../assets/icons/nav-bar/pro.svg" alt="{{'pro.title' | translate}} Icon" class="icon">
                        </div>
                        <h5 class="card-title mb-5 pb-0">{{"pro.title" | translate}} </h5>
                        <p class="paragraph-global-text">{{'pro.description' | translate}}</p>
                        <div class="mt-5">
                          <button class="cancel-btn price-btn mx-auto">GET STARTED</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="pricing-card card mx-2">
                      <div class="card-body">
                        <div class="opt-img-circular mx-auto">
                          <img src="../../assets/icons/nav-bar/enterprise.svg" alt="{{'enterprise.title' | translate}} Icon" class="icon">
                        </div>
                        <h5 class="card-title mb-5 pb-0">{{"enterprise.title" | translate}} </h5>
                        <p class="paragraph-global-text">{{'enterprise.description' | translate}}</p>
                        <div class="mt-5">
                          <button class="cancel-btn price-btn mx-auto">GET STARTED</button>
                        </div>
                      </div>
                    </div>
                  </div>            
                </div>
              </div>
              
              <div class="col-md-4">
                <div class="round-corners ml-3">
                  <div class="card-body opt-card card mb-3">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/Amy-gold.svg" alt="{{'amyGoldPlan.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center"><span class="mr-5">{{"amyGoldPlan.title" | translate}}</span> <i class="pi pi-chevron-right"></i> </h5>
                      <p class="paragraph-global-text">{{"amyGoldPlan.description" | translate}}</p>
                    </div>
                  </div>
                  <div class="card-body opt-card card mb-3">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/storage.svg" alt="{{'storage.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center"><span class="mr-5">{{"storage.title" | translate}}</span> <i class="pi pi-chevron-right"></i></h5>
                      <p class="paragraph-global-text">{{"storage.description" | translate}}</p>
                    </div>
                  </div>
                  <div class="card-body opt-card card">
                    <div class="opt-img">
                      <img src="../../assets/icons/nav-bar/teams.svg" alt="{{'teams.title' | translate}}">
                    </div>
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center"><span class="mr-5">{{"teams.title" | translate}}</span> <i class="pi pi-chevron-right"></i></h5>
                      <p class="paragraph-global-text">{{"teams.description" | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="currentExpandedElement === 'learn'">
        <!-- Learn content here -->
        <div class="learnrelativeDiv" id="learnrelativeDiv">
          <ul class="learnOptionsParent px-5 py-4">
            <div class="row mx-0"> 
              <div class="col-md-4">
                <div class="card-body opt-card card mb-4 mr-4">
                  <div class="opt-img">
                    <img src="../../assets/icons/nav-bar/collaboration-easy.svg" alt="{{'makingCollaborationEasy.title' | translate}}">
                  </div>
                  <div>
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"makingCollaborationEasy.title" | translate}} {{"makingCollaborationEasy.title2" | translate}} </h5>
                    <p class="paragraph-global-text ">{{"makingCollaborationEasy.description" | translate}}</p>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item paragraph-global-text  mb-0">
                        <a class="text-decoration-none">{{"makingCollaborationEasy.options.travelAgents" | translate}}</a>
                      </li>
                      <li class="list-group-item paragraph-global-text  mb-0">
                        <a class="text-decoration-none">{{"makingCollaborationEasy.options.digitalMarketingAgencies" | translate}}</a>
                      </li>
                      <!--  <li class="list-group-item paragraph-global-text  mb-0">{{"makingCollaborationEasy.options.graphicDesignAgencies" | translate}}</a>
                            </li>
                      <li class="list-group-item paragraph-global-text  mb-0">{{"makingCollaborationEasy.options.createCustomProjectTemplates" | translate}}</a>
                            </li> -->
                    </ul>
                  </div>
                </div>
                <div class="card-body opt-card card mr-4">
                  <div class="opt-img">
                    <img src="../../assets/icons/nav-bar/help-center.svg" alt="{{'helpCentre.title' | translate}}">
                  </div>
                  <div>
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"helpCentre.title" | translate}} {{"helpCentre.title2" | translate}} </h5>
                    <p class="paragraph-global-text ">{{"helpCentre.description" | translate}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card-body opt-card card mb-4">
                  <div class="opt-img">
                    <img src="../../assets/icons/nav-bar/amy-blog.svg" alt="{{'amyBlog.title' | translate}}">
                  </div>
                  <div>
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"amyBlog.title" | translate}} {{"amyBlog.title2" | translate}} </h5>
                    <p class="paragraph-global-text ">{{"amyBlog.description" | translate}}</p>
                  </div>
                </div>
                <div class="card-body opt-card card mb-4">
                  <div class="opt-img">
                    <img src="../../assets/icons/nav-bar/collaboration-easy.svg" alt="{{'collaborationSchool.title' | translate}}">
                  </div>
                  <div>
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"collaborationSchool.title" | translate}} {{"collaborationSchool.title2" | translate}}</h5>
                    <p class="paragraph-global-text ">{{"collaborationSchool.description" | translate}}</p>
                  </div>
                </div>
                <div class="card-body opt-card card">
                  <div class="opt-img">
                    <img src="../../assets/icons/nav-bar/productivity2.svg" alt="{{'productivityRevolutionPodcast.title' | translate}}">
                  </div>
                  <div>
                    <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                      {{"productivityRevolutionPodcast.title" | translate}} {{"productivityRevolutionPodcast.title2" | translate}}
                    </h5>
                    <p class="paragraph-global-text ">{{"productivityRevolutionPodcast.description" | translate}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="round-corners ml-3">          
                  <div class="card-body opt-card card flex-column">    
                    <div class="opt-img single">
                      <img src="../../assets/icons/nav-bar/get-started.svg" alt="{{'gettingStarted.title' | translate}}">
                    </div>        
                    <div>
                      <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
                        {{"gettingStarted.title" | translate}} {{"gettingStarted.title2" | translate}} 
                      </h5>
                      <p class="paragraph-global-text ">{{"gettingStarted.description" | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
    </ng-container>
  </ul>
</div>


<!-- mobile menu options  -->
<ng-template #businessMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 businessMobileOptionsParent">
    <div class="">
      <div class="row mx-0">
        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"askAmy.title" | translate}} {{"askAmy.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-answers'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.getAnswers" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/automate-tasks'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.automateTasks" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/hire-contract-talent'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.hireContractTalent" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-insights'" (click)="resetAndCloseMobileElements()">{{"askAmy.items.findInsights" | translate}}</a>
                    </li>
          </ul>
        </div>

        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"projectManagement.title" | translate}} {{"projectManagement.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/plan-collaborately'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.planCollaborately" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/set-goals-targets'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.setGoals" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/surveys'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.sprintSurveys" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/tasks-subtasks-bugs'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.tasksAndBugs" | translate}}</a>
            </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"projectManagement.items.mentionsAndComments" | translate}}</a>
                    </li> -->
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/time-sheets'" (click)="resetAndCloseMobileElements()">{{"projectManagement.items.timeSheets" | translate}}</a>
            </li>
          </ul>
        </div>

        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"marketplaceServices.title" | translate}} {{"marketplaceServices.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/industry-wise-templates'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.industryTemplates" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/compare-prices-of-agencies'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.comparePrices" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/experience-vertical-marketplace'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.verticalMarketplace" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/manage-in-house-teams'" (click)="resetAndCloseMobileElements()">{{"marketplaceServices.items.manageTeams" | translate}}</a>
                    </li>
          </ul>          
        </div>


        <div class="col-md-6 col-sm-12 px-0">
          <div class="d-flex align-items-start">
            <h5 class="card-title">{{"enterpriseCollaboration.title" | translate}} {{"enterpriseCollaboration.title2" | translate}}</h5>
          </div>
          <ul class="list-group list-group-flush  mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/one-to-one-chat'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.oneToOneChat" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/group-chat'" (click)="resetAndCloseMobileElements()">{{"enterpriseCollaboration.items.groupChat" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.videoCalls" | translate}}</a>
                    </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.screenSharing" | translate}}</a>
                    </li> -->
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.fileSharing" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.calendar" | translate}}</a>
                    </li>
            <!-- <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"enterpriseCollaboration.items.invoices" | translate}}</a>
                    </li> -->
          </ul>
          <h5 class="card-title">{{"appsAndIntegrations.title" | translate}} {{"appsAndIntegrations.title2" | translate}}</h5>
          <ul class="list-group list-group-flush mb-4">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none">{{"appsAndIntegrations.items.amyCMS" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/gitlab'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.gitlab" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/jenkins'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.Jenkins" | translate}}</a>
            </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
              <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/apps/google-calendar'"
                (click)="resetAndCloseMobileElements()">{{"appsAndIntegrations.items.googleCalendar" | translate}}</a>
            </li>
          </ul>
        </div>  
      </div>
    </div>
  </ul>
</ng-template>


<ng-template #indutryMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 industryMobileOptionsParent">
    <div class="row mx-0">     
      <div class="col-sm-12 col-md-6 px-0 mb-4">
        <div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.softwareAndITService" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.design" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.digitalMarketing" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.writingEditing" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.mediaProduction" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.musicAudioVoice" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.corporateServices" | translate}}</a>
                    </li>
            <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.marketingSalesConsulting" | translate}}</a>
                    </li>
                    <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.professionalTrainingCoaching" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.managementConsulting" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.trainingEducationalServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.researchAnalysis" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.eventsServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.architecturePlanning" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.fashionLifestyleServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.logisticsSupplyChain" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.environmentalServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.healthcareServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.intellectualPropertyLawServices" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.insurance" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"topIndustries.industriesList.realEstateServices" | translate}}</a>
                    </li>
          </ul>
        </div>
      </div>

      <div class="col-12 px-0">
        <div class="mb-4">
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"amyGold.title" | translate}} {{"askAmy.title2" | translate}}</div> 
              <i class="pi pi-chevron-right"></i> 
            </h5>
            <p class="paragraph-global-text mobile-description">{{"amyGold.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"amyEnterprise.title" | translate}} {{"amyEnterprise.title2" | translate}}</div> 
              <i class="pi pi-chevron-right"></i>
            </h5>
            <p class="paragraph-global-text mobile-description">{{"amyEnterprise.description" | translate}}</p>
          </div>
        </div>
        <div>
          <div class="w-100">
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center justify-content-between">
              <div>{{"contactSales.title" | translate}} {{"contactSales.title2" | translate}}</div> 
               <i class="pi pi-chevron-right"></i>
            </h5>
            <p class="paragraph-global-text mobile-description">{{"contactSales.description" | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </ul>
</ng-template>

<ng-template #picingMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 picingMobileOptionsParent">
    <div class="row mx-0"> 
      <div class="col-md-4 px-0">
        <div class="">
          <div class="card-body opt-card card mb-4">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/free.svg" alt="{{'free.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none"><span class="mr-5">{{"free.title" | translate}}</span> <i class="pi pi-chevron-right"></i> </h5>
              <p class="paragraph-global-text  mobile-description">{{"free.description" | translate}}</p>
            </div>
          </div>
          <div class="card-body opt-card card mb-4">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/pro.svg" alt="{{'pro.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none"><span class="mr-5">{{"pro.title" | translate}}</span> <i class="pi pi-chevron-right"></i> </h5>
              <p class="paragraph-global-text mobile-description">{{"pro.description" | translate}}</p>
            </div>
          </div>
          <div class="card-body opt-card card mb-4">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/enterprise.svg" alt="{{'enterprise.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none"><span class="mr-5">{{"enterprise.title" | translate}}</span> <i class="pi pi-chevron-right"></i> </h5>
              <p class="paragraph-global-text mobile-description">{{"enterprise.description" | translate}}</p>
            </div>
          </div>
          <div class="card-body opt-card card mb-4">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/Amy-gold.svg" alt="{{'amyGoldPlan.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none"><span class="mr-5">{{"amyGoldPlan.title" | translate}}</span> <i class="pi pi-chevron-right"></i> </h5>
              <p class="paragraph-global-text mobile-description">{{"amyGoldPlan.description" | translate}}</p>
            </div>
          </div>
          <div class="card-body opt-card card mb-4">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/storage.svg" alt="{{'storage.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center"><span class="mr-5">{{"storage.title" | translate}}</span> <i class="pi pi-chevron-right"></i></h5>
              <p class="paragraph-global-text mobile-description">{{"storage.description" | translate}}</p>
            </div>
          </div>
          <div class="card-body opt-card card ">
            <div class="opt-img mobile-opt-img">
              <img src="../../assets/icons/nav-bar/teams.svg" alt="{{'teams.title' | translate}}">
            </div>
            <div>
              <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center"><span class="mr-5">{{"teams.title" | translate}}</span> <i class="pi pi-chevron-right"></i></h5>
              <p class="paragraph-global-text mobile-description">{{"teams.description" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ul>
</ng-template>

<ng-template #learnMobileOptions>
  <ul class="c-m-extnav-level__1 p-4 learnMobileOptionsParent">
    <div class="row mx-0"> 
      <div class="col-md-4 px-0">
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"makingCollaborationEasy.title" | translate}} {{"makingCollaborationEasy.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"makingCollaborationEasy.description" | translate}}</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.travelAgents" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.digitalMarketingAgencies" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.graphicDesignAgencies" | translate}}</a>
                    </li>
              <li class="list-group-item mobile-link paragraph-global-text mb-0">
                      <a class="text-decoration-none">{{"makingCollaborationEasy.options.createCustomProjectTemplates" | translate}}</a>
                    </li>
            </ul>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"helpCentre.title" | translate}} {{"helpCentre.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"helpCentre.description" | translate}}</p>
          </div>
        </div>

        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"amyBlog.title" | translate}} {{"amyBlog.title2" | translate}} </h5>
            <p class="paragraph-global-text  mobile-description">{{"amyBlog.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"collaborationSchool.title" | translate}} {{"collaborationSchool.title2" | translate}}</h5>
            <p class="paragraph-global-text mobile-description">{{"collaborationSchool.description" | translate}}</p>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"productivityRevolutionPodcast.title" | translate}} {{"productivityRevolutionPodcast.title2" | translate}}
            </h5>
            <p class="paragraph-global-text mobile-description">{{"productivityRevolutionPodcast.description" | translate}}</p>
          </div>
        </div> 
        <div class="">
          <div>
            <h5 class="card-title mb-1 pb-0 border-none d-flex align-items-center">
              {{"gettingStarted.title" | translate}}  {{"gettingStarted.title2" | translate}}</h5>
            <p class="paragraph-global-text mobile-description">{{"gettingStarted.description" | translate}}</p>
          </div>
        </div>      
      </div>  
    </div>
  </ul>
</ng-template>
