import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../app/core/services/common.service';
import { SharedService } from '../../../app/core/services/shared.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile-view-card',
  templateUrl: './profile-view-card.component.html',
  styleUrls: ['./profile-view-card.component.scss']
})
export class ProfileViewCardComponent implements OnInit {
  primarySkills: any;
  secondarySkills: any;
  signUrl = environment.WEB_URL;
  currentDate:Date;
  constructor(private commonService:CommonService,public sharedService : SharedService) { }

  @Output() drawerClose = new EventEmitter();
  @Input() workId: string;
  profile : any;
  languageLevel = [{ id: 1, name: 'Basic' }, { id: 2, name: 'Intermediate' }, { id: 3, name: 'Advanced' }, { id: 4, name: 'Native' }];
  ngOnInit(): void {
    this.currentDate = new Date();
    this.getProfileData()
  }


  getProfileData(){
    this.commonService.getDeveloperProfile({developerWorkId: this.workId}).subscribe(data => {
      this.profile = data.message;
        this.primarySkills = this.profile.skills?.primarySkills;
        this.secondarySkills = this.profile.skills?.secondarySkills;
    })
  }

  close(){
    this.sharedService.sendValue(false);
    this.drawerClose.emit();
    this.sharedService.developerPitch = null;
  }

  buttonClick(type){

  }
}
